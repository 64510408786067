import {errorHttpMessage} from "@/utils/errorDecodeUtil"
import operationClient from "@/axiosClients/operationClient";
import vaccinationClient from "@/axiosClients/vaccinationClient";
import configurationClient from "@/axiosClients/configurationClient";
import dashboardClient from "@/axiosClients/dashboardClient";
import moment from 'moment';

import { downloadFile } from "@/utils/filesUtil"

export const getFilters = async ({ commit }) => {
    try{
        const { data:result } = await operationClient.get(`/get-filters/vaccinations`)
        const { data, message, error } = result.result
        return {data, message, error}
    } catch(ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getSubSpecialLabelsFilters = async ({commit}, specialLabelsSelected) => {
    try {
        const {data: result} = await operationClient.get(`/get-filters/subspecial-labels/${specialLabelsSelected.toString() || 0}`,)
        const {data, message, error} = result.result
        return {data, message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const findById = async ({commit}, medicalConditionId) => {
    try{
        const { data:result } = await configurationClient.get(`/catalog/medical-conditions/${medicalConditionId}`)
        const { data, message, error } = result.result
        return {data, message, error}
    } catch(ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getVaccines = async ({commit}) => {
    try{
        const {data:result } = await configurationClient.get('/catalog/vaccination-records/')
        const { data, message, error } = result.result
        return {data, message, error}
    } catch(ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}
export const destroy = async ({commit}, id) => {
    try{
        const { data:result } = await vaccinationClient.delete(`/vaccination/user/${id}`)
        const { message, error } = result.result
        return {message, error}
    } catch(ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const saveRegisterVaccine = async ({commit}, vaccineInfo) => {
    try{
        const { data:result } = await vaccinationClient.post(`/vaccination/user/${vaccineInfo.patient_id}`, vaccineInfo.data)
        const { data, message, error } = result.result
        return {data,message, error}
    } catch(ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const downloadExcelReport = async ({commit}, data) => {
    try{
        const response = await vaccinationClient.get(`/vaccination/download${data.filtersString}`,{
            responseType: 'blob'
        })
        downloadFile(response.data, `${data.fileName} ${moment().format("DD_MM_YYYY")}`)
    } catch(ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}
export const getDashboardInfo = async ({commit}, params) => {
    try{
        const { data:result } = await dashboardClient.get(`/vaccines${params}`)
        const { data, message, error } = result.result
        return {data, message, error}
    } catch(ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}
export const findRegisterVaccineById = async ({commit}, registerVaccineId) => {
    try{
        const { data:result } = await vaccinationClient.get(`/vaccination/user/show/${registerVaccineId}`)
        const { data, message, error } = result.result
        return {data, message, error}
    } catch(ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}
export const editRegisterVaccine = async ({commit}, vaccineInfo) => {
    try{

        const { data:result } = await vaccinationClient.post(`/vaccination/user/${vaccineInfo.patient_id}/${vaccineInfo.vaccine_id}`, vaccineInfo.vaccination_data)

        const { data, message, error } = result.result
        return {data, message, error}
    } catch(ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}
export const getDiagnostics = async ({commit}) => {
    try{
        const {data:result } = await configurationClient.get('/catalog/diagnostics/')
        const { data, message, error } = result.result
        return {data, message, error}
    } catch(ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}
