
// export const myGetter = ( state ) => {
//  return state
// }

export const getUserVaccinationRecordReactions = ( state ) => {
    return state
}
export const getVaccination = (state) => {
    return state.recordReactions
}

