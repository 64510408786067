import {errorHttpMessage} from "@/utils/errorDecodeUtil"
import configurationClient from '@/axiosClients/configurationClient'
// import {createOperationClient} from '@/axiosClients/operationClient'
import operationClient from '@/axiosClients/operationClient'
import { downloadFile } from "@/utils/filesUtil"

export const save = async ({commit}, rows) => {
    try{
        let body = {
            data: rows.filter(el => el.nomina_number) 
        }
        const { data:result } = await operationClient.post('/load-databases/medic-users', body)        
        const { message, error, title } = result.result    
        return {message, error, title}        
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    } 
}

export const getHeadersList = async ({commit}) => {
    try{        
        // const operationClient = createOperationClient()
        const { data:result } = await operationClient.get(`/load-databases/medic-users/table-headers`)        
        const { data, message, error } = result.result    
        return {data, message, error}        
    } catch(ex) {                     
        console.log(ex)
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const downloadTemplate = async ({commit}, fileName) => {
    try{        
        const response = await operationClient.get(`/load-databases/medic-users/download-template`,{
            responseType: 'blob'
        })                
        downloadFile(response.data, fileName)
    } catch(ex) {                     
        console.log('error', ex)
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    } 
}

export const saveWithTempalte = async ({commit}, file) => {
    try{        
        const formData = new FormData()
        formData.append("template_file", file)
        const { data:result } = await operationClient.post('/load-databases/medic-users/process-template', formData)        
        const { message, error, title } = result.result    
        return {message, error, title}        
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    } 
}