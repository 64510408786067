import isVerifyTokenGuard from '@/guards/verifyTokenGuard';
export default {
    name: 'covid',
    component: () => import(/* webpackChunkName: "covid" */ '@/layouts/BaseLayout.vue'),
    children: [
        {
            path: '',
            name: 'covid-index',
            beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [
                    {                        
                        transKey: 'breadcrumbs.covid-index',
                        isActive: true
                    }
                ]
            },
            component: () => import(/* webpackChunkName: "covid-index" */ '@/modules/covid/views/Index.vue')
        },
        {
            path: 'history/:id',
            name: 'covid-history',
            beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [
                    {   
                        route: {
                            name: 'covid-index',
                            params: {}
                        },
                        transKey: 'breadcrumbs.covid-index',
                        isActive: false
                    },
                    {                           
                        transKey: 'breadcrumbs.covid-history',
                        isActive: true
                    }
                ]
            },
            component: () => import(/* webpackChunkName: "covid-index" */ '@/modules/covid/views/History.vue')
        },
        {
            path: 'create/:id',
            name: 'covid-create',
            beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [
                    {   
                        route: {
                            name: 'covid-index',
                            params: {}
                        },
                        transKey: 'breadcrumbs.covid-index',
                        isActive: false
                    },
                    {   
                        route: {
                            name: 'covid-index',
                            params: {}
                        },
                        transKey: 'breadcrumbs.covid-history',
                        isActive: false,
                        isRedirect: true
                    },
                    {                           
                        transKey: 'breadcrumbs.covid-create',
                        isActive: true
                    }
                ]
            },
            props: route => ({ query: route}),
            component: () => import(/* webpackChunkName: "covid-index" */ '@/modules/covid/views/NewConsultation.vue')
        },
    ]    
}