import isVerifyTokenGuard from "@/guards/verifyTokenGuard";

export default {
    name: 'profile-patient',
    component: () => import(/* webpackChunkName: "base-layout" */ '@/layouts/BaseLayout.vue'),
    children: [
        {
            path: 'edit',
            name: 'patient-profile-edit',
            beforeEnter: [isVerifyTokenGuard],
            component: () => import(/* webpackChunkName: "users-edit" */ '@/modules/patient-profile/profile-patient/views/Edit.vue')
        },
    ]
} 