
import {errorHttpMessage} from "@/utils/errorDecodeUtil"
import configurationClient from '@/axiosClients/configurationClient'

export const saveDisabilityStatus = async ({commit}, disability) => {
    try{
        const { data:result } = await configurationClient.post('/catalog/incapacityStatus/create', disability)        
        const { message, error } = result.result    
        return {message, error}        
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    } 
}

export const findDisabilityStatusById = async ({commit}, disabilityId) => {
    try{        
        const { data:result } = await configurationClient.get(`/catalog/incapacityStatus/show/${disabilityId}`)        
        const { data, message, error } = result.result    
        return {data, message, error}        
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const editDisabilityStatus = async ({commit}, disability) => {
    try{
        const { data:result } = await configurationClient.post(`/catalog/incapacityStatus/${disability.id}`, disability)        
        const { message, error } = result.result    
        return { message, error}        
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const deleteDisabilityStatus = async ({commit}, disabilityId) => {
    try{        
        const { data:result } = await configurationClient.delete(`/catalog/incapacityStatus/destroy/${disabilityId}`)        
        const { message, error } = result.result    
        return {message, error}        
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    } 
}

export const downloadExcelReport = async ({commit}, fileName) => {
    try{        
        const response = await configurationClient.get(`/catalog/incapacityStatus/download`,{
            responseType: 'blob'
        })                
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${fileName}.xlsx`); //or any other extension
        document.body.appendChild(link);
        link.click();
    } catch(ex) {                             
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    } 
}