import isVerifyTokenGuard from "@/guards/verifyTokenGuard";

export default {
    name: 'db-assignation-special-label',
    component: () => import('@/layouts/BaseLayout.vue'),
    children: [
        {
            path: '',
            name: 'db-assignation-special-label-index',
            beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [
                    {
                        transKey: 'breadcrumbs.db-assignation-special-label-index',
                        isActive: true
                    }
                ]
            },
            component: () => import('@/modules/configurations/database-load/db-assignation-special-label/views/Index.vue')
        }
    ]
}
