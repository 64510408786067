import isVerifyTokenGuard from "@/guards/verifyTokenGuard"
export default {
    name: 'vaccinations',
    component: () => import(/* webpackChunkName: "base-layout" */ '@/layouts/BaseLayout.vue'),
    children: [
        {
            path: '',
            name: 'vaccinations-index',
            beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [
                    {                        
                        transKey: 'breadcrumbs.vaccinations-index',
                        isActive: true,                    
                    }
                ]
            },
            component: () => import(/* webpackChunkName: "vaccinations-index" */ '@/modules/vaccinations/views/Index.vue')
        },
        {
            path: 'card/:id',
            name: 'vaccinations-card',
            beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [
                    {
                        route: {
                            name: 'vaccinations-index',
                        },
                        transKey: 'breadcrumbs.vaccinations-index',
                        isActive: false
                    },
                    {                        
                        transKey: 'breadcrumbs.vaccinations-card',
                        isActive: true,                        
                    }
                ]
            },
            component: () => import(/* webpackChunkName: "vaccinations-card" */ '@/modules/vaccinations/views/VaccinationCard.vue')
        },
        {
            path: 'register-vaccine/:id',
            name: 'vaccinations-create',
            meta: {
                breadcrumbs: [
                    {
                        route: {
                            name: 'vaccinations-index',
                        },
                        transKey: 'breadcrumbs.vaccinations-index',
                        isActive: false
                    },
                    {
                        route: {
                            name: 'vaccinations-card',
                        },
                        transKey: 'breadcrumbs.vaccinations-card',
                        isActive: false,
                        isRedirect: true
                    },
                    {
                        transKey: 'breadcrumbs.vaccinations-card-create',
                        isActive: true,                    
                    }
                ]
            },
            beforeEnter: [isVerifyTokenGuard],            
            component: () => import(/* webpackChunkName: "vaccinations-create" */ '@/modules/vaccinations/views/Create.vue')
        },        
        {
            path: 'register-vaccine/:id/edit/:user_vaccination_record_id',
            name: 'vaccinations-card-edit',
            beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [
                    {
                        route: {
                            name: 'vaccinations-index',
                        },
                        transKey: 'breadcrumbs.vaccinations-index',
                        isActive: false
                    },
                    {
                        route: {
                            name: 'vaccinations-card',
                        },
                        transKey: 'breadcrumbs.vaccinations-card',
                        isActive: false,
                        isRedirect: true
                    },
                    {
                        transKey: 'breadcrumbs.vaccinations-card-edit',
                        isActive: true,                    
                    }
                ]
            },
            component: () => import(/* webpackChunkName: "demo-childres" */ '@/modules/vaccinations/views/Edit.vue')
        },
        {
            path: 'register-vaccine/:id/second-dose/:user_vaccination_record_id',
            name: 'vaccinations-card-second-dose',
            beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [
                    {
                        route: {
                            name: 'vaccinations-index',
                        },
                        transKey: 'breadcrumbs.vaccinations-index',
                        isActive: false
                    },
                    {
                        route: {
                            name: 'vaccinations-card',
                        },
                        transKey: 'breadcrumbs.vaccinations-card',
                        isActive: false,
                        isRedirect: true
                    },
                    {
                        route: {
                            name: 'vaccinations-card',
                        },
                        transKey: 'breadcrumbs.vaccinations-card-edit',
                        isActive: true,                    
                    }
                ]
            },
            component: () => import(/* webpackChunkName: "demo-childres" */ '@/modules/vaccinations/views/SecondDose.vue')
        }
    ]    
}