import ephiClient from "@/axiosClients/ephiClient"
import {errorHttpMessage} from "@/utils/errorDecodeUtil"

export const createIncapacity = async ({commit}, data) => {
  try {
    const {data: result} = await ephiClient.post('/patients/patient-incapacities/create', data)
    const {message, error} = result.result
    return {message, error}
  } catch (ex) {
    const error_http_message = errorHttpMessage(ex);
    return {
      message: error_http_message.message,
      error_keys: error_http_message.error_keys,
      data: ex.response.data,
      error: true
    }
  }
}

export const getIncapacityById = async ({commit}, incapacity_id) => {
  try{
    const { data:result } = await ephiClient.get(`/patients/patient-incapacities/show/${incapacity_id}`)
    const { data, message, error } = result.result
    return {data, message, error}
  } catch(ex) {
    const error_http_message = errorHttpMessage(ex);
    return {
      message: error_http_message.message,
      error_keys: error_http_message.error_keys,
      data: ex.response.data,
      error: true
    }
  }
}

export const updateIncapacity = async ({commit}, data) => {
  try{
    const { data:result } = await ephiClient.post(`/patients/patient-incapacities/edit/${data.incapacity_id}`, data.incapacity_data)
    const { message, error } = result.result
    return { message, error}
  } catch(ex) {
    const error_http_message = errorHttpMessage(ex);
    return {
      message: error_http_message.message,
      error_keys: error_http_message.error_keys,
      error: true
    }
  }
}

export const deleteIncapacity = async ({commit}, incapacity_id) => {
  try{
    const { data:result } = await ephiClient.delete(`/patients/patient-incapacities/${incapacity_id}`)
    const { message, error } = result.result
    return { message, error}
  } catch(ex) {
    const error_http_message = errorHttpMessage(ex);
    return {
      message: error_http_message.message,
      error_keys: error_http_message.error_keys,
      error: true
    }
  }
}

export const getIncapacitiesPDF = async ({commit}) => {
    try {
      const {data: result} = await ephiClient.get(`/patients/patient-incapacities/download`, {responseType: "blob"})
      const blob = new Blob([result], {type: "application/pdf"});
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.target = "_blank"
      link.click();
      document.body.removeChild(link);
  
      return true
    } catch (ex) {
      if (ex.error && ex.message) {
        const error_http_message = errorHttpMessage(ex);
        return {
          message: error_http_message.message,
          error_keys: error_http_message.error_keys,
          data: ex.response.data,
          error: true
        }
      } else {
        return {
          message: '',
          data: [],
          error: true
        }
      }
    }
  }

export const deleteAttachedFile = async ({commit}, file_item) => {
  try {
      const {data: result} = await ephiClient.delete(`/patients/patient-incapacities/file/${file_item.id}`)
      const {message, error} = result.result
      return {message, error}
  } catch (ex) {
      if (ex.error && ex.message) {
          const error_http_message = errorHttpMessage(ex);
          return {
              message: error_http_message.message,
              error_keys: error_http_message.error_keys,
              data: ex.response.data,
              error: true
          }
      } else {
          return {
              message: '',
              data: [],
              error: true
          }
      }
  }
}