
import {errorHttpMessage} from "@/utils/errorDecodeUtil"
import configurationClient from '@/axiosClients/configurationClient'
import { downloadFile } from "@/utils/filesUtil"

export const saveReason = async ({commit}, reason) => {
    try{
        const { data:result } = await configurationClient.post('/catalog/consultReason/create', reason)
        const { message, error } = result.result
        return {message, error}
    } catch(ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getAllReasons = async ({commit}) => {
    try {
        const { data: result } = await configurationClient.get(
            `/catalog/consultReason`
          );
          const { message, error, ...res } = result;
          return { data: res.result.data, message, error };
    } catch(ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true
        }
    }
}

export const findReasonById = async ({commit}, reasonId) => {
    try{
        const { data:result } = await configurationClient.get(`/catalog/consultReason/show/${reasonId}`)
        const { data, message, error } = result.result
        return {data, message, error}
    } catch(ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const editReason = async ({commit}, reason) => {
    try{
        const { data:result } = await configurationClient.post(`/catalog/consultReason/${reason.id}`, reason)
        const { message, error } = result.result
        return { message, error}
    } catch(ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const deleteReason = async ({commit}, reasonId) => {
    try{
        const { data:result } = await configurationClient.delete(`/catalog/consultReason/destroy/${reasonId}`)
        const { message, error } = result.result
        return {message, error}
    } catch(ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const downloadExcelReport = async ({commit}, fileName) => {
    try{
        const response = await configurationClient.get(`/catalog/consultReason/download`,{
            responseType: 'blob'
        })
        downloadFile(response.data, fileName)
    } catch(ex) {
        console.log('error', ex)
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}


export const getClasifications = async ({commit}) => {
    try {
        const { data: result } = await configurationClient.get(
            `/catalog/clasifications/get-list`
        );
        const { message, error, ...res } = result;
        return { data: res.result.data, message, error };
    } catch(ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true
        }
    }
}


export const getSubclasifications = async ({commit}) => {
    try {
        const { data: result } = await configurationClient.get(
            `/catalog/subclasifications/get-list`
        );
        const { message, error, ...res } = result;
        return { data: res.result.data, message, error };
    } catch(ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true
        }
    }
}

