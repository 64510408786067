import isVerifyTokenGuard from "@/guards/verifyTokenGuard";

export default {
    name: "digital-record",
    component: () =>
        import("@/layouts/BaseLayout.vue"),
    children: [
        {
            path: ":patient_id",
            name: "digital-record-index",
            beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [
                    {
                        route: {
                            name: 'patients-index',
                        },
                        transKey: 'breadcrumbs.patients-index',
                        isActive: false
                    },
                    {
                        transKey: 'breadcrumbs.clinical-history',
                        isActive: true,
                    }
                ]
            },
            component: () =>
                import(
                    "@/modules/digital-record/digital-record/views/Index.vue"
                    ),
        }
    ],
};
