import {createStore} from 'vuex'
import configurationClient from '@/axiosClients/configurationClient'
import auth from '@/modules/auth/store'
import users from '@/modules/configurations/users/store'
import medicines from '@/modules/configurations/catalogs/medicines/store'
import reasonsForConsultation from '@/modules/configurations/catalogs/reason-for-consultation/store'
import affectedSystems from '@/modules/configurations/catalogs/affected-systems/store'
import affectedSystemsConcentrated from '@/modules/digital-record/affected-systems/store'
import cieDiagnostics from '@/modules/configurations/catalogs/cie-diagnostics/store'
import disabilityTypes from '@/modules/configurations/catalogs/disability-types/store'
import disabilityStatus from '@/modules/configurations/catalogs/disability-status/store'
import clinicalExams from '@/modules/configurations/catalogs/clinical-exams/store'
import userProfile from '@/modules/configurations/profile/store'
import profiles from '@/modules/configurations/profiles/store'
import clients from '@/modules/clients/store'
import collaboratorTypes from '@/modules/configurations/catalogs/collaborator-types/store'
import specialLabels from '@/modules/configurations/catalogs/special-labels/store'
import subgroups from '@/modules/configurations/catalogs/subgroups/store'
import vaccinationsRecords from '@/modules/configurations/catalogs/vaccinations-records/store'
import dbMedicUsers from '@/modules/configurations/database-load/db-medic-users/store'
import dbPatientUsers from '@/modules/configurations/database-load/db-patient-users/store'
import dbVaccinations from '@/modules/configurations/database-load/db-vaccinations/store'
import dbSpecialLabels from '@/modules/configurations/database-load/db-assignation-special-label/store'
import calendars from '@/modules/calendar/store'
import dataMining from '@/modules/data-mining/store'
import patients from '@/modules/patients/store'
import medicalConsultation from '@/modules/digital-record/medical-consultation/store'
import medicalConditions from '@/modules/configurations/catalogs/medical-conditions/store'
import vaccinations from '@/modules/vaccinations/store'
import vaccinationScheme from '@/modules/configurations/catalogs/vaccinations-scheme/store'
import dashboard from '@/modules/dashboard/store'
import covid from '@/modules/covid/store'
import periodicExams from '@/modules/digital-record/periodic-exams/store'
import digitalRecord from '@/modules/digital-record/digital-record/store'
import bloodPreasure from '@/modules/patient-profile/blood-pressure/store'
import recordDisabilities from '@/modules/digital-record/medical-reports/record-disabilities/store'
import clinicalHistory from '@/modules/digital-record/clinical-history/store'
import certificateHealthStatus from '@/modules/digital-record/medical-reports/certificate-health-status/store'
import incidentReportSt7 from '@/modules/digital-record/medical-reports/incident-report-st7/store'
import medicalExam from '@/modules/digital-record/medical-exam/store'
import habits from '@/modules/patient-profile/habits/store'
import vaccinationRecord from '@/modules/patient-profile/vaccination-record/store'
import incapacities from '@/modules/patient-profile/incapacities/store'
import glucose from '@/modules/patient-profile/glucose/store'
import somatometry from '@/modules/patient-profile/somatometry/store'
import patientEvolution from '@/modules/digital-record/patient-evolution/store'
import nutrition from '@/modules/patient-profile/nutrition/store'
import classifications from '@/modules/configurations/catalogs/classifications/store'
import subClassifications from '@/modules/configurations/catalogs/sub-classifications/store'
import editableInputs from '@/modules/configurations/catalogs/editable-inputs/store'
import notifications from '@/modules/notifications/store'
import updatePassword from '@/modules/change-password-expired/store'
import assignmentsConcentrated from '@/modules/assignments-concentrated/store'
import assignationPatient from '@/modules/configurations/assignation-patient/store'
import noticeOfPrivacy from '@/modules/notice-privacy/store'
import {errorHttpMessage} from "@/utils/errorDecodeUtil"
import {removeMenuPermission} from '@/utils/permissionsUtil'

export default createStore({
    state: {
        lang: localStorage.getItem('lang_hc') || 'es',
        menus: [],
        axiosOperationDomain: '',
        globalToastData: {},
        startVideoCall: false,
        videocallRoomName: '',
        generalIsLoading: false,
        backupMenus: [],

        // Added for notifications
        openNotificationView: false,
        notificationsNumber: 0

    },
    getters: {
        getLang: (state) => state.lang,
        getMenus: (state) => [...state.menus],
        getAxiosOperationDomain: (state) => state.axiosOperationDomain,
        getGlobalToastData: (state) => ({...state.globalToastData}),
        getStartVideoCall: (state) => state.startVideoCall,
        getVideocallRoomName: (state) => state.videocallRoomName,
        getGeneralIsLoading: (state) => state.generalIsLoading,
        getBackupMenus: (state) => state.backupMenus,

        // Added for notifications
        getOpenNotificationView: (state) => state.openNotificationView,
        getNotificationsNumber: (state) => state.notificationsNumber

    },
    mutations: {
        setMenus: (state, menus) => {
            /* menus.forEach((a, b, c) => {
                a.visible = true
                removeMenuPermission(a)
            }) */
            state.menus = menus
        },
        setLang: (state, lang) => {
            state.lang = lang
            localStorage.setItem('lang_hc', lang)
        },
        setAxiosOperationDomain: (state, domain) => state.axiosOperationDomain = domain,
        setGlobalToastData: (state, data) => {
            state.globalToastData = {...data}
            const toastLiveExample = document.getElementById('liveToast')
            const toast = new bootstrap.Toast(toastLiveExample)
            toast.show()
        },
        setStartVideoCall: (state, value) => {
            state.startVideoCall = value
        },
        setVideocallRoomName: (state, value) => {
            state.videocallRoomName = value
        },
        setGeneralIsLoading: (state, value) => {
            state.generalIsLoading = value
        },
        setBackupMenus: (state, menus) => {
            state.backupMenus = menus
        },
        reloadMenus: (state) => {
            console.log('reloadMenus', state.menus)
            state.menus.forEach((a, b, c) => {
                a.visible = true
                removeMenuPermission(a)
            })
        },

        // Added for notifications
        setOpenNotificationView: (state, value) => { state.openNotificationView = value },
        setNotificationsNumber: (state, value) => { state.notificationsNumber = value}
    }
    ,
    actions: {
        loadMenus: async ({commit}) => {
            try {
                const {data} = await configurationClient.get('/menus/menusavailable')
                // console.log('loadMenus', data)
                commit('setBackupMenus', JSON.parse(JSON.stringify(data)))
                data.forEach((a, b, c) => {
                    a.visible = true
                    removeMenuPermission(a)
                })
                commit('setMenus', data)
            } catch (ex) {
                const error_http_message = errorHttpMessage(ex);
                return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
            }
        },
        getDisabledInputs: async ({commit}) => {
            try {
                const {data} = await configurationClient.get('/inputs/inputs-disabled')
                return data.result.data;
            } catch (ex) {
                const error_http_message = errorHttpMessage(ex);
                return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
            }
        },
    },
    modules: {
        auth,
        users,
        medicines,
        reasonsForConsultation,
        affectedSystems,
        cieDiagnostics,
        disabilityTypes,
        disabilityStatus,
        clinicalExams,
        userProfile,
        profiles,
        clients,
        collaboratorTypes,
        specialLabels,
        subgroups,
        vaccinationsRecords,
        dbMedicUsers,
        dbPatientUsers,
        dbSpecialLabels,
        calendars,
        dataMining,
        patients,
        medicalConsultation,
        medicalConditions,
        vaccinations,
        vaccinationScheme,
        dashboard,
        covid,
        periodicExams,
        digitalRecord,
        clinicalHistory,
        certificateHealthStatus,
        recordDisabilities,
        incidentReportSt7,
        medicalExam,
        habits,
        vaccinationRecord,
        incapacities,
        dbVaccinations,
        bloodPreasure,
        glucose,
        somatometry,
        patientEvolution,
        nutrition,
        classifications,
        subClassifications,
        editableInputs,
        notifications,
        updatePassword,
        assignmentsConcentrated,
        assignationPatient,
        affectedSystemsConcentrated,
        noticeOfPrivacy
    }
})
