import isVerifyTokenGuard from "@/guards/verifyTokenGuard";

export default {
    name: 'profile',
    component: () => import(/* webpackChunkName: "base-layout" */ '@/layouts/BaseLayout.vue'),
    children: [
        {
            path: '',
            name: 'profile-edit',
            beforeEnter: [isVerifyTokenGuard],
            component: () => import(/* webpackChunkName: "users-edit" */ '@/modules/configurations/profile/views/Edit.vue')
        },
    ]
}