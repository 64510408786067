import store from '@/store'

export const checkPermission = (permission) => {
    return store.getters['auth/getLoggedUser']['permissions'].includes(permission)
}
export const removeMenuPermission = (menu) => {
    menu.visible = true
    if (menu.hasChilds) {
        // console.log('tiene hijos')
        let parentAccess = false
        menu.children.forEach((hijo1) => {
            menu.visible = false
            if (hijo1.hasChilds) {
                hijo1.children.forEach(hijo2 => {
                    if (!checkPermission(hijo2.routeName)) {
                        // console.log('checkPermission', checkPermission(hijo2.routeName))
                        // console.log('checkPermission', hijo2.routeName)
                        hijo2.visible = false
                    } else {
                        hijo2.visible = true
                        parentAccess = true
                    }
                })
                hijo1.visible = parentAccess
            } else {
                if (!checkPermission(hijo1.routeName)) {
                    // console.log('checkPermission', checkPermission(hijo1.routeName))
                    // console.log('checkPermission', hijo1.routeName)
                    hijo1.visible = false
                } else {
                    hijo1.visible = true
                    parentAccess = true
                }
            }
            menu.visible = parentAccess
            // console.log('menu hijo', array)
            // removeMenuPermission(obj)
        })
    } else {
        if (!checkPermission(menu.routeName)) {
            menu.visible = false
            // console.log('no tiene permisos', menu)
        }
        // console.log('no tiene hijos')
    }
}

export const removeRootMenu = (menu) => {
    // console.log('evaluando', menu)
    let access = false
    if (menu.hasChilds) {
        menu.children.forEach(obj => {
            if (obj.visible) {
                // console.log('hijo activo', obj)
                access = true
            }
        })
    }
    // console.log('access', access)
    return access
}
