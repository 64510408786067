
import {errorHttpMessage} from "@/utils/errorDecodeUtil"
import { downloadFile } from "@/utils/filesUtil"
import configurationClient from '@/axiosClients/configurationClient'


export const saveProfile = async ({commit}, profile) => {
    try{
        const { data:result } = await configurationClient.post('/roles/create', profile)        
        const { message:m, error:e, data } = result.result    
        profile.role_id = data
        const { data:result2 } = await configurationClient.post(`/roles/${data}/assign`, profile)        
        const { message, error } = result.result    
        return {message, error, data}        
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    } 
}

export const findProfileById = async ({commit}, profileId) => {
    try{        
        const { data:result } = await configurationClient.get(`/roles/show/${profileId}`)        
        const { data, message, error } = result.result    
        return {data, message, error}        
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const editProfile = async ({commit, dispatch}, profile) => {
    try{
        const { data:result } = await configurationClient.post(`/roles/${profile.id}`, profile)    
        const { data:result2 } = await configurationClient.post(`/roles/${profile.id}/assign`, profile)  
        const { data:result3 } = await configurationClient.get(`/users/refresh-data/user-in-sesion`)      

        let permissions = result3.result.data

        dispatch('auth/updatePermissionsUserSesion',permissions, {root:true})

        //console.log('--- PERMISOS ---');
        //console.log(permissions)

        const { message, error } = result.result    
        return { message, error}        
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const deleteProfile = async ({commit}, profileId) => {
    try{        
        const { data:result } = await configurationClient.delete(`/roles/destroy/${profileId}`)        
        const { message, error } = result.result    
        return {message, error}        
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    } 
}

export const getPermissions = async ({commit}) => {
    try{
        
        const { data:result } = await configurationClient.get('/permissions/show')
        const { message, error, data } = result.result            
        const permissions = data
        return {message, error, permissions}        
    } catch(ex) {                     
        console.log('error', ex)
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    } 
}

export const getProfileTypes = async ({commit}) => {
    try{
        
        const { data:result } = await configurationClient.get('/roles/role-types')                
        const { message, error, data } = result.result                        
        return {message, error, data}        
    } catch(ex) {                     
        console.log('error', ex)
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    } 
}

export const downloadExcelReport = async ({commit}, fileName) => {
    try{        
        const response = await configurationClient.get(`/roles/download`,{
            responseType: 'blob'
        })                
        downloadFile(response.data, fileName)
    } catch(ex) {                     
        console.log('error', ex)
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    } 
}

export const getProfileList = async (_, {order, limit}) => {
    try{
        
        const { data:result } = await configurationClient.get(`/roles/custom/${order}/${limit}`)
        const { message, error, data } = result.result                    
        const profilesList = data.profiles_list
        return {message, error, profilesList}        
    } catch(ex) {                     
        console.log('error', ex)
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}
