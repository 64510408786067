
export const getEvents = ( state ) => {
    return [...state.events]
}

export const getEvent = (state) => {
    return {...state.event}
}

export const getCalendar = (state) => {
    return {...state.calendar}
}

export const getAction = (state) => {
    return state.action
}
