import verifyTokenGuardWithoutPermission from "@/guards/verifyTokenGuardWithoutPermission";

export default {
    name: 'incident-report-st7',
    component: () => import('@/layouts/BaseLayout.vue'),
    children: [
        {
            path: 'create/:patient_id',
            name: 'incident-report-st7-create',
            beforeEnter: [verifyTokenGuardWithoutPermission],
            meta: {
                breadcrumbs: [
                    {
                        route: {
                            name: 'patients-index',
                        },
                        transKey: 'breadcrumbs.patients-index',
                        isActive: false
                    },
                    {
                        route: {
                            name: 'digital-record-index',
                        },
                        params: ["patient_id"],
                        transKey: 'breadcrumbs.clinical-history',
                        isActive: false,
                    },
                    {
                        transKey: 'breadcrumbs.incident-report-st7',
                        isActive: true,
                    }
                ]
            },
            component: () => import('@/modules/digital-record/medical-reports/incident-report-st7/views/Create.vue')
        },
        {
            path: 'edit/:patient_id/:id',
            name: 'incident-report-st7-edit',
            beforeEnter: [verifyTokenGuardWithoutPermission],
            meta: {
                breadcrumbs: [
                    {
                        route: {
                            name: 'patients-index',
                        },
                        transKey: 'breadcrumbs.patients-index',
                        isActive: false
                    },
                    {
                        route: {
                            name: 'digital-record-index',
                        },
                        params: ["patient_id"],
                        transKey: 'breadcrumbs.clinical-history',
                        isActive: false,
                    },
                    {
                        transKey: 'breadcrumbs.incident-report-st7',
                        isActive: true,
                    }
                ]
            },
            component: () => import('@/modules/digital-record/medical-reports/incident-report-st7/views/Edit.vue')
        },
        {
            path: 'view/:patient_id/:id',
            name: 'incident-report-st7-view',
            beforeEnter: [verifyTokenGuardWithoutPermission],
            meta: {
                breadcrumbs: [
                    {
                        route: {
                            name: 'patients-index',
                        },
                        transKey: 'breadcrumbs.patients-index',
                        isActive: false
                    },
                    {
                        route: {
                            name: 'digital-record-index',
                        },
                        params: ["patient_id"],
                        transKey: 'breadcrumbs.clinical-history',
                        isActive: false,
                    },
                    {
                        transKey: 'breadcrumbs.incident-report-st7',
                        isActive: true,
                    }
                ]
            },
            component: () => import('@/modules/digital-record/medical-reports/incident-report-st7/views/View.vue')
        }
    ]
}