
export default () => ({
    user: JSON.parse(sessionStorage.getItem('user')) || null,
    token: sessionStorage.getItem('token') || null,
    refreshToken: sessionStorage.getItem('refreshToken') || null,
    expires: sessionStorage.getItem('expires') || null,
    date: sessionStorage.getItem('date') || null,
    host: sessionStorage.getItem('host') || null,
    isLandlord: sessionStorage.getItem('isLandlord') == 'true',
    emailRecoveryPassword: null,
    typeOfRequest: 'code',
    backupData: JSON.parse(sessionStorage.getItem('backupData')) || null,
    routeRedirect: sessionStorage.getItem('routeRedirect') || null,
})