import isVerifyTokenGuard from "@/guards/verifyTokenGuard"

export default {
    name: 'users',
    component: () => import(/* webpackChunkName: "base-layout" */ '@/layouts/BaseLayout.vue'),
    children: [
        {
            path: '',
            name: 'users-index',
            beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [
                    {                           
                        transKey: 'breadcrumbs.users-index',
                        isActive: true
                    }
                ]
            },
            component: () => import(/* webpackChunkName: "users-index" */ '@/modules/configurations/users/views/index.vue')
        },
        {
            path: 'create',
            name: 'users-create',
            beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [
                    {   
                        route: {
                            name: 'users-index',
                            params: {}
                        },
                        transKey: 'breadcrumbs.users-index',
                        isActive: false
                    },
                    {                           
                        transKey: 'breadcrumbs.users-create',
                        isActive: true
                    }
                ]
            },
            component: () => import(/* webpackChunkName: "users-create" */ '@/modules/configurations/users/views/create.vue')
        },
        {
            path: 'edit/:id',
            name: 'users-edit',
            beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [
                    {   
                        route: {
                            name: 'users-index',
                            params: {}
                        },
                        transKey: 'breadcrumbs.users-index',
                        isActive: false
                    },
                    {                           
                        transKey: 'breadcrumbs.users-edit',
                        isActive: true
                    }
                ]
            },
            component: () => import(/* webpackChunkName: "users-edit" */ '@/modules/configurations/users/views/edit.vue')
        }
    ]
}