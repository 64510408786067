import ephiClient from '@/axiosClients/ephiClient'
import {errorHttpMessage} from "@/utils/errorDecodeUtil"

export const getCreateCertificateHealthStatus = async ({commit}, dataForm) => {

    try {
        const {data: result} = await ephiClient.get('/patients/' + dataForm.patient_id + '/certificate-health-status/get-create')
        const {message, error, data} = result.result
        return {message, error, data}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex)
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }

}


export const saveCertificateHealthStatus = async ({commit}, {patientId, formData}) => {

    try {
        const {data: result} = await ephiClient.post('/patients/' + patientId.value + '/certificate-health-status', formData)
        const {message, error, data} = result.result
        return {message, error, data}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex)
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }

}


export const getCertificateHealthStatus = async ({commit}, {patientId, patientCertificateHealthStatusId}) => {

    try {
        const {data: result} = await ephiClient.get('/patients/' + patientId.value + '/certificate-health-status/show/' + patientCertificateHealthStatusId.value)
        const {message, error, data} = result.result
        return {message, error, data}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex)
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }

}


export const updateCertificateHealthStatus = async ({commit}, {patientId, patientCertificateHealthStatusId, formData}) => {

    try {
        const {data: result} = await ephiClient.post('/patients/' + patientId.value + '/certificate-health-status/' + patientCertificateHealthStatusId.value, formData)
        const {message, error, data} = result.result
        return {message, error, data}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex)
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }

}
