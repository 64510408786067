import {createRouter, createWebHistory} from 'vue-router'
import authRouter from '@/modules/auth/router'
import dashboardsRouter from '@/modules/dashboard/router'
import medicinesRouter from '@/modules/configurations/catalogs/medicines/router'
import usersRouter from '@/modules/configurations/users/router'
import profilesRouter from '@/modules/configurations/profiles/router'
import profilesPatientRouter from '@/modules/patient-profile/profile-patient/router'
import clientsRouter from '@/modules/clients/router'
import reasonForConsultationsRouter from '@/modules/configurations/catalogs/reason-for-consultation/router'
import affectedSystemsRouter from '@/modules/configurations/catalogs/affected-systems/router'
import diagnosticsRouter from '@/modules/configurations/catalogs/cie-diagnostics/router'
import disabilityTypesRouter from '@/modules/configurations/catalogs/disability-types/router'
import disabilityStatusesRouter from '@/modules/configurations/catalogs/disability-status/router'
import clinicalExamsRouter from '@/modules/configurations/catalogs/clinical-exams/router'
import profileRouter from '@/modules/configurations/profile/router'
import specialLabelsRouter from '@/modules/configurations/catalogs/special-labels/router'
import subgroupsRouter from '@/modules/configurations/catalogs/subgroups/router'
import vaccinationsRecordsRouter from '@/modules/configurations/catalogs/vaccinations-records/router'
import dbMedicUsersRouter from '@/modules/configurations/database-load/db-medic-users/router'
import dbPatientUsersRouter from '@/modules/configurations/database-load/db-patient-users/router'
import dbSpecialLabelAssignationRouter from '@/modules/configurations/database-load/db-assignation-special-label/router'
import dataMiningRouter from '@/modules/data-mining/router'
import medicalContidtionsRouter from '@/modules/configurations/catalogs/medical-conditions/router'
import partientsRouter from '@/modules/patients/router'
import vaccinationsRouter from '@/modules/vaccinations/router'
import vaccinationsSchemeRouter from '@/modules/configurations/catalogs/vaccinations-scheme/router'
import medicalConsultationRouter from '@/modules/digital-record/medical-consultation/router'
import covidRouter from '@/modules/covid/router'
import digitalRecordRouter from '@/modules/digital-record/digital-record/router'
import periodicExamsRouter from '@/modules/digital-record/periodic-exams/router'
import affectedSystemConcentratedRouter from '@/modules/digital-record/affected-systems/router'
import videocallRouter from '@/modules/videocall/router'
import clinicalHistoryRouter from '@/modules/digital-record/clinical-history/router'
import certificateHealthStatusRouter from '@/modules/digital-record/medical-reports/certificate-health-status/router'
import RecordDisabilitiesRouter from '@/modules/digital-record/medical-reports/record-disabilities/router'
import incidentReportSt7Router from '@/modules/digital-record/medical-reports/incident-report-st7/router'
import medicalExamRouter from '@/modules/digital-record/medical-exam/router'
import calendarRouter from '@/modules/calendar/router'
import patientHabitsRouter from '@/modules/patient-profile/habits/router'
import patientVaccinationRecordRouter from '@/modules/patient-profile/vaccination-record/router'
import patientIncapacitiesRouter from '@/modules/patient-profile/incapacities/router'
import patientCovidRouter from '@/modules/patient-profile/covid/router'
import patientBloodPressure from '@/modules/patient-profile/blood-pressure/router'
import dbVaccinations from '@/modules/configurations/database-load/db-vaccinations/router'
import myEvolutionRouter from '@/modules/patient-profile/evolution/router'
import patientGlucoseRouter from '@/modules/patient-profile/glucose/router'
import patientSomatometryRouter from '@/modules/patient-profile/somatometry/router'
import patientEvolutionRouter from '@/modules/digital-record/patient-evolution/router'
import patientNutritionRouter from '@/modules/patient-profile/nutrition/router'
import classificationsRouter from '@/modules/configurations/catalogs/classifications/router'
import subClassificationsRouter from '@/modules/configurations/catalogs/sub-classifications/router'
import editableInputsRouter from '@/modules/configurations/catalogs/editable-inputs/router'
import notificationsRouter from '@/modules/notifications/router'
import updatePasswordExpiredRouter from '@/modules/change-password-expired/router'
import assignmentsConcentratedRouter from '@/modules/assignments-concentrated/router'
import assignationPatientRouter from '@/modules/configurations/assignation-patient/router'
import noticePrivacyRouter from '@/modules/notice-privacy/router'

const routes = [
    {
        path: '/',
        ...authRouter,
    },
    {
        path: '/notice_privacy',
        ...noticePrivacyRouter,
    },
    {
        path: '/update-password',
        ...updatePasswordExpiredRouter
    },
    {
        path: '/dashboard',
        ...dashboardsRouter
    },
    {
        path: '/medicines',
        ...medicinesRouter
    },
    {
        path: '/users',
        ...usersRouter
    },
    {
        path: '/profiles',
        ...profilesRouter
    },
    {
        path: '/clients',
        ...clientsRouter
    },
    {
        path: '/reason-for-consultation',
        ...reasonForConsultationsRouter
    },
    {
        path: '/affected-systems',
        ...affectedSystemsRouter
    },
    {
        path: '/cie-diagnostics',
        ...diagnosticsRouter
    },
    {
        path: '/disability-types',
        ...disabilityTypesRouter
    },
    {
        path: '/disability-status',
        ...disabilityStatusesRouter
    },
    {
        path: '/clinical-exams',
        ...clinicalExamsRouter
    },
    {
        path: '/profile',
        ...profileRouter
    },
    // {
    //     path: '/collaborator-types',
    //     ...collaboratorTypesRouter
    // },
    {
        path: '/special-labels',
        ...specialLabelsRouter
    },
    {
        path: '/subgroups',
        ...subgroupsRouter
    },
    {
        path: '/vaccinations-records',
        ...vaccinationsRecordsRouter
    },
    {
        path: '/db-medic-users',
        ...dbMedicUsersRouter
    },
    {
        path: '/db-vaccinations',
        ...dbVaccinations
    }
    ,
    {
        path: '/db-patient-users',
        ...dbPatientUsersRouter
    },
    {
        path: '/db-assignation-special-label',
        ...dbSpecialLabelAssignationRouter
    },
    {
        path: '/data-mining',
        ...dataMiningRouter
    },
    {
        path: '/medical-conditions',
        ...medicalContidtionsRouter
    },
    {
        path: '/patients',
        ...partientsRouter
    },
    {
        path: '/vaccinations',
        ...vaccinationsRouter
    },
    {
        path: '/vaccinations-scheme',
        ...vaccinationsSchemeRouter
    },
    {
        path: '/medical-consultation',
        ...medicalConsultationRouter
    },
    {
        path: '/digital-record',
        ...digitalRecordRouter
    },
    {
        path: '/digital-record/periodic-exams',
        ...periodicExamsRouter
    },
    {
        path: '/digital-record/affected-systems',
        ...affectedSystemConcentratedRouter
    },
    {
        path: '/video',
        ...videocallRouter
    },
    {
        path: '/clinical-history',
        ...clinicalHistoryRouter
    },
    {
        path: '/digital-record/medical-reports/certificate-health-status',
        ...certificateHealthStatusRouter
    },
    {
        path: '/digital-record/medical-reports/record-disabilities',
        ...RecordDisabilitiesRouter
    },
    {
        path: '/covid',
        ...covidRouter
    },
    {
        path: '/digital-record/medical-reports/incident-report-st7',
        ...incidentReportSt7Router
    },
    {
        path: '/digital-record/medical-exam',
        ...medicalExamRouter
    },
    {
        path: '/digital-record/patient-evolution',
        ...patientEvolutionRouter
    },
    {
        path: '/calendar',
        ...calendarRouter
    },
    {
        path: '/patient/habits',
        ...patientHabitsRouter
    },
    {
        path: '/patient/vaccination-record',
        ...patientVaccinationRecordRouter
    },
    {
        path: '/patient/incapacities',
        ...patientIncapacitiesRouter
    },
    {
        path: '/patient/covid',
        ...patientCovidRouter
    },
    {
        path: '/patient-profile/',
        ...profilesPatientRouter
    },
    {
        path: '/patient/evolution/',
        ...myEvolutionRouter
    },
    {
        path: '/patient/blood-pressure',
        ...patientBloodPressure
    },
    {
        path: '/patient/glucose',
        ...patientGlucoseRouter
    },
    {
        path: '/patient/somatometry',
        ...patientSomatometryRouter
    },
    {
        path: '/patient/nutrition',
        ...patientNutritionRouter
    },
    {
        path: '/classifications',
        ...classificationsRouter
    },
    {
        path: '/sub-classifications',
        ...subClassificationsRouter
    },
    {
        path: '/editable-inputs',
        ...editableInputsRouter
    },
    {
        path: '/notifications',
        ...notificationsRouter
    },
    {
        path: '/assignments-concentrated',
        ...assignmentsConcentratedRouter
    },
    {
        path: '/assignation-patient',
        ...assignationPatientRouter
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    scrollBehavior(to, from, savedPosition) {
        // always scroll to top
        const scroll = document.getElementById('main_content')
        if (scroll) {
            scroll.scrollTop = 0
        }
        return {
            top: 0
        }
    }
})

export default router
