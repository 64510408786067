
import {errorHttpMessage} from "@/utils/errorDecodeUtil"
import { downloadFile } from "@/utils/filesUtil"
import configurationClient from '@/axiosClients/configurationClient'
import dashboardClient from '@/axiosClients/dashboardClient'


export const saveAffectedSystem = async ({commit}, affectedSystem) => {
    try{
        const { data:result } = await configurationClient.post('/catalog/affected_system/create', affectedSystem)
        const { message, error } = result.result
        return {message, error}
    } catch(ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const findAffectedSystemById = async ({commit}, affectedSystemId) => {
    try{
        const { data:result } = await configurationClient.get(`/catalog/affected_system/show/${affectedSystemId}`)
        const { data, message, error } = result.result
        return {data, message, error}
    } catch(ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const editAffectedSystem = async ({commit}, affectedSystem) => {
    try{
        const { data:result } = await configurationClient.post(`/catalog/affected_system/${affectedSystem.id}`, affectedSystem)
        const { message, error } = result.result
        return { message, error}
    } catch(ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const deleteAffectedSystem = async ({commit}, affectedSystemId) => {
    try{
        const { data:result } = await configurationClient.delete(`/catalog/affected_system/destroy/${affectedSystemId}`)
        const { message, error } = result.result
        return {message, error}
    } catch(ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getAffectedSystems = async ({commit}) => {
    try{

        const { data:result } = await configurationClient.get('/catalog/affected_system/getAffectedSystems')
        const { message, error, data } = result.result
        const affectedSystems = Object.entries(data).map(el => ({ id: el[0], text: el[1] }))
        //console.log('action', affectedSystems)
        return {message, error, affectedSystems}
    } catch(ex) {
        console.log('error', ex)
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const downloadExcelReport = async ({commit}, fileName) => {
    try{
        const response = await configurationClient.get(`/catalog/affected_system/download`,{
            responseType: 'blob'
        })
        downloadFile(response.data, fileName)
    } catch(ex) {
        console.log('error', ex)
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getDashboardInfo = async ({commit}, params) => {
    try{
        const { data:result } = await dashboardClient.get(`/affected-system${params}`)
        const { data, message, error } = result.result
        return {data, message, error}
    } catch(ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getDashboardTopFiveInfo = async ({commit}, params) => {
    try{
        const { data:result } = await dashboardClient.get(`/affected-system/top-five${params}`)
        const { data, message, error } = result.result
        return {data, message, error}
    } catch(ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}
