import isVerifyTokenGuard from "@/guards/verifyTokenGuard";

export default {
    name: 'calendar',
    component: () => import(/* webpackChunkName: "base-layout" */ '@/layouts/BaseLayout.vue'),
    children: [
        {
            path: '',
            name: 'calendar-index',
            beforeEnter: [isVerifyTokenGuard],
            component: () => import(/* webpackChunkName: "test-index" */ '@/modules/calendar/views/Calendar.vue')
        }
    ]
}
