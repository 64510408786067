import configurationClient from '@/axiosClients/configurationClient'
import operationClient from '@/axiosClients/operationClient'
import ephiClient from '@/axiosClients/ephiClient'
import catalogsClient from '@/axiosClients/catalogsClient'
import {errorHttpMessage} from "@/utils/errorDecodeUtil"
import {downloadFile} from "@/utils/filesUtil"
import dashboardClient from "@/axiosClients/dashboardClient";
import moment from "moment";

export const getAdminStatuses = async ({commit}) => {
    try {
        const {data: result} = await configurationClient.get(`/catalog/administrative-statuses`)
        const {data, message, error} = result.result
        return {data, message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true
        }
    }
}

export const getNationalities = async ({commit}) => {
    try {
        const {data: result} = await catalogsClient.get(`/nationalities`)
        const {data, message, error} = result.result
        return {data, message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true
        }
    }
}

export const getFederalEntities = async ({commit}) => {
    try {
        const {data: result} = await catalogsClient.get(`/federal-entities`)
        const {data, message, error} = result.result
        return {data, message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true
        }
    }
}

export const getMunicipalities = async ({commit}) => {
    try {
        const {data: result} = await catalogsClient.get(`/municipalities`)
        const {data, message, error} = result.result
        return {data, message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true
        }
    }
}

export const getLocalities = async ({commit}, searchTerms) => {
    try {
        const {federal_entity_code, municipality_code, search} = searchTerms
        const {data: result} = await catalogsClient.get(`/localities`, {params: {search: search, federal_entity_code: federal_entity_code, municipality_code: municipality_code}})
        const {data, message, error} = result.result
        return {data, message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true
        }
    }
}

export const getMaritalStatuses = async ({commit}) => {
    try {
        const {data: result} = await configurationClient.get(`/catalog/marital-statuses`)
        const {data, message, error} = result.result
        return {data, message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true
        }
    }
}

export const getGenders = async ({commit}) => {
    try {
        const {data: result} = await configurationClient.get(`/catalog/genders`)
        const {data, message, error} = result.result
        return {data, message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true
        }
    }
}

export const getExaminationFrecuencies = async ({commit}) => {
    try {
        const {data: result} = await configurationClient.get(`/catalog/examination-frequencies`)
        const {data, message, error} = result.result
        return {data, message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true
        }
    }
}

export const getCovidStatuses = async ({commit}) => {
    try {
        const {data: result} = await configurationClient.get(`/catalog/covid-statuses`)
        const {data, message, error} = result.result
        return {data, message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true
        }
    }
}

export const getCasesGeneral = async ({commit}) => {
    try {
        const {data: result} = await configurationClient.get(`/catalog/cases-general`)
        const {data, message, error} = result.result
        return {data, message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true
        }
    }
}

export const getCollaboratorAdvancement = async ({commit}) => {
    try {
        const {data: result} = await configurationClient.get(`/catalog/collaborator-advancement`)
        const {data, message, error} = result.result
        return {data, message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true
        }
    }
}

export const getReasonRuledOut = async ({commit}) => {
    try {
        const {data: result} = await configurationClient.get(`/catalog/reason-ruled-out`)
        const {data, message, error} = result.result
        return {data, message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true
        }
    }
}

export const getMedicalConditions = async ({commit}) => {
    try {
        const {data: result} = await configurationClient.get(`/catalog/medical-conditions`)
        const {data, message, error} = result.result
        return {data, message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true
        }
    }
}

export const getInitialSymptoms = async ({commit}) => {
    try {
        const {data: result} = await configurationClient.get(`/catalog/initial-symptoms`)
        const {data, message, error} = result.result
        return {data, message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true
        }
    }
}

export const getTypesOfTest = async ({commit}) => {
    try {
        const {data: result} = await configurationClient.get(`/catalog/types-of-test`)
        const {data, message, error} = result.result
        return {data, message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true
        }
    }
}

export const getResultsTest = async ({commit}) => {
    try {
        const {data: result} = await configurationClient.get(`/catalog/results-test`)
        const {data, message, error} = result.result
        return {data, message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true
        }
    }
}

export const getConsultationHistory = async ({commit}, patientId) => {
    try {
        const {data: result} = await ephiClient.get(`/patients/notes-evolution/${patientId}`)
        const {data, message, error} = result.result
        return {data, message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true
        }
    }
}

export const savePatient = async ({commit}, patient) => {
    try {
        const {data: result} = await operationClient.post('/patients/create', patient)
        const {message, error, data} = result.result
        return {message, error, data}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex)
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const editPatient = async ({commit}, patientData) => {
    try {
        const {data: result} = await operationClient.put(`/patients/update/${patientData.userId}`, patientData.patientFormValue)
        const {message, error} = result.result
        return {message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex)
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const findPatientById = async ({commit}, patientId) => {
    try {
        const {data: result} = await operationClient.get(`/patients/${patientId}`)
        const {data, message, error} = result.result
        return {data, message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true
        }
    }
}
// {{hostUrl}}/operation/api/v1/patients/validate-curp/:curp
export const validateCURP = async ({commit}, {curp, userId}) => {
    try {
        const {data: result} = await operationClient.get(`/patients/validate-curp/${curp}${userId ? '/' + userId : ''}`)
        const {data, message, error} = result.result
        return {data, message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true
        }
    }
}

export const getPatientByNomina = async ({commit}, nominaNumber) => {
    try {
        const {data: result} = await operationClient.get(`/patients/show/${nominaNumber}`)
        const {data, message, error} = result.result
        return {data, message, error}
    } catch (ex) {
        if (ex.error && ex.message) {
            const error_http_message = errorHttpMessage(ex);
            return {
                message: error_http_message.message,
                error_keys: error_http_message.error_keys,
                data: ex.response.data,
                error: true
            }
        } else {
            return {
                message: '',
                data: [],
                error: true
            }
        }
    }
}

export const getFilters = async ({commit}) => {
    try {
        const {data: result} = await operationClient.get(`/get-filters/patients`)
        const {data, message, error} = result.result
        return {data, message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getSubSpecialLabelsFilters = async ({commit}, specialLabelsSelected) => {
    try {
        const {data: result} = await operationClient.get(`/get-filters/subspecial-labels/${specialLabelsSelected.toString() || 0}`,)
        const {data, message, error} = result.result
        return {data, message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const downloadExcelReport = async ({commit}, data) => {
    try {
        const response = await operationClient.get(`/patients/download${data.filtersString}`, {
            responseType: 'blob'
        })
        downloadFile(response.data, `${data.fileName} ${moment().format("DD_MM_YYYY")}`)
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}
export const downloadExcelReportDetail = async ({commit}, data) => {
    try {
        const response = await operationClient.get(`/patients/assignment/concentrated/${data.assignationRequestsId}/download${data.filtersString}`, {
            responseType: 'blob'
        })
        downloadFile(response.data, `${data.fileName} ${moment().format("DD_MM_YYYY")}`)
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getDashboardInfo = async ({commit}, params) => {
    try {
        const {data: result} = await operationClient.get(`/patients/chart-data${params}`)
        const {data, message, error} = result.result
        return {data, message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getPatient = async ({commit}, params) => {
    try {
        const {data: result} = await operationClient.get(`/patients/${params.id}/user-information?module=${params.module || ''}&is_patient_id=${params.is_patient_id || ''}`)
        const {data, message, error} = result.result
        return {data, message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getPatientsList = async ({commit}, params) => {
    try {
        const {data} = await operationClient.get(`/patients/list/${params.patientName}/${params.patientId}`)
        return data.result
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const assignUsers = async ({commit}, data) => {
    try {
        const {data: result} = await operationClient.post('/patients/assignments', data)
        const {message, error} = result.result
        return {message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex)
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const assignMultipleUsers = async ({commit}, data) => {
    try {
        const {data: result} = await operationClient.post('/patients/assignments-multiple', data)
        const {message, error} = result.result
        return {message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex)
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}


export const unassignMultipleUsers = async ({commit}, data) => {
    try {
        const {data: result} = await operationClient.post('/patients/unassign-multiple', data)
        const {message, error} = result.result
        return {message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex)
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getPatientAssignments = async ({commit}, patientId) => {
    try {
        const {data} = await operationClient.get(`/patients/assignments/${patientId}`)
        return data.result
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const deleteUserAssignments = async ({commit}, assingmentId) => {
    try {
        const {data: result} = await operationClient.delete(`/patients/assignments/${assingmentId}`)
        const {message, error} = result.result
        return {message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex)
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getHealtProfessionalUsers = async ({commit}) => {
    try {
        const {data: response} = await configurationClient.get(`/roles/users/healthcare-professionals`)
        return response[0].result
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex)
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getAdministrativeUsers = async ({commit}) => {
    try {
        const {data: response} = await configurationClient.get(`/roles/users/administratives`)
        return response[0].result
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex)
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const requestAssignment = async ({commit}, patientId) => {
    try {
        const {data: result} = await operationClient.post(`/patients/assignment/individual`, {patient_id: patientId})
        const {message, error} = result.result
        return {message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex)
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const deleteRequestAssignment = async ({commit}, patientId) => {
    try {
        const {data: result} = await operationClient.delete(`/patients/assignment/individual/${patientId}`,)
        const {message, error} = result.result
        return {message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex)
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getTotalUserNotAssigned = async ({commit}, params) => {
    try {
        const {data: result} = await operationClient.get(`/patients/not-assignment/total?${params}`)
        const {message, error, data} = result.result
        return {message, error, data}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex)
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const setBulkRequestUserNotAssigned = async ({commit}, params) => {
    try {
        const {data: result} = await operationClient.post(`/patients/assignment/massive?${params.paramsInFilter}`, {patients_selected: params.patientSelected})
        const {message, error, data} = result.result
        return {message, error, data}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex)
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getBloodPressureRanges = async ({commit}) => {
    try {
        const {data: result} = await configurationClient.get(`/catalog/blood-pressure-ranges`)
        const {data, message, error} = result.result
        return {data, message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true
        }
    }
}

export const getLastSomatometry = async ({commit}, patientId) => {
    try {
        const {data: result} = await ephiClient.get(`/patients/somatometry/last-somatometry/${patientId}`)
        const {message, error, data} = result.result
        return {message, error, data}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex)
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}
