import ephiClient from '@/axiosClients/ephiClient'
import {errorHttpMessage} from "@/utils/errorDecodeUtil"

export const saveHabits = async ({commit}, data) => {
  try {
    const {data: result} = await ephiClient.post('/patients/habits', data)
    const {message, error} = result.result
    return {message, error}
  } catch (ex) {
    const error_http_message = errorHttpMessage(ex);
    return {
      message: error_http_message.message,
      error_keys: error_http_message.error_keys,
      data: ex.response.data,
      error: true
    }
  }
}

export const getHabitById = async ({commit}, habit_id) => {
  try{
    const { data:result } = await ephiClient.get(`/patients/habits/${habit_id}`)
    const { data, message, error } = result.result
    return {data, message, error}
  } catch(ex) {
    if (ex.error && ex.message) {
      const error_http_message = errorHttpMessage(ex);
      return {
        message: error_http_message.message,
        error_keys: error_http_message.error_keys,
        data: ex.response.data,
        error: true
      }
    } else {
      return {
        message: '',
        data: [],
        error: true
      }
    }
  }
}

export const updateHabit = async ({commit}, data) => {
  try {
    const {data: result} = await ephiClient.put(`/patients/habits/${data.habit_id}`, data.habit_data)
    const {message, error} = result.result
    return {message, error}
  } catch (ex) {
    const error_http_message = errorHttpMessage(ex);
    return {
      message: error_http_message.message,
      error_keys: error_http_message.error_keys,
      error: true
    }
  }
}

export const deleteHabit = async ({commit}, habit_id) => {
  try{
    const { data:result } = await ephiClient.delete(`/patients/habits/${habit_id}`)
    const { message, error } = result.result
    return { message, error}
  } catch(ex) {
    if (ex.error && ex.message) {
      const error_http_message = errorHttpMessage(ex);
      return {
        message: error_http_message.message,
        error_keys: error_http_message.error_keys,
        error: true
      }
    } else {
      return {
        message: '',
        error: true
      }
    }
  }
}

export const getHabitsPDF = async ({commit}, patient_id = null) => {
  const req_url = (patient_id != null) ? `/patients/habits/pdf/${patient_id}` : `/patients/habits/pdf`

  try {
    const {data: result} = await ephiClient.get(req_url, {responseType: "blob"})
    const blob = new Blob([result], {type: "application/pdf"});
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.target = "_blank"
    link.click();
    document.body.removeChild(link);
    // URL.revokeObjectURL(link.href);

    return true
  } catch (ex) {
    if (ex.error && ex.message) {
      const error_http_message = errorHttpMessage(ex);
      return {
        message: error_http_message.message,
        error_keys: error_http_message.error_keys,
        data: ex.response.data,
        error: true
      }
    } else {
      return {
        message: '',
        data: [],
        error: true
      }
    }
  }
}
