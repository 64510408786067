import verifyTokenGuardWithoutPermission from "@/guards/verifyTokenGuardWithoutPermission";

export default {
    name: 'periodic-exams',
    component: () => import(/* webpackChunkName: "base-layout" */ '@/layouts/BaseLayout.vue'),
    children: [
        {
            path: 'create/:patient_id',
            name: 'periodic-exam-create',
            beforeEnter: [verifyTokenGuardWithoutPermission],
            meta: {
                breadcrumbs: [
                    {
                        route: {
                            name: 'patients-index',
                        },
                        transKey: 'breadcrumbs.patients-index',
                        isActive: false
                    },
                    {
                        route: {
                            name: 'digital-record-index',
                        },
                        params: ["patient_id"],
                        transKey: 'breadcrumbs.clinical-history',
                        isActive: false,
                    },
                    {
                        transKey: 'breadcrumbs.periodic-exams',
                        isActive: true,
                    }
                ]
            },
            component: () => import('@/modules/digital-record/periodic-exams/views/Create.vue')
        },
        {
            path: 'edit/:patient_id/:id',
            name: 'periodic-exam-edit',
            beforeEnter: [verifyTokenGuardWithoutPermission],
            meta: {
                breadcrumbs: [
                    {
                        route: {
                            name: 'patients-index',
                        },
                        transKey: 'breadcrumbs.patients-index',
                        isActive: false
                    },
                    {
                        route: {
                            name: 'digital-record-index',
                        },
                        params: ["patient_id"],
                        transKey: 'breadcrumbs.clinical-history',
                        isActive: false,
                    },
                    {
                        transKey: 'breadcrumbs.periodic-exams',
                        isActive: true,
                    }
                ]
            },
            component: () => import('@/modules/digital-record/periodic-exams/views/Edit.vue')
        },
        {
            path: 'view/:patient_id/:id',
            name: 'periodic-exam-view',
            beforeEnter: [verifyTokenGuardWithoutPermission],
            meta: {
                breadcrumbs: [
                    {
                        route: {
                            name: 'patients-index',
                        },
                        transKey: 'breadcrumbs.patients-index',
                        isActive: false
                    },
                    {
                        route: {
                            name: 'digital-record-index',
                        },
                        params: ["patient_id"],
                        transKey: 'breadcrumbs.clinical-history',
                        isActive: false,
                    },
                    {
                        transKey: 'breadcrumbs.periodic-exams',
                        isActive: true,
                    }
                ]
            },
            component: () => import('@/modules/digital-record/periodic-exams/views/View.vue')
        },
        {
            path: '',
            name: 'periodic-exam-index',
            beforeEnter: [verifyTokenGuardWithoutPermission],
            meta: {
                breadcrumbs: [
                    {                        
                        transKey: 'breadcrumbs.record-periodic-examinations',
                        isActive: true,                    
                    }
                ]
            },
            component: () => import(/* webpackChunkName: "record-disabilities-index" */ '@/modules/digital-record/periodic-exams/views/Index.vue')
        }
    ]
}