import isVerifyTokenGuard from "@/guards/verifyTokenGuard";

export default {
    name: 'assignation-patient',
    component: () => import('@/layouts/BaseLayout.vue'),
    children: [
        {
            path: '',
            name: 'assignation-patient-index',
            beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [
                    {
                        transKey: 'breadcrumbs.assignation-patient-index',
                        isActive: true
                    }
                ]
            },
            component: () => import('@/modules/configurations/assignation-patient/views/Index.vue')
        }
    ]
}
