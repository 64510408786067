import t from "lodash/debounce";
import {
    openBlock as e,
    createElementBlock as o,
    createElementVNode as s,
    toDisplayString as n,
    withDirectives as r,
    Fragment as i,
    renderList as a,
    vModelSelect as l,
    createCommentVNode as h,
    vModelText as u,
    normalizeClass as c,
    createTextVNode as g,
    createBlock as d,
    resolveDynamicComponent as m,
    resolveComponent as f,
    createVNode as p,
} from "vue";
import "bootstrap/dist/css/bootstrap.min.css";
//import {useRouter} from 'vue-router'

var routeNameRowClick = null;
var paramsRowClick = null;

var y = {
    props: {
        lengthMenu: Array,
        menu: Number,
        isLengthMenu: Boolean,
        isSearch: Boolean,
        language: Object
    },
    emits: ["onChangeMenu", "onSearch"],
    data() {
        return {menuSelect: this.menu, search: ""};
    },
    methods: {
        selectMenu() {
            this.$emit("onChangeMenu", this.menuSelect);
        },
        inputSearch: t(function () {
            this.$emit("onSearch", this.search);
        }, 700)
    },
    computed: {
        formatLang() {
            const t = this.language.lengthMenu.split("_MENU_"),
                e = this.language.search;
            return {labelMenu1: t.length > 0 ? t[0] : null, labelMenu2: t.length > 1 ? t[1] : null, labelSearch: e};
        },
    },
};
const
    //S = {class: "d-flex flex-column flex-lg-row align-items-center justify-content-between"},
    S = {class: "row"},
    b = {key: 0, class: "col-xxl-9 col-xl-8 col-lg-6 col-md-6 col-sm-6 col-6 left-option-table align-items-center justify-content-between"}, //Row para lista de show 10 entries
    v = {class: "d-inline-block"},
    k = {for: "inputSearch", class: "col-form-label"},
    w = {class: "d-inline-block"},
    B = ["value"],
    M = {class: "d-inline-block"},
    L = {for: "inputSearch", class: "col-form-label"},
    C = {key: 1, class: "col-xxl-3 col-xl-4 col-lg-6 col-md-6 col-sm-6 col-6 text-end"}, //Row para buscador
    D = {class: "col-auto"},
    T = {class: "col-form-label"},
    E = {class: "pe-1"};
(y.render = function (t, c, g, d, m, f) {
    return (
        e(),
            o("div", S, [
                g.isLengthMenu && g.lengthMenu.length > 0
                    ? (e(),
                        o("div", b, [
                            s("div", v, [s("label", k, n(f.formatLang.labelMenu1), 1)]),
                            s("div", w, [
                                r(
                                    s(
                                        "select",
                                        {"onUpdate:modelValue": c[0] || (c[0] = (t) => (m.menuSelect = t)), class: "form-select form-select-sm", onChange: c[1] || (c[1] = (...t) => f.selectMenu && f.selectMenu(...t))},
                                        [
                                            (e(!0),
                                                o(
                                                    i,
                                                    null,
                                                    a(g.lengthMenu, (t, s) => (e(), o("option", {key: s, value: t}, n(t), 9, B))),
                                                    128
                                                )),
                                        ],
                                        544
                                    ),
                                    [[l, m.menuSelect]]
                                ),
                            ]),
                            s("div", M, [s("label", L, n(f.formatLang.labelMenu2), 1)]),
                        ]))
                    : h("v-if", !0),
                g.isSearch
                    ? (e(),
                            o("div", C, [
                                //s("div", D, [s("label", T, n(f.formatLang.labelSearch), 1)]),
                                s("div", E, [
                                    o("div", {class: "search_input"}, [
                                        r(
                                            s(
                                                "input",
                                                {
                                                    "onUpdate:modelValue": c[2] || (c[2] = (t) => (m.search = t)),
                                                    type: "text",
                                                    class: "form-control form-control-sm",
                                                    onInput: c[3] || (c[3] = (...t) => f.inputSearch && f.inputSearch(...t)),
                                                    placeholder: "Buscar..."
                                                },
                                                null,
                                                544
                                            ),
                                            [[u, m.search, void 0, {trim: !0}]]
                                        ),
                                        s(
                                            "button",
                                            null,
                                            [
                                                s(
                                                    "i",
                                                    {
                                                        class: "fa-solid fa-magnifying-glass",
                                                    },
                                                    null,
                                                    544
                                                )
                                            ],
                                            544
                                        )
                                    ])
                                ]),
                            ])
                    )
                    : h("v-if", !0),
            ])
    );
}),
    (y.__file = "src/Header.vue");
var _ = {
    props: {metaData: Object, links: Array, language: Object},
    methods: {
        selectPage(t) {
            this.$emit("onChangePage", {url: t, isPrevious: !!this.links[0], isNext: !!this.links[this.links.length - 1], isPaginate: !this.isPrevious && !this.isNext});
        },
    },
    computed: {
        formatLang() {
            const t = this.language.info.split("_FROM_").join(",").split("_TO_").join(",").split("_TOTAL_").join(",").split(",");
            return {labelInfo1: t.length > 0 ? t[0] : null, labelInfo2: t.length > 1 ? t[1] : null, labelInfo3: t.length > 2 ? t[2] : null, labelInfo4: t.length > 3 ? t[3] : null};
        },
    },
};
const A = {class: "text-center"},
    P = {class: "pagination"},
    O = {key: 0, class: "page-item disabled"},
    j = ["innerHTML"],
    H = ["onClick", "innerHTML"];
(_.render = function (t, r, l, u, g, d) {
    return (
        e(),
            o("div", A, [
                // s(
                //     "div",
                //     null,
                //     n(d.formatLang.labelInfo1) + " " + n(l.metaData.from) + " " + n(d.formatLang.labelInfo2) + " " + n(l.metaData.to) + " " + n(d.formatLang.labelInfo3) + " " + n(l.metaData.total) + " " + n(d.formatLang.labelInfo4),
                //     1
                // ),
                s("nav", null, [
                    s("ul", P, [
                        (e(!0),
                            o(
                                i,
                                null,
                                a(
                                    l.links,
                                    (t, n) => (
                                        e(),
                                            o(
                                                i,
                                                {key: n},
                                                [
                                                    t.url
                                                        ? (e(), o("li", {key: 1, class: c(["page-item", {active: t.active}])}, [s("a", {class: "page-link", onClick: (e) => d.selectPage(t.url), href: "#", innerHTML: t.label}, null, 8, H)], 2))
                                                        : (e(), o("li", O, [t.url ? h("v-if", !0) : (e(), o("a", {key: 0, class: "page-link", innerHTML: t.label}, null, 8, j))])),
                                                ],
                                                64
                                            )
                                    )
                                ),
                                128
                            )),
                    ]),
                ]),
            ])
    );
}),
    (_.__file = "src/Footer.vue");
const $ = require("./sort_asc.png"),
    x = require("./sort_desc.png"),
    q = require("./sort_both.png");
var R = {
    props: {columns: {required: !0, type: Array}, isSort: Boolean, sortDt: Object},
    emits: ["changeSort"],
    data() {
        return {sortBy: this.isSort && this.sortDt.sortBy ? this.sortDt.sortBy : null, sortable: !this.isSort || this.isSortable(), iconSortPath: this.isSort ? this.isIconSortPath() : null, iconSortBothPath: q};
    },
    methods: {
        sort(t, e) {
            this.isSort
                ? (!0 !== t.isSort && void 0 !== t.isSort) ||
                (!0 == !t.isAction &&
                    ((this.sortable = this.sortBy !== t.key || !e),
                        (this.iconSortPath = this.sortable ? $ : x),
                        (this.sortBy = t.key),
                        this.$emit("changeSort", {isSort: this.isSort, sort: {sortBy: this.sortBy, sort: this.sortable ? "ASC" : "DESC"}, column: t})))
                : this.$emit("changeSort", {isSort: this.isSort, sort: {sortBy: null, sort: null}, column: t});
        },
        isSortable() {
            return "ASC" === this.sortDt.sort || "DESC" !== this.sortDt.sort;
        },
        isIconSortPath() {
            return "ASC" === this.sortDt.sort ? $ : "DESC" === this.sortDt.sort ? x : null;
        },
    },
};
const z = {class: "table-light"},
    I = ["onClick", "width"],
    N = ["src"];
(R.render = function (t, r, l, u, c, d) {
    // console.log('columns',l)
    // console.log('t',t)
    return (
        e(),
            o("thead", z, [
                s("tr", null, [
                    (e(!0),
                        o(
                            i,
                            null,
                            a(
                                l.columns,
                                (t, s) => (
                                    e(),
                                        o(
                                            i,
                                            {key: s},
                                            [
                                                "object" != typeof t || (0 != t.isHide && null != t.isHide)
                                                    ? h("v-if", !0)
                                                    : (e(),
                                                        o(
                                                            "th",
                                                            {key: 0, onClick: (e) => d.sort(t, c.sortable), width: t.width},
                                                            [g(n(t.title) + " ", 1), l.isSort && !t.isAction ? (e(), o("img", {key: 0, src: c.sortBy == t.key && c.iconSortPath ? c.iconSortPath : c.iconSortBothPath}, null, 8, N)) : h("v-if", !0)],
                                                            8,
                                                            I
                                                        )),
                                            ],
                                            64
                                        )
                                )
                            ),
                            128
                        )),
                ]),
            ])
    );
}),
    (R.__file = "src/TableHeader.vue");
var F = {
    props: {
        columns: {required: !0, type: Array},
        data: Array,
        language: Object,
        isLoading: Boolean
    },
    methods: {
        redirect(t, s) {

            if (routeNameRowClick != null && paramsRowClick.length > 0 && !s.isAction) {

                var params_row = {}
                paramsRowClick.forEach(item => {
                    params_row[item] = btoa(t[item])
                })

                this.$router.push({name: "digital-record-index", params: params_row})
            }

        }
    }
};
const U = {key: 0},
    J = {key: 0},
    V = {key: 0},
    G = {key: 1},
    HH = ["onClick"];
(F.render = function (t, r, l, u, c, g) {
    //console.log('ldata1', l)
    return l.data.length > 0
        ? (e(),
            o("tbody", U, [
                h(
                    ' <template v-if="isLoading">\r\n            <tr>\r\n                {{language.loading}}                \r\n            </tr>\r\n        </template>\r\n        <template v-else-if="data.length === 0">\r\n            <tr class="text-center">\r\n                {{language.zeroRecords}}                \r\n            </tr>\r\n\r\n        </template> '
                ),
                (e(!0),
                    o(
                        i,
                        null,
                        a(
                            l.data,
                            (t, s) => {
                                // console.log('t', t)
                                // console.log('s', s)
                                // console.log('ldata', l)
                                return (
                                    e(),
                                        o("tr", {key: s, class: (routeNameRowClick != null ? "cursor-row" : "")}, [
                                                (e(!0),
                                                    o(
                                                        i,
                                                        null,
                                                        a(
                                                            l.columns,
                                                            (s, r) => (
                                                                e(),
                                                                    o(
                                                                        i,
                                                                        {key: r},
                                                                        [
                                                                            Object.keys(t).find((t) => t === s.key)
                                                                                ? (e(),
                                                                                    o(
                                                                                        i,
                                                                                        {key: 0},
                                                                                        [
                                                                                            s.isAction || (0 != s.isHide && null != s.isHide)
                                                                                                ? (e(),
                                                                                                    o(
                                                                                                        i,
                                                                                                        {key: 1},
                                                                                                        [
                                                                                                            "object" != typeof s.component || (0 != s.isHide && null != s.isHide)
                                                                                                                ? h("v-if", !0)
                                                                                                                : (e(),
                                                                                                                        o(
                                                                                                                            "td",
                                                                                                                            {
                                                                                                                                ...V, class: t.triage_classification + ' h--' + t.pid,
                                                                                                                                onClick: (e) => {
                                                                                                                                    //console.log(e, t, s)
                                                                                                                                    g.redirect(t, s)
                                                                                                                                }
                                                                                                                            },
                                                                                                                            [(e(), d(m(s.component), {row: t, column: s, permissions: s.permissions, actionsUrl: s.actionsUrl, HH}, null, 8, ["row", "column", "permissions", "actionsUrl"]))],
                                                                                                                            1312312,
                                                                                                                            HH
                                                                                                                        )
                                                                                                                ),
                                                                                                        ],
                                                                                                        2112
                                                                                                    ))
                                                                                                : (e(),
                                                                                                        o(
                                                                                                            "td",
                                                                                                            {
                                                                                                                ...J, class: t.triage_classification + ' h--' + t.pid,
                                                                                                                onClick: (e) => {
                                                                                                                    //console.log(e, t, s)
                                                                                                                    g.redirect(t, s)
                                                                                                                }
                                                                                                            },
                                                                                                            n(t[s.key]),
                                                                                                            1,
                                                                                                            HH
                                                                                                        )
                                                                                                ),
                                                                                        ],
                                                                                        2112
                                                                                    ))
                                                                                : h("v-if", !0),
                                                                        ],
                                                                        64
                                                                    )
                                                            )
                                                        ),
                                                        128
                                                    )),
                                            ],
                                        )
                                )
                            }
                        ),
                        128
                    )),
            ]))
        : l.isLoading
            ? h("v-if", !0)
            : (e(), o("tbody", G, [
                o("tr", null, [
                    s("td", {class: "text-center", colspan: "100"}, n(l.language.zeroRecords), 1)
                ])
            ]));
}),
    (F.__file = "src/TableBody.vue");
var K = {
    props: {columns: Array, data: Array, isSort: Boolean, sortDt: Object, language: Object, isLoading: Boolean},
    emits: ["onChangeSort"],
    components: {TableHeader: R, TableBody: F},
    methods: {
        setSort(t) {
            this.$emit("onChangeSort", t);
        },
    },
};
const Q = {class: "table table-hover table-responsive align-middle"};
(K.render = function (t, s, n, r, i, a) {
    const l = f("TableHeader"),
        h = f("TableBody");
    return (
        e(),
            p("div", {class: "parent-scroll"}, [
                p("div", {class: "child-scroll"}, [
                    o("table", Q, [
                        p(l, {columns: n.columns, isSort: n.isSort, sortDt: n.sortDt, onChangeSort: a.setSort}, null, 8, ["columns", "isSort", "sortDt", "onChangeSort"]),
                        p(h, {columns: n.columns, data: n.data, language: n.language, isLoading: n.isLoading}, null, 8, ["columns", "data", "language", "isLoading"]),
                    ])
                ])
            ])
    );
}),
    (K.__file = "src/Table.vue");
var W = {
    name: "JlDatatable",
    emits: ["error", "countPageChanged", "search", "gettingEntries", "entriesFetched", "columnClicked", "prevPaginated", "nextPaginated", "paginated"],
    props: {
        routeNameRowClick: {
            type: String,
            default: null
        },
        paramsRowClick: {
            type: Array,
            default: []
        },
        url: {
            type: String,
            validator(t) {
                if (void 0 === t) throw Error("The url is required.");
                /* if (!!!new RegExp("^(https?:\\/\\/)?((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|((\\d{1,3}\\.){3}\\d{1,3}))(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*(\\?[;&a-z\\d%_.~+=-]*)?(\\#[-a-z\\d_]*)?$", "i").test(t))
                    throw Error("The url is invalid."); */
                return !0;
            },
        },
        filters: {
            type: Object,
            required: false
        },
        requestOptions: {
            type: Object,
            default: (t) => ({method: "POST"}),
        },
        columns: {
            type: Array,
            validator(t) {
                if (!(t.length > 0)) throw Error("Has no assigned columns.");
                for (const e of t) {
                    if ("object" != typeof e) throw Error("The column is not an object.");
                    if (void 0 === e.key) throw Error("The key property of columns, is required.");
                    if ("string" != typeof e.key) throw Error("The key property of columns, is not string.");
                    if (void 0 === e.title) throw Error("The title property of columns, is required.");
                    if ("string" != typeof e.title) throw Error("The title property of columns, is not string.");
                    if (!0 !== e.isSort && !1 !== e.isSort && void 0 !== e.isSort) throw Error("The isSort property of columns, is not boolean.");
                    if (!0 !== e.isHide && !1 !== e.isHide && void 0 !== e.isHide) throw Error("The isHide property of columns, is not boolean.");
                    if ("string" != typeof e.width && void 0 !== e.width) throw Error("The width property of columns, is not string.");
                    if (!0 !== e.isSearch && !1 !== e.isSearch && void 0 !== e.isSearch) throw Error("The isSearch property of columns, is not boolean.");
                    if (!0 !== e.isAction && !1 !== e.isAction && void 0 !== e.isAction) throw Error("The isAction property of columns, is not boolean.");
                }
                return !0;
            },
        },
        lengthMenu: {
            type: Array,
            default: () => [10, 20, 50, 100],
            validator(t) {
                for (const e of t) if (!Number.isInteger(e)) throw Error("The menu is not a whole number.");
                return !0;
            },
        },
        isLengthMenu: {type: Boolean, default: !0},
        pageLength: {
            type: Number,
            default: () => 10,
            validator(t) {
                if (!Number.isInteger(t)) throw Error("The pageLength is not a whole number.");
                return !0;
            },
        },
        isSearch: {type: Boolean, default: !0},
        isSort: {type: Boolean, default: !0},
        sortDt: {type: Object},
        language: {type: Object, default: () => ({lengthMenu: null, info: null, zeroRecords: null, search: null})},
        isReload: {type: Boolean, default: !1},
    },
    components: {Header: y, Table: K, Footer: _},
    data() {
        return {
            urlBase: this.url,
            filtersBase: this.filters,
            menu: this.pageConfig(),
            search: "",
            sortBy: this.orderConfig().sortBy,
            sort: this.orderConfig().sort,
            isLoading: !1,
            data: [],
            links: [],
            metaData: {from: 0, to: 0, total: 0},
            lang: {
                lengthMenu: this.language.lengthMenu ? this.language.lengthMenu : "Mostrar_MENU_registros por página",
                //info: this.language.info ? this.language.info : "Showing_FROM_to_TO_of_TOTAL_entries",
                info: this.language.info ? this.language.info : " ",
                zeroRecords: this.language.zeroRecords ? this.language.zeroRecords : "No hay datos para mostrar",
                search: this.language.search ? this.language.search : "Buscar",
            },
        };
    },
    mounted() {
        this.getEntries()
    },
    computed: {
        filtersComputed() {
            return this.filtersBase
        }
    },
    methods: {
        async getEntries() {

            routeNameRowClick = this.routeNameRowClick;
            paramsRowClick = this.paramsRowClick;

            if (this.urlBase) {
                // console.log('filtersBase', this.filtersBase)
                /* console.log('filters', this.filters)
                console.log('filtersComputed', this.filtersComputed) */
                // console.log('urlBase', this.urlBase.split("?")[1])
                let t = this.urlBase.split("?")[1];
                const e = new URLSearchParams(t),
                    o = new Map(),
                    s = e.get("page");
                if (this.filtersBase) {
                    // console.log('hay filtros', Object.keys(this.filtersBase))
                    const keys = Object.keys(this.filtersBase)
                    let customFilters = ''
                    keys.forEach(el => {
                        customFilters += customFilters == '' ? `${el}=${this.filtersBase[el].join(',')}` : `&${el}=${this.filtersBase[el].join(',')}`
                        o.set(el, this.filtersBase[el].join(','))
                    })
                    // console.log('filtros final', customFilters)
                }
                o.set("page", s || 1),
                    o.set("pageLength", this.menu),
                    o.set("sortBy", this.sortBy),
                    o.set(
                        "sortColumns",
                        this.columns
                            .map((t) => ((!1 !== t.isAction && void 0 !== t.isAction) || (!0 !== t.isSort && void 0 !== t.isSort) ? "" : t.key))
                            .filter((t) => "" != t)
                            .join(",")
                    ),
                    o.set("sort", this.sort),
                    o.set("search", this.search),
                    o.set(
                        "searchColumns",
                        this.columns
                            .map((t) => ((!1 !== t.isAction && void 0 !== t.isAction) || (!0 !== t.isSearch && void 0 !== t.isSearch) ? "" : t.key))
                            .filter((t) => "" != t)
                            .join(",")
                    );


                var request_options = {
                    method: this.requestOptions.method,
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${this.requestOptions.token}`
                    },
                    body: JSON.stringify(this.requestOptions.body),
                };
                // console.log('body', this.requestOptions.body)
                const n = new URLSearchParams(o).toString(),
                    r = `${this.url}?${n}`,
                    i = new Request(r, request_options);
                // console.log('request', n)                
                this.$emit("gettingEntries", i);
                const a = fetch(i);
                this.isLoading = !0;
                const l = await a;
                if (l.ok) {
                    const t = await l.json();
                    this.$emit("entriesFetched", {request: i, data: t}),
                        (this.data = t.data),
                        (this.metaData.from = t.from ? t.from : 0),
                        (this.metaData.to = t.to ? t.to : 0),
                        (this.metaData.total = t.total ? t.total : 0),
                        (this.links = t.links),
                        (this.isLoading = !1);
                } else this.$emit("error", {type: "entries", message: "Get entries fail", context: {request: i, reqPromise: a}});
            }
        },
        setPage(t) {
            (this.urlBase = t.url), t.isPrev ? this.$emit("prevPaginated", t.url) : t.isNext ? this.$emit("nextPaginated", t.url) : this.$emit("paginated", t.url), this.getEntries();
        },
        setMenu(t) {
            (this.menu = t), this.$emit("countPageChanged", this.menu);
            const e = (this.metaData.to / t).toFixed(0);
            (this.urlBase = `${this.url}?page=${e}`), this.getEntries();
        },
        setSearch(t) {
            (this.urlBase = this.url), (this.search = t), this.$emit("search", this.search), this.getEntries();
        },
        setSort(t) {
            this.$emit("columnClicked", t), t.isSort && ((this.sortBy = t.sort.sortBy), (this.sort = t.sort.sort), this.getEntries());
        },
        orderConfig() {
            if (this.isSort) {
                if ("object" == typeof this.sortDt) {
                    if (void 0 !== this.sortDt.sortBy || "" !== this.sortDt.sortBy) {
                        if (this.columns.find((t) => !(t.key !== this.sortDt.sortBy || (!0 !== t.isSort && void 0 !== t.isSort) || (!1 !== t.isAction && void 0 !== t.isAction))))
                            return {sortBy: this.sortDt.sortBy, sort: ["ASC", "DESC"].includes(this.sortDt.sort) ? this.sortDt.sort : "ASC"};
                        throw Error("The column to be sorted, isSort is disabled or does not exist in the columns.");
                    }
                    if (!this.columns[0].isSort)
                        return {sortBy: "", sort: ""};
                    return {sortBy: this.columns[0].key, sort: "ASC"};
                }
                if (!this.columns[0].isSort)
                    return {sortBy: "", sort: ""};
                return {sortBy: this.columns[0].key, sort: "ASC"};
            }
            return {sortBy: "", sort: ""};
        },
        pageConfig() {
            if (this.lengthMenu.length > 0) return this.isLengthMenu ? (this.lengthMenu.includes(this.pageLength) ? this.pageLength : this.lengthMenu[0]) : 10;
            throw Error("The lengthMenu has no elements.");
        },
    },
    watch: {
        isReload(t) {
            t && this.getEntries();
        },
    },
};
//const X = {class: "row"};
const X = {class: "parent-table"}; //Row padre para la tabla

function Y(t) {
    Y.installed || ((Y.installed = !0), t.component("JlDatatable", W));
}

(W.render = function (t, n, r, i, a, l) {
    const h = f("Header"),
        u = f("Table"),
        c = f("Footer");
    return (
        e(),
            o("div", {class: "custom-table"}, [
                p(h, {lengthMenu: r.lengthMenu, isLengthMenu: r.isLengthMenu, menu: a.menu, isSearch: r.isSearch, language: a.lang, onOnChangeMenu: l.setMenu, onOnSearch: l.setSearch}, null, 8, [
                    "lengthMenu",
                    "isLengthMenu",
                    "menu",
                    "isSearch",
                    "language",
                    "onOnChangeMenu",
                    "onOnSearch",
                ]),
                s("div", X, [
                    p(u, {columns: r.columns, data: a.data, isSort: r.isSort, sortDt: {sortBy: a.sortBy, sort: a.sort}, language: a.lang, isLoading: a.isLoading, onOnChangeSort: l.setSort}, null, 8, [
                        "columns",
                        "data",
                        "isSort",
                        "sortDt",
                        "language",
                        "isLoading",
                        "onOnChangeSort",
                    ]),
                    p(c, {metaData: a.metaData, links: a.links, language: a.lang, onOnChangePage: l.setPage}, null, 8, ["metaData", "links", "language", "onOnChangePage"]),
                ]),
            ])
    );
}),
    (W.__file = "src/JlDatatable.vue");
const Z = {install: Y};
let tt = null;
"undefined" != typeof window ? (tt = window.Vue) : "undefined" != typeof global && (tt = global.vue), tt && tt.use(Z), (W.install = Y);
export {W as default};
