import isVerifyTokenGuard from "@/guards/verifyTokenGuard";
import verifyTokenGuardWithoutPermission from "@/guards/verifyTokenGuardWithoutPermission";

export default {
    name: 'medical-consultation',
    component: () => import(/* webpackChunkName: "base-layout" */ '@/layouts/BaseLayout.vue'),
    children: [
        {
            path: 'create/:patient_id',
            name: 'medical-consultation-index',
            beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [
                    {
                        route: {
                            name: 'digital-record-index',
                        },
                        params: ["patient_id"],
                        transKey: 'breadcrumbs.clinical-history',
                        isActive: false,
                    },
                    {
                        transKey: 'breadcrumbs.medical-consultation-create',
                        isActive: true
                    }
                ]
            },
            component: () => import(/* webpackChunkName: "medical-consultation-index" */ '@/modules/digital-record/medical-consultation/views/Create.vue')
        },
        {
            path: 'edit/:patient_id/:encounter_id',
            name: 'medical-consultation-edit',
            beforeEnter: [verifyTokenGuardWithoutPermission],
            meta: {
                breadcrumbs: [
                    {
                        route: {
                            name: 'digital-record-index',
                        },
                        params: ["patient_id"],
                        transKey: 'breadcrumbs.clinical-history',
                        isActive: false,
                    },
                    {
                        transKey: 'breadcrumbs.medical-consultation-edit',
                        isActive: true
                    }
                ]
            },
            component: () => import(/* webpackChunkName: "medical-consultation-edit" */ '@/modules/digital-record/medical-consultation/views/Edit.vue')
        }
    ]
}
