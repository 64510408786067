import useAuth from '@/modules/auth/composables/useAuth'
import router from "@/router";
import {useStore} from 'vuex'
import {useI18n} from 'vue-i18n'


//Decode error from API when return a bad request
export function errorHttpMessage(http_request) {

    const response = http_request.response;

    if (response != undefined) {

        const error_status = response.status;
        const store = useStore()

        if (error_status == 422) {
            let res = JSON.parse(response.request.response);
            //Laravel error request
            if (res.errors) {
                
                let errors = Object.keys(res.errors);
                let empty = [];
                let error_keys = [];
                let subtexts = '';
                errors.forEach((element) => {
                    let filteredValues = res.errors[element];
                    error_keys.push(element);
                    filteredValues.forEach((sub_element) => {
                        empty.push(`<li class="li_details_error">${sub_element}</li>`)
                    });
                    return empty;
                });
                subtexts = empty.join("");
                return {"message": subtexts, "error_keys": error_keys};
            } else {
                console.log(res)
                return {"message": res.result.message, "error_keys": null};
            }

        } else if (error_status == 401) {

            let res = JSON.parse(response.request.response);
            var messsage = res.result["message"] || ""
            var time_to_redirect = 0;

            sessionStorage.clear()

            //If the error 401 has a message, let set 3 seconds before redirect. In this way the user can read the message
            if (messsage != "") {
                time_to_redirect = 1000
            }

            // setTimeout(function () {
            //     router.push({name: 'auth-login'}).then(() => {
            //         router.go()
            //     })
            // }, time_to_redirect)

            router.push({name: 'auth-login'})

            return {"message": messsage, "error_keys": null};
        }

    } else {

        console.log(http_request)
        return {"message": "No hemos podido procesar tu solicitud, inténtalo nuevamente.", "error_keys": null};

    }

}


export function setErrorKey(key_with_error) {

    let error_keys = {};

    if (key_with_error != undefined) {
        key_with_error.forEach((element) => {
            error_keys[element] = true;
        });
    }

    //console.log(error_keys);

    return error_keys;
}
