import {errorHttpMessage} from "@/utils/errorDecodeUtil"
import calendarClient from '@/axiosClients/calendarClient'
import operationClient from "@/axiosClients/operationClient"
import videocallClient from "@/axiosClients/videocallClient"



export const getEvents = async ({commit}, {start_date, end_date, clear_data}) => {
    try{

        const { data:result } = await calendarClient.get(`/calendar?start_date=${start_date}&end_date=${end_date}`)
        const { message, error, data } = result.result 
        commit('setEvents', {events: data.events, clear_data: clear_data})
        return {message, error}        
    } catch(ex) {                     
        //console.log(ex)
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const save = async ({commit}, event) => {
    try{        
        const { data:result } = await calendarClient.post(`/calendar/event`, event)
        const { message, error } = result.result         
        return {message, error}        
    } catch(ex) {                     
        //console.log(ex)
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const update = async ({commit}, event) => {
    try{        
        const { data:result } = await calendarClient.post(`/calendar/event/${event.id}`, event)
        const { message, error } = result.result         
        return {message, error}        
    } catch(ex) {                     
        console.log(ex)
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const deleteById = async ({commit}, event_id) => {
    try{        
        const { data:result } = await calendarClient.delete(`/calendar/event/${event_id}`)
        const { message, error } = result.result         
        return {message, error}        
    } catch(ex) {                     
        console.log(ex)
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const deleteAttendee = async ({commit}, data) => {
    try{        
        const { data:result } = await calendarClient.delete(`/calendar/event/${data.event_id}/attende/${data.event_attende_id}`)
        const { message, error } = result.result         
        return {message, error}        
    } catch(ex) {                     
        console.log(ex)
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getEncounterOptions = async ({ commit }, patientId) => {
    try {
        const {data: response} = await operationClient.get(`/get-filters/calendar`)
        return response.result        
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getEventTypes = async ({commit}) => {
    try{

        const { data:result } = await calendarClient.get(`/get-event-types`)
        const { data } = result.result 
        return {data}        
    } catch(ex) {                     
        console.log(ex)
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getVideoCall = async ({commit}) => {
    try{

        const { data:response } = await videocallClient.post(`/make-room`)       
        return response.result.data.result 
    } catch(ex) {                     
        console.log(ex)
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}