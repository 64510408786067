import configurationClient from '@/axiosClients/configurationClient'
import ephiClient from '@/axiosClients/ephiClient'
import {downloadFile} from "@/utils/filesUtil"
import moment from 'moment'
import {errorHttpMessage} from "@/utils/errorDecodeUtil"

export const savePeriodicExam = async ({commit}, data) => {

    const patient_id = data.get("patient_id")

    try {
        const {data: result} = await ephiClient.post('/patients/' + patient_id + '/periodic-exams', data)
        const {message, error, periodic_exam_id} = result.result
        return {message, error, periodic_exam_id}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex)
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}


export const updatePeriodicExam = async ({commit}, {formData, patient_periodic_exam_id}) => {

    const patient_id = formData.get("patient_id")

    try {
        const {data: result} = await ephiClient.post('/patients/' + patient_id + '/periodic-exams/' + patient_periodic_exam_id, formData)
        const {message, error, periodic_exam_id} = result.result
        return {message, error, periodic_exam_id}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex)
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }

}


export const findPeriodicExam = async ({commit}, {patient_periodic_exam_id, patient_id}) => {

    try {
        const {data: result} = await ephiClient.get('/patients/' + patient_id + '/periodic-exams/' + patient_periodic_exam_id)
        const {data, message, error} = result.result
        return {data, message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true
        }
    }

}

export const deletePeriodicExam = async ({commit}, {patient_id, periodic_exam_id}) => {
    try {
        const {data: result} = await ephiClient.delete('/patients/' + patient_id + '/periodic-exams/' + periodic_exam_id)
        const {message, error} = result.result
        return {message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex)
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}


export const getClinicalExamsList = async ({commit}) => {

    try {
        const {data: result} = await configurationClient.get(`/catalog/clinicalExam/list`)
        const {data, message, error} = result.result
        return {data, message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true
        }
    }

}


export const deleteFileFromServer = async ({commit}, file_item) => {

    try {
        const {data: result} = await ephiClient.delete('/patients/' + file_item.patient_id + '/periodic-exams/' + file_item.patient_periodic_exam_id + '/file/' + file_item.file_id)
        const {message, error} = result.result
        return {message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex)
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }

}


export const deleteClinicalExamFromServer = async ({commit}, {patient_periodic_exams_clinical_exam_id, patient_periodic_exam_id, patient_id}) => {

    try {
        const {data: result} = await ephiClient.delete('/patients/' + patient_id + '/periodic-exams/' + patient_periodic_exam_id + "/clinic_exam/" + patient_periodic_exams_clinical_exam_id)
        const {message, error} = result.result
        return {message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex)
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }

}

export const downloadExcelReport = async ({commit}, data) => {
    try {
        const response = await ephiClient.get(`/patients/periodic-exams/excel/${data.filtersString}`, {
            responseType: 'blob'
        })
        downloadFile(response.data, `${data.fileName} ${moment().format("DD_MM_YYYY")}`)
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}


export const downloadPdf = async ({commit}, data) => {
    try {
        const {data: result} = await ephiClient.get(`/patients/${data.patientId}/periodic-exams/${data.periodicExamId}/download`, {responseType: "blob"})

        const blob = new Blob([result], {type: "application/pdf"});
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.target = "_blank"
        link.click();
        document.body.removeChild(link);

        return true
    } catch (ex) {
        //console.log(ex)
        return
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }

}
