import Swal from 'sweetalert2'

const fire = (config) => {
    // Overwritting swal config params
    config.customClass = 'sherpa-alert'
    config.icon = ''

    return Swal.fire({
        ...config,
        heightAuto: false
    })
}

const close = () => {
    return Swal.close()
}

const isLoading = () => {
    return Swal.isLoading()
}

export default {
    fire,
    close,
    isLoading
}