import configurationClient from '@/axiosClients/configurationClient'
import {errorHttpMessage} from "@/utils/errorDecodeUtil"
import {downloadFile} from "@/utils/filesUtil"

export const getRoles = async ({commit}) => {

    try {

        const response = await configurationClient.get(`/roles`)
        console.log(response.data);
        return response.data;

    } catch (ex) {

        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }

}

export const getRolesAdministrative = async ({commit}) => {

    try {

        const response = await configurationClient.get(`/roles/administrative-roles`)
        console.log(response.data);
        return response.data;

    } catch (ex) {

        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }

}

export const saveUser = async ({commit}, formData) => {

    try {
        const {data: result} = await configurationClient.post('/users/create', formData)
        const {message, error} = result.result
        return {message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }

}

export const findUserById = async ({commit}, userId) => {
    try {
        const {data: result} = await configurationClient.get(`/users/show/${userId}`)
        const {data, message, error} = result.result
        return {data, message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const editUser = async ({commit}, formData) => {
    try {
        const {data: result} = await configurationClient.post(`/users/${formData.get('id')}`, formData)
        const {message, error} = result.result
        return {message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const deleteUser = async ({commit}, userId) => {
    try {
        console.log(userId)
        const {data: result} = await configurationClient.delete(`/users/destroy/${userId}`)
        const {message, error} = result.result
        return {message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const downloadExcelReport = async ({commit}, fileName) => {
    try {
        const response = await configurationClient.get(`/users/download`, {
            responseType: 'blob'
        })
        downloadFile(response.data, fileName)
    } catch (ex) {
        console.log('error', ex)
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getUsersByProfile = async ({commit}, profileId) => {
    try {
        const {data: result} = await configurationClient.get(`/users/getByRole/${profileId}`)
        const {data, message, error} = result.result
        const options = data.map(el => ({id: el.id, text: el.name}))
        return {options, message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}