
import {errorHttpMessage} from "@/utils/errorDecodeUtil"
import configurationClient from '@/axiosClients/configurationClient'
import { downloadFile } from "@/utils/filesUtil"

export const save = async ({commit}, subgroup) => {
    try{
        const { data:result } = await configurationClient.post('/catalog/editable-fields/create', subgroup)
        const { message, error } = result.result
        return {message, error}
    } catch(ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const findById = async ({commit}, subgroupId) => {
    try{
        const { data:result } = await configurationClient.get(`/catalog/editable-fields/show/${subgroupId}`)
        const { data, message, error } = result.result
        return {data, message, error}
    } catch(ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const edit = async ({commit}, subgroup) => {
    try{
        const { data:result } = await configurationClient.post(`/catalog/editable-fields/${subgroup.id}`, subgroup)
        const { message, error } = result.result
        return { message, error}
    } catch(ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const deleteById = async ({commit}, subgroupId) => {
    try{
        const { data:result } = await configurationClient.delete(`/catalog/editable-fields/destroy/${subgroupId}`)
        const { message, error } = result.result
        return {message, error}
    } catch(ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getEditableInputList = async ({commit}) => {
    try{
        const { data:result } = await configurationClient.get(`/catalog/editable-fields/list`)
        const { data, message, error } = result.result
        return {data, message, error}
    } catch(ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getAllEditableInputs = async ({commit}) => {
    try{
        const { data:result } = await configurationClient.get(`/catalog/editable-fields/get-list`)
        const { data, message, error } = result.result
        return {data, message, error}
    } catch(ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const downloadExcelReport = async ({commit}, fileName) => {
    try{
        const response = await configurationClient.get(`/catalog/editable-fields/download`,{
            responseType: 'blob'
        })
        downloadFile(response.data, fileName)
    } catch(ex) {
        console.log('error', ex)
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}


export const isEnableAdd = async ({commit}, subgroupId) => {
    try{
        const { data:result } = await configurationClient.get(`/catalog/editable-fields/is-enable-add`)
        const { data, message, error } = result.result
        return {data, message, error}
    } catch(ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}
