import configurationClient from '@/axiosClients/configurationClient'
import ephiClient from '@/axiosClients/ephiClient'
import {downloadFile} from "@/utils/filesUtil"
import moment from 'moment'
import {errorHttpMessage} from "@/utils/errorDecodeUtil"


export const downloadExcel = async ({commit}, data) => {
    try {
        const response = await ephiClient.get(`/patients/affected-systems/excel/${data.filtersString}`, {
            responseType: 'blob'
        })
        downloadFile(response.data, `${data.fileName} ${moment().format("DD_MM_YYYY")}`)
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}
