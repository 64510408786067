import configurationClient from '@/axiosClients/configurationClient'
import {errorHttpMessage} from "@/utils/errorDecodeUtil"

export const saveUser = async ({commit}, formData) => {

    try {
        const {data: result} = await configurationClient.post('/profile/edit', formData)
        const {message, error} = result.result
        return {message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }

}

export const findUserByToken = async ({commit}, userId) => {
    try {
        const {data: result} = await configurationClient.get(`/profile`)
        const {data, message, error} = result.result
        return {data, message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, data: ex, error: true}
    }
}

export const deleteImage = async ({commit}, type) => {
    try {
        const {data: result} = await configurationClient.delete('/profile/' + type)
        const {data, message, error} = result.result
        return {data, message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, data: ex, error: true}
    }
}



