import isVerifyTokenGuard from "@/guards/verifyTokenGuard";

export default {
    name: 'db-patient-users',
    component: () => import(/* webpackChunkName: "base-layout" */ '@/layouts/BaseLayout.vue'),
    children: [
        {
            path: '',
            name: 'db-patient-users-index',
            beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [
                    {                           
                        transKey: 'breadcrumbs.db-patient-users-index',
                        isActive: true
                    }
                ]
            },
            component: () => import(/* webpackChunkName: "db-patient-users-index" */ '@/modules/configurations/database-load/db-patient-users/views/Index.vue')
        }
    ]
}