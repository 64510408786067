import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import JlDatatable from 'jl-datatable'
import {Tabs, Tab} from 'vue3-tabs-component';

import 'v-calendar/dist/style.css'
import VCalendar from 'v-calendar'

import '@/styles/styles.scss'
// import 'bootstrap'

import VueTheMask from 'vue-the-mask'

import i18n from '@/translations/index'

import Select2 from 'vue3-select2-component';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';

import '@/assets/fontawesome/css/fontawesome.css'
import '@/assets/fontawesome/css/brands.css'
import '@/assets/fontawesome/css/solid.css'
import '@/assets/fontawesome/css/regular.css'

createApp(App)
    .use(store)
    .use(i18n)
    .use(VueTheMask)
    .use(VCalendar, {})
    .use(router)
    .use(vSelect)
    .component('jl-datatable', JlDatatable)
    .component('Select2', Select2)
    .component('tabs', Tabs)
    .component('tab', Tab)
    .mount('#app')