import isVerifyTokenGuard from "@/guards/verifyTokenGuard";

export default {
    name: 'assignments-concentrated',
    component: () => import(/* webpackChunkName: "base-layout" */ '@/layouts/BaseLayout.vue'),
    children: [
        {
            path: '',
            name: 'assignments-concentrated-index',
            beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [                   
                    {                           
                        transKey: 'breadcrumbs.assignments-concentrated-index',
                        isActive: true
                    }
                ]
            },
            component: () => import( '@/modules/assignments-concentrated/views/Index.vue')
        },
        {
            path: 'details/:assignationRequestsId',
            name: 'assignments-concentrated-detail',
            beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [
                    {   
                        route: {
                            name: 'assignments-concentrated-detail',
                            params: {}
                        },
                        transKey: 'breadcrumbs.assignments-concentrated-index',
                        isActive: false,
                        isRedirect: true
                    },
                    {                           
                        transKey: 'breadcrumbs.assignments-concentrated-detail',
                        isActive: true,
                    }
                    
                ]
            },
            component: () => import( '@/modules/assignments-concentrated/views/Detail.vue')
        },/*
        {
            path: 'edit/:id',
            name: 'patients-edit',
            beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [
                    {   
                        route: {
                            name: 'patients-index',
                            params: {}
                        },
                        transKey: 'breadcrumbs.previous',
                        isActive: false,
                        isRedirect: true
                    },
                    {                           
                        transKey: 'breadcrumbs.patients-edit',
                        isActive: true
                    }
                ]
            },
            component: () => import( '@/modules/patients/views/Edit.vue')
        },
        {
            path: 'assignments-history/:userId/:patientId',
            name: 'assignments-history',
            beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [
                    {   
                        route: {
                            name: 'patients-index',
                            params: {}
                        },
                        transKey: 'breadcrumbs.patients-index',
                        isActive: false
                    },
                    {                           
                        transKey: 'breadcrumbs.patients-edit',
                        isActive: false,
                        isRedirect: true
                    },
                    {                           
                        transKey: 'breadcrumbs.patients-assignments-history',
                        isActive: true,
                    }
                ]
            },
            component: () => import( '@/modules/patients/views/AssigmentsHistory.vue')
        },*/
    ]
}