import vaccinationClient from "@/axiosClients/vaccinationClient"
import {errorHttpMessage} from "@/utils/errorDecodeUtil"

export const addVaccinationRecord = async ({commit}, data) => {
  try {
    const {data: result} = await vaccinationClient.post(`/vaccination/user`, data)
    const {message, error} = result.result
    return {message, error}
  } catch (ex) {
    const error_http_message = errorHttpMessage(ex);
    return {
      message: error_http_message.message,
      error_keys: error_http_message.error_keys,
      data: ex.response.data,
      error: true
    }
  }
}

export const updateVaccinationRecord = async ({commit}, data) => {
  try{
    const { data:result } = await vaccinationClient.post(`/vaccination/user/update/${data.vaccine_record_id}`, data.vaccination_data)
    const { message, error } = result.result
    return { message, error}
  } catch(ex) {
    const error_http_message = errorHttpMessage(ex);
    return {
      message: error_http_message.message,
      error_keys: error_http_message.error_keys,
      error: true
    }
  }
}

export const getVaccinationRecordById = async ({commit}, vaccine_record_id) => {
  try{
    const { data:result } = await vaccinationClient.get(`/vaccination/user/show/${vaccine_record_id}`)
    const { data, message, error } = result.result
    return {data, message, error}
  } catch(ex) {
    const error_http_message = errorHttpMessage(ex);
    return {
      message: error_http_message.message,
      error_keys: error_http_message.error_keys,
      data: ex.response.data,
      error: true
    }
  }
}

export const deleteVaccinationRecord = async ({commit}, vaccine_record_id) => {
  try{
    const { data:result } = await vaccinationClient.delete(`/vaccination/user/${vaccine_record_id}`)
    const { message, error } = result.result
    return { message, error}
  } catch(ex) {
    const error_http_message = errorHttpMessage(ex);
    return {
      message: error_http_message.message,
      error_keys: error_http_message.error_keys,
      error: true
    }
  }
}

export const getVaccinationRecordPDF = async ({commit}) => {
  try {
    const {data: result} = await vaccinationClient.get('/vaccination/user/pdf', {responseType: "blob"})
    const blob = new Blob([result], {type: "application/pdf"});
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.target = "_blank"
    link.click();
    document.body.removeChild(link);

    return true
  } catch (ex) {
    if (ex.error && ex.message) {
      const error_http_message = errorHttpMessage(ex);
      return {
        message: error_http_message.message,
        error_keys: error_http_message.error_keys,
        data: ex.response.data,
        error: true
      }
    } else {
      return {
        message: '',
        data: [],
        error: true
      }
    }
  }
}