import isVerifyTokenGuard from "@/guards/verifyTokenGuard";
import verifyTokenGuardWithoutPermission from "@/guards/verifyTokenGuardWithoutPermission";

export default {
    name: 'medical-exam',
    component: () => import(/* webpackChunkName: "base-layout" */ '@/layouts/BaseLayout.vue'),
    children: [
        {
            path: 'create/:patient_id',
            name: 'medical-exam-create',
            // beforeEnter: [isVerifyTokenGuard],
            beforeEnter: [verifyTokenGuardWithoutPermission],
            meta: {
                breadcrumbs: [     
                    {
                        route: {
                            name: 'digital-record-index',
                        },
                        params: ["patient_id"],
                        transKey: 'breadcrumbs.clinical-history',
                        isActive: false,
                    },              
                    {                           
                        transKey: 'breadcrumbs.medical-exam-create',
                        isActive: true
                    }
                ]
            },
            component: () => import(/* webpackChunkName: "medical-exam-create" */ '@/modules/digital-record/medical-exam/views/Create.vue')
        },
        {
            path: 'edit/:patient_id/:admission_medical_exam_id',
            name: 'medical-exam-update',
            // beforeEnter: [isVerifyTokenGuard],
            beforeEnter: [verifyTokenGuardWithoutPermission],
            meta: {
                breadcrumbs: [     
                    {
                        route: {
                            name: 'digital-record-index',
                        },
                        params: ["patient_id"],
                        transKey: 'breadcrumbs.clinical-history',
                        isActive: false,
                    },              
                    {                           
                        transKey: 'breadcrumbs.medical-exam-update',
                        isActive: true
                    }
                ]
            },
            component: () => import(/* webpackChunkName: "medical-exam-update" */ '@/modules/digital-record/medical-exam/views/Edit.vue')
        },
        {
            path: 'detail/:patient_id/:admission_medical_exam_id',
            name: 'medical-exam-detail',
            // beforeEnter: [isVerifyTokenGuard],
            beforeEnter: [verifyTokenGuardWithoutPermission],
            meta: {
                breadcrumbs: [     
                    {
                        route: {
                            name: 'digital-record-index',
                        },
                        params: ["patient_id"],
                        transKey: 'breadcrumbs.clinical-history',
                        isActive: false,
                    },              
                    {                           
                        transKey: 'breadcrumbs.medical-exam-detail',
                        isActive: true
                    }
                ]
            },
            component: () => import(/* webpackChunkName: "medical-exam-update" */ '@/modules/digital-record/medical-exam/views/Edit.vue')
        }
    ]
}