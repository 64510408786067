import ephiClient from "@/axiosClients/ephiClient";
import { errorHttpMessage } from "@/utils/errorDecodeUtil";

export const createBloodPressure = async ({ commit }, params) => {
  try {
    const { data: result } = await ephiClient.post(
      `/patients/blood-pressure`,
      params
    );
    return result.result;
  } catch (ex) {
    const error_http_message = errorHttpMessage(ex);
    return {
      message: error_http_message.message,
      error_keys: error_http_message.error_keys,
      data: ex.response.data,
      error: true,
    };
  }
};

export const downloadPdfBloodPressure = async ({ commit }, dataDownload) => {
  try {
    const { data: result } = await ephiClient.get(
      `/patients/blood-pressure/pdf`,
      { responseType: "blob" }
    );
    const blob = new Blob([result], { type: "application/pdf" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.target = "_blank";
    link.click();
    document.body.removeChild(link);
    return { data: null, error: null, message: null };
  } catch (ex) {
    // console.log(ex);
    return { data: null, message: null, error: ex };
    const error_http_message = errorHttpMessage(ex);
    return {
      message: error_http_message.message,
      error_keys: error_http_message.error_keys,
      data: ex.response.data,
      error: true,
    };
  }
};

export const deleteBloodPressure = async ({ commit }, id) => {
  try {
    const { data: result } = await ephiClient.delete(
      `/patients/blood-pressure/${id}`
    );
    const { message, error } = result.result;
    return { message, error };
  } catch (ex) {
    if (ex.error && ex.message) {
      const error_http_message = errorHttpMessage(ex);
      return {
        message: error_http_message.message,
        error_keys: error_http_message.error_keys,
        error: true,
      };
    } else {
      return {
        message: "",
        error: true,
      };
    }
  }
};

export const updateBloodPressure = async ({commit}, data) => {
    try {
        const {data: result} = await ephiClient.put(
            `/patients/blood-pressure/${data.id}`,
            data
        );
        const {message, error} = result.result;
        return {message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            error: true,
        };
    }
};

export const getBloodPressureById = async ({ commit }, id) => {
  try {
    const { data: result } = await ephiClient.get(
      `/patients/blood-pressure/${id}`
    );
    const { data, message, error } = result.result;
    return { data, message, error };
  } catch (ex) {
    if (ex.error && ex.message) {
      const error_http_message = errorHttpMessage(ex);
      return {
        message: error_http_message.message,
        error_keys: error_http_message.error_keys,
        data: ex.response.data,
        error: true,
      };
    } else {
      return {
        message: "",
        data: [],
        error: true,
      };
    }
  }
};
