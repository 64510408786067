import verifyTokenGuardWithoutPermission from "@/guards/verifyTokenGuardWithoutPermission";

export default {
    name: 'video',
    component: () => import('@/layouts/VideocallLayout.vue'),
    children: [
        {
            path: ':client_id/:room_name',
            name: 'index-video',
            //beforeEnter: [verifyTokenGuardWithoutPermission],
            component: () => import('@/modules/videocall/views/Index.vue')
        }
    ]
}