import ephiClient from '@/axiosClients/ephiClient'
import {errorHttpMessage} from "@/utils/errorDecodeUtil"

export const saveNutrition = async ({commit}, data) => {
  try {
    const {data: result} = await ephiClient.post('/patients/nutritions', data)
    const {message, error} = result.result
    return {message, error}
  } catch (ex) {
    const error_http_message = errorHttpMessage(ex);
    return {
      message: error_http_message.message,
      error_keys: error_http_message.error_keys,
      data: ex.response.data,
      error: true
    }
  }
}

export const getNutritionById = async ({commit}, nutrition_id) => {
  try{
    const { data:result } = await ephiClient.get(`/patients/nutritions/show/${nutrition_id}`)
    const { data, message, error } = result.result
    return {data, message, error}
  } catch(ex) {
    if (ex.error && ex.message) {
      const error_http_message = errorHttpMessage(ex);
      return {
        message: error_http_message.message,
        error_keys: error_http_message.error_keys,
        data: ex.response.data,
        error: true
      }
    } else {
      return {
        message: '',
        data: [],
        error: true
      }
    }
  }
}

export const updateNutrition = async ({commit}, data) => {
  try {
    const {data: result} = await ephiClient.put(`/patients/nutritions/${data.id}`, data.nutrition_data)
    const {message, error} = result.result
    return {message, error}
  } catch (ex) {
    const error_http_message = errorHttpMessage(ex);
    return {
      message: error_http_message.message,
      error_keys: error_http_message.error_keys,
      error: true
    }
  }
}

export const deleteNutrition = async ({commit}, nutrition_id) => {
  try{
    const { data:result } = await ephiClient.delete(`/patients/nutritions/${nutrition_id}`)
    const { message, error } = result.result
    return { message, error}
  } catch(ex) {
    if (ex.error && ex.message) {
      const error_http_message = errorHttpMessage(ex);
      return {
        message: error_http_message.message,
        error_keys: error_http_message.error_keys,
        error: true
      }
    } else {
      return {
        message: '',
        error: true
      }
    }
  }
}

export const getNutritionPDF = async ({commit}, data = null) => {
  const req_url = (data != null) ?
  (data.constructor === Object && data.patient_id != null && data.nutrition_id != null) ?
  `/patients/nutritions/pdf/${data.patient_id}/${data.nutrition_id}` : 
  `/patients/nutritions/pdf/${data}` :
  `/patients/nutritions/pdf`

  try {
    const {data: result} = await ephiClient.get(req_url, {responseType: "blob"})
    const blob = new Blob([result], {type: "application/pdf"});
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.target = "_blank"
    link.click();
    document.body.removeChild(link);
    // URL.revokeObjectURL(link.href);

    return true
  } catch (ex) {
    if (ex.error && ex.message) {
      const error_http_message = errorHttpMessage(ex);
      return {
        message: error_http_message.message,
        error_keys: error_http_message.error_keys,
        data: ex.response.data,
        error: true
      }
    } else {
      return {
        message: '',
        data: [],
        error: true
      }
    }
  }
}