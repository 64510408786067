import axios from 'axios'
import store from '@/store'

const vaccinationClient = axios.create({})

vaccinationClient.interceptors.request.use(config => {
    config.headers.Authorization = `Bearer ${store.getters['auth/getToken']}`
    config.headers.lang = store.getters['getLang']
    config.url = `${process.env.VUE_APP_PROTOCOL}://${store.getters['auth/getTenantHost']}/vaccination/api/v1${config.url}`
    return config;
})

export default vaccinationClient
