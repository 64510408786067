import ephiClient from '@/axiosClients/ephiClient'
import operationClient from '@/axiosClients/operationClient'
import {errorHttpMessage} from "@/utils/errorDecodeUtil"
import {downloadFile} from "@/utils/filesUtil"

export const saveMedicalExam = async ({commit}, params) => {
    try {
        const {data: result} = await ephiClient.post(`/patients/admission-medical-exam/${params.patientId}`, params.medicalExam)
        const {data, message, error} = result.result
        return {data, message, error}
    } catch (ex) {
        if (ex.error && ex.message) {
            const error_http_message = errorHttpMessage(ex);
            return {
                message: error_http_message.message,
                error_keys: error_http_message.error_keys,
                data: ex.response.data,
                error: true
            }
        } else {
            return {
                message: '',
                data: [],
                error: true
            }
        }
    }
}

export const updateMedicalExam = async ({commit}, data) => {
    try {
        const {data: result} = await ephiClient.post(`/patients/admission-medical-exam/${data.patientId}/${data.medicalExamId}`, data.medicalExam)
        const {message, error} = result.result
        return {message, error}
    } catch (ex) {
        if (ex.error && ex.message) {
            const error_http_message = errorHttpMessage(ex);
            return {
                message: error_http_message.message,
                error_keys: error_http_message.error_keys,
                data: ex.response.data,
                error: true
            }
        } else {
            return {
                message: '',
                data: [],
                error: true
            }
        }
    }
}

export const getMedicalExamById = async ({commit}, medicalExamId) => {
    try {
        const {data: result} = await ephiClient.get(`/patients/admission-medical-exam/${medicalExamId}`)
        const {data, message, error} = result.result
        return {data, message, error}
    } catch (ex) {
        if (ex.error && ex.message) {
            const error_http_message = errorHttpMessage(ex);
            return {
                message: error_http_message.message,
                error_keys: error_http_message.error_keys,
                data: ex.response.data,
                error: true
            }
        } else {
            return {
                message: '',
                data: [],
                error: true
            }
        }
    }
}

export const deleteMedicalExamFile = async ({commit}, file_item) => {
    try {
        const {data: result} = await ephiClient.delete(`/patients/admission-medical-exam/file/${file_item.id}`)
        const {message, error} = result.result
        return {message, error}
    } catch (ex) {
        if (ex.error && ex.message) {
            const error_http_message = errorHttpMessage(ex);
            return {
                message: error_http_message.message,
                error_keys: error_http_message.error_keys,
                data: ex.response.data,
                error: true
            }
        } else {
            return {
                message: '',
                data: [],
                error: true
            }
        }
    }
}

export const deleteTreatment = async ({commit}, {admission_medical_exams_treatment_id}) => {
    try {
        const {data: result} = await ephiClient.delete(`/patients/admission-medical-exam/treatment/${admission_medical_exams_treatment_id}`)
        const {message, error} = result.result
        return {message, error}
    } catch (ex) {
        if (ex.error && ex.message) {
            const error_http_message = errorHttpMessage(ex);
            return {
                message: error_http_message.message,
                error_keys: error_http_message.error_keys,
                data: ex.response.data,
                error: true
            }
        } else {
            return {
                message: '',
                data: [],
                error: true
            }
        }
    }
}
