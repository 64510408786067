import isVerifyTokenGuard from "@/guards/verifyTokenGuard";

export default {
    name: 'patient-evolution',
    component: () => import(/* webpackChunkName: "base-layout" */ '@/layouts/BaseLayout.vue'),
    children: [
        {
            path: ':patient_id',
            name: 'patient-evolution-index',
            beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [   
                    {
                        route: {
                            name: 'digital-record-index',
                        },
                        params: ["patient_id"],
                        transKey: 'breadcrumbs.clinical-history',
                        isActive: false,
                        isRedirect: true
                    },                
                    {                           
                        transKey: 'breadcrumbs.patient-evolution-index',
                        isActive: true
                    }
                ]
            },
            component: () => import(/* webpackChunkName: "index-evolution-index" */ '@/modules/digital-record/patient-evolution/views/Index.vue')
        }
    ]
}