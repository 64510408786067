import ephiClient from '@/axiosClients/ephiClient'
import configurationClient from '@/axiosClients/configurationClient'
import {errorHttpMessage} from "@/utils/errorDecodeUtil"

export const getFormInformation = async ({commit}, dataForm) => {

    try {
        const {data: result} = await ephiClient.get('/patients/incident-reports/create/information/' + dataForm.patientId)
        const {message, error, data} = result.result
        return {message, error, data}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex)
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }

}

export const getDiagnosticsByAffectedSystem = async ({commit}, dataForm) => {

    try {
        const {data: result} = await configurationClient.get('/catalog/diagnostics/affected-system/' + dataForm.affectedSystemId)
        const {message, error, data} = result.result
        return {message, error, data}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex)
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }

}


export const saveIncidentReportSt7 = async ({commit}, dataForm) => {

    try {
        const {data: result} = await ephiClient.post('/patients/incident-reports', dataForm.formData)
        const {message, error, data} = result.result
        return {message, error, data}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex)
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }

}


export const getIncidentReportSt7 = async ({commit}, dataForm) => {

    try {
        const {data: result} = await ephiClient.get('/patients/incident-reports/' + dataForm.incidentReportSt7Id,)
        const {message, error, data} = result.result
        return {message, error, data}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex)
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }

}


export const deleteFileFromServer = async ({commit}, dataForm) => {

    try {
        const {data: result} = await ephiClient.delete('/patients/incident-reports/file/' + dataForm.id)
        const {message, error, data} = result.result
        return {message, error, data}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex)
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }

}


export const updateIncidentReportSt7 = async ({commit}, dataForm) => {

    try {
        const {data: result} = await ephiClient.post('/patients/incident-reports/' + dataForm.incidentReportSt7Id, dataForm.formData)
        const {message, error, data} = result.result
        return {message, error, data}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex)
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }

}