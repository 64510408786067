import covidClient from '@/axiosClients/covidClient'
import dashboardClient from '@/axiosClients/dashboardClient'
import ephiClient from '@/axiosClients/ephiClient'
import operationClient from '@/axiosClients/operationClient'
import {downloadFile, downloadPdfFile} from "@/utils/filesUtil"
import {errorHttpMessage} from '@/utils/errorDecodeUtil'
import moment from 'moment';

export const save = async ({commit}, consultation) => {
    try {
        const {data: response} = await covidClient.post(`/medic-consultation/${consultation.patientId ? consultation.patientId : ''}`, consultation)
        commit('saveConsultation', false)
        return response.result
    } catch (ex) {
        commit('saveConsultation', false)
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const decline = async ({commit}, consultation) => {
    try {
        const {data: response} = await covidClient.post(`/medic-consultation/reject/${consultation.patientId}`, consultation)
        return response.result
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const triageReclassification = async ({commit}, consultation) => {
    try {
        const {data: response} = await covidClient.post(`/triage-reclassification/${consultation.covid_medical_consultation_id}`, consultation)
        return response.result
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getLastTriageClasification = async ({commit}, patientId) => {
    try {
        const {data: response} = await covidClient.get(`/triage-reclassification/last/${patientId}`)
        return response.result
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getCovidStatusHistories = async ({commit}, patientId) => {
    try {
        const {data: response} = await ephiClient.get(`/patients/status-covid-histories/${patientId}`)
        return response.result
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getById = async ({commit}, consultationId) => {
    try {
        const {data: response} = await covidClient.get(`/medic-consultation/show/${consultationId}`)
        return response.result
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getEvolutionNotes = async ({commit}, {patientId, searchQuery}) => {
    try {
        const {data: response} = await covidClient.get(`/covid-history/evolution-notes-list/patient/${patientId}?search=${searchQuery}`)
        // const {error, message, data} = response.result
        return response.result
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getGraphs = async ({commit}, patientId) => {
    try {
        const {data: response} = await covidClient.get(`/medic-consultation/graph/${patientId}`)
        // const {error, message, data} = response.result
        return response.result
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getSupplementalOxygens = async () => {
    try {
        const {data: response} = await covidClient.get(`/supplemental-oxygens`)
        return response.result
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getChronicDegenerativeDieases = async () => {
    try {
        const {data: response} = await covidClient.get(`/chronic-degenerative-diseases`)
        return response.result
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getImmunocompromises = async () => {
    try {
        const {data: response} = await covidClient.get(`/immunocompromises`)
        return response.result
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getConsciousnessStates = async () => {
    try {
        const {data: response} = await covidClient.get(`/consciousness-states`)
        return response.result
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getOtherSintoms = async () => {
    try {
        const {data: response} = await covidClient.get(`/other-sintoms`)
        return response.result
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getTriageClasifications = async () => {
    try {
        const {data: response} = await covidClient.get(`/triage-clasifications`)
        return response.result
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}
export const getMedicalConditions = async () => {
    try {
        const {data: result} = await configurationClient.get(`/catalog/medical-conditions`)
        return response.result
        //const {data, message, error} = result.result
        //return {data, message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getCovidTestByPatientId = async (_, patientId) => {
    try {
        const {data: response} = await covidClient.get(`/covid-history/covid-tests/patient/${patientId}`)
        return response.result
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getDashboardInfo = async ({commit}, params) => {
    try {
        const {data: result} = await dashboardClient.get(`/covid${params}`)
        return result.result
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getFilters = async ({commit}) => {
    try {
        const {data: result} = await operationClient.get(`/get-filters/triage`)
        return result.result
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getSubSpecialLabelsFilters = async ({commit}, specialLabelsSelected) => {
    try {
        const {data: result} = await operationClient.get(`/get-filters/subspecial-labels/${specialLabelsSelected.toString() || 0}`,)
        const {data, message, error} = result.result
        return {data, message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getTriageCount = async ({commit}, filters) => {
    try {
        const {data: result} = await covidClient.get(`/triage-concentrated/count${filters}`)
        return result.result
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const downloadExcelReport = async ({commit}, data) => {
    try {
        const response = await covidClient.get(`/triage-concentrated/download${data.filtersString}`, {
            responseType: 'blob'
        })
        downloadFile(response.data, `${data.fileName} ${moment().format("DD_MM_YYYY")}`)
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const downloadPdfPatientReport = async ({commit}, fileName) => {
    try {
        const response = await covidClient.get(`/medic-consultation/pdf`, {
            responseType: 'blob'
        })
        downloadPdfFile(response.data, 'pdf')
        return {data: '', error: '', message: ''}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getMedicConsultationGraph = async ({commit}) => {
    try {
        const {data: result} = await covidClient.get(`/medic-consultation/graph-patient`)
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const saveCovidTest = async ({commit}, {covidForm, patientId}) => {
    try {
        const {data: response} = await covidClient.post(`/covid-history/covid-tests/patient/${patientId}`, covidForm)
        return response.result
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const updateCovidTest = async ({commit}, {covidForm, patientId, patientCovidTestId}) => {
    try {
        const {data: response} = await covidClient.post(`/covid-history/covid-tests/patient/${patientId}/${patientCovidTestId}`, covidForm)
        return response.result
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const deleteCovidTest = async ({commit}, {patientId, patientCovidTestId}) => {
    try {
        const {data: response} = await covidClient.delete(`/covid-history/covid-tests/patient/${patientId}/${patientCovidTestId}`)
        return response.result
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}
