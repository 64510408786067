import ephiClient from '@/axiosClients/ephiClient'
import {errorHttpMessage} from "@/utils/errorDecodeUtil"

export const saveSomatometry = async ({commit}, data) => {
    try {
        const {data: result} = await ephiClient.post('/patients/somatometry', data)
        const {message, error} = result.result
        return {message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true
        }
    }
}

export const getSomatometryById = async ({commit}, somatometry_id) => {
    try {
        const {data: result} = await ephiClient.get(`/patients/somatometry/${somatometry_id}`)
        const {data, message, error} = result.result
        return {data, message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true
        }
    }
}

export const updateSomatometry = async ({commit}, data) => {
    try {
        const {data: result} = await ephiClient.put(`/patients/somatometry/${data.id}`, data.somatometry_data)
        const {message, error} = result.result
        return {message, error}
    } catch (ex) {
        if (ex.error && ex.message) {
            const error_http_message = errorHttpMessage(ex);
            return {
                message: error_http_message.message,
                error_keys: error_http_message.error_keys,
                error: true
            }
        } else {
            return {
                message: '',
                error: true
            }
        }
    }
}

export const deleteSomatometry = async ({commit}, somatometry_id) => {
    try {
        const {data: result} = await ephiClient.delete(`/patients/somatometry/${somatometry_id}`)
        const {message, error} = result.result
        return {message, error}
    } catch (ex) {
        if (ex.error && ex.message) {
            const error_http_message = errorHttpMessage(ex);
            return {
                message: error_http_message.message,
                error_keys: error_http_message.error_keys,
                error: true
            }
        } else {
            return {
                message: '',
                error: true
            }
        }
    }
}

export const getSomatometryPDF = async ({commit}, patient_id = null) => {
    const req_url = (patient_id != null) ? `/patients/somatometry/pdf/${patient_id}` : `/patients/somatometry/pdf`

    try {
        const {data: result} = await ephiClient.get(req_url, {responseType: "blob"})
        const blob = new Blob([result], {type: "application/pdf"});
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.target = "_blank"
        link.click();
        document.body.removeChild(link);

        return true
    } catch (ex) {
        if (ex.error && ex.message) {
            const error_http_message = errorHttpMessage(ex);
            return {
                message: error_http_message.message,
                error_keys: error_http_message.error_keys,
                data: ex.response.data,
                error: true
            }
        } else {
            return {
                message: '',
                data: [],
                error: true
            }
        }
    }
}
