import configurationClient from "@/axiosClients/configurationClient";
import {errorHttpMessage} from "@/utils/errorDecodeUtil";
import ephiClient from "@/axiosClients/ephiClient";
import {downloadFile} from "@/utils/filesUtil"
import moment from 'moment'
import operationClient from "@/axiosClients/operationClient";

export const getIncapacitiesPdf = async ({commit}, {id}) => {
    try {
        const {data: result} = await ephiClient.get(
            `/patients/incapacities/${id}/download`
        );
        return result
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};

export const getSubSpecialLabelsFilters = async ({commit}, specialLabelsSelected) => {
    try {
        const {data: result} = await operationClient.get(`/get-filters/subspecial-labels/${specialLabelsSelected.toString() || 0}`,)
        const {data, message, error} = result.result
        return {data, message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const postEditIncapacities = async ({commit}, {params, id}) => {
    try {
        const {data: result} = await ephiClient.post(
            `/patients/incapacities/edit/${id}`,
            params
        );
        return result.result;
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};

export const postIncapacities = async ({commit}, {params, id}) => {
    try {
        const {data: result} = await ephiClient.post(
            `/patients/incapacities`,
            params
        );
        return result.result;
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
            id,
        };
    }
};

export const deleteIncapacity = async ({commit}, id) => {
    try {
        const {data: result} = await ephiClient.delete(`/patients/incapacities/${id}`)
        const {message, error} = result.result
        return {message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex)
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getConsultReason = async ({commit}) => {
    try {
        const {data: result} = await configurationClient.get(
            `/catalog/consultReason`
        );
        const {message, error, ...res} = result;
        return {data: res.result.data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};

export const getAffectedSystem = async ({commit}) => {
    try {
        const {data: result} = await configurationClient.get(
            `/catalog/affected_system/show`
        );
        const {data, message, error} = result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};
export const getDiagnosis = async ({commit}) => {
    try {
        const {data: result} = await configurationClient.get(
            `/catalog/diagnostics/show`
        );
        const {data, message, error} = result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};
export const getTypeOfDisability = async ({commit}) => {
    try {
        const {data: result} = await configurationClient.get(
            `/catalog/incapacityType/show`
        );
        const {data, message, error} = result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};
export const getDisabilityModality = async ({commit}) => {
    try {
        const {data: result} = await configurationClient.get(
            `/catalog/incapacity-modality/show`
        );
        const {data, message, error} = result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};
export const getDisabilityStatus = async ({commit}) => {
    try {
        const {data: result} = await configurationClient.get(
            `/catalog/incapacityStatus/show`
        );
        const {data, message, error} = result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};

export const getIncapacitiesById = async ({commit}, id) => {
    try {
        const {data: result} = await ephiClient.get(
            `/patients/incapacities/show/${id}`
        );
        return result.result;
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};

export const deleteFileFromServer = async ({commit}, file_item) => {

    try {
        const {data: result} = await ephiClient.delete('/patients/incapacities/file/' + file_item.id)
        const {message, error} = result.result
        return {message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex)
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }

}

export const downloadExcelReport = async ({commit}, data) => {
    try {
        const response = await ephiClient.get(`/patients/incapacities/download${data.filtersString}`, {
            responseType: 'blob'
        })
        downloadFile(response.data, `${data.fileName} ${moment().format("DD_MM_YYYY")}`)
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}
