export const setEvents = (state, {events, clear_data}) => {

    // if (clear_data) {
        state.events = events
    // } else {
        // state.events = appendEvents(state, events)
    // }

}

export const selectDate = (state, data) => {
    state.event = {...data}
}

export const setAllDay = (state, event) => {
    state.event.is_all_day = event.is_all_day
    state.event.summary = event.summary
}

export const setAction = (state, action) => {
    state.action = action
}

export const setCalendar = (state, calendar) => {
    state.calendar = calendar
}

export const setEventType = (state, type) => {
    state.event.event_type_id = type
}

const appendEvents = (state, events) => {

    const current_events = [...state.events];
    const new_events = events;

    new_events.forEach(element => {

        const event_id = element.event_id;
        const is_already_show_in_calendar = current_events.find(element => element.event_id == event_id);

        if (is_already_show_in_calendar == undefined) {
            current_events.push(element)
        }

    })

    return current_events
}
