import notificationsClient from "@/axiosClients/notificationsClient";
import { errorHttpMessage } from "@/utils/errorDecodeUtil";

export const getNotifications = async ({commit}, date = null) => {
    try {
        const url = (date !== null && date !== '') ? `/notifications?date=${date}` : `/notifications`

        const {data: result} = await notificationsClient.get(url)
        const {data, message, error} = result.result
        return {data, message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true
        }
    }
}

export const setNotificationSeen = async ({commit}, notification_id) => {
    try {
        const {data: result} = await notificationsClient.post(`/notifications/seen?notification_id=${notification_id}`)
        const {data, message, error} = result.result
        return {data, message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true
        }
    }
}

export const markAllNotificationsAsRead = async ({commit}) => {
    try {
        const {data: result} = await notificationsClient.post(`/notifications/seen-all?status=last`)
        const {data, message, error} = result.result
        return {data, message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true
        }
    }
}

