import isVerifyTokenGuard from "@/guards/verifyTokenGuard";

export default {
    name: 'change-password-expired',
    component: () => import(/* webpackChunkName: "base-layout" */ '@/layouts/BaseLayout.vue'),
    children: [
        {
            path: '',
            name: 'change-password-expired-edit',
            //beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [
                    {
                        transKey: 'breadcrumbs.change-password-expired-index',
                        isActive: true
                    }
                ]
            },
            component: () => import( '@/modules/change-password-expired/views/Edit.vue')
        }
    ]
}
