import isVerifyTokenGuard from "@/guards/verifyTokenGuard";

export default {
    name: 'medical-conditions',
    component: () => import(/* webpackChunkName: "base-layout" */ '@/layouts/BaseLayout.vue'),
    children: [
        {
            path: '',
            name: 'medical-conditions-index',
            beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [                    
                    {                           
                        transKey: 'breadcrumbs.medical-conditions-index',
                        isActive: true
                    }
                ]
            },
            component: () => import(/* webpackChunkName: "medical-conditions-index" */ '@/modules/configurations/catalogs/medical-conditions/views/Index.vue')
        },
        {
            path: 'create',
            name: 'medical-conditions-create',
            beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [
                    {   
                        route: {
                            name: 'medical-conditions-index',
                            params: {}
                        },
                        transKey: 'breadcrumbs.medical-conditions-index',
                        isActive: false
                    },
                    {                           
                        transKey: 'breadcrumbs.medical-conditions-create',
                        isActive: true
                    }
                ]
            },
            component: () => import(/* webpackChunkName: "medical-conditions-create" */ '@/modules/configurations/catalogs/medical-conditions/views/Create.vue')
        },
        {
            path: 'edit/:id',
            name: 'medical-conditions-edit',
            beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [
                    {   
                        route: {
                            name: 'medical-conditions-index',
                            params: {}
                        },
                        transKey: 'breadcrumbs.medical-conditions-index',
                        isActive: false
                    },
                    {                           
                        transKey: 'breadcrumbs.medical-conditions-edit',
                        isActive: true
                    }
                ]
            },
            component: () => import(/* webpackChunkName: "medical-conditions-edit" */ '@/modules/configurations/catalogs/medical-conditions/views/Edit.vue')
        }
    ]
}