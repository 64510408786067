import store from '@/store'
import sweetalertUtil from '@/utils/sweetalertUtil'
import i18n from '@/translations'
import router from "@/router";

const verifyTokenGuard = async (to, from, next) => {

    const isExpired = store.getters["auth/isExpiredPasswordResetToken"]

    if (isExpired) {

        sweetalertUtil.fire({
            icon: 'error',
            title: 'Error',
            html: i18n.global.messages[i18n.global.locale]['errors']['token-password-expired'],
            timer: 3000
        }).then((result) => {

            store.commit("auth/clearPasswordResetToken")

            router.push({name: 'auth-login'}).then(() => {
                router.go()
            })

        })

    }

    next()

}

export default verifyTokenGuard