
import {errorHttpMessage} from "@/utils/errorDecodeUtil"
import configurationClient from '@/axiosClients/configurationClient'
import dashboardClient from '@/axiosClients/dashboardClient'
import { downloadFile } from "@/utils/filesUtil"

export const saveSpecialLabel = async ({commit}, specialLabel) => {
    try{
        const { data:result } = await configurationClient.post('/catalog/specialLabels/create', specialLabel)        
        const { message, error } = result.result    
        return {message, error}        
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    } 
}

export const findSpecialLabelById = async ({commit}, specialLabelId) => {
    try{        
        const { data:result } = await configurationClient.get(`/catalog/specialLabels/show/${specialLabelId}`)        
        const { data, message, error } = result.result    
        return {data, message, error}        
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const editSpecialLabel = async ({commit}, specialLabel) => {
    try{
        const { data:result } = await configurationClient.post(`/catalog/specialLabels/${specialLabel.id}`, specialLabel)        
        const { message, error } = result.result    
        return { message, error}        
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const deleteSpecialLabel = async ({commit}, specialLabelId) => {
    try{        
        const { data:result } = await configurationClient.delete(`/catalog/specialLabels/destroy/${specialLabelId}`)        
        const { message, error } = result.result    
        return {message, error}        
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    } 
}

export const downloadExcelReport = async ({commit}, fileName) => {
    try{        
        const response = await configurationClient.get(`/catalog/specialLabels/download`,{
            responseType: 'blob'
        })                
        downloadFile(response.data, fileName)
    } catch(ex) {                     
        console.log('error', ex)
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    } 
}

export const getColors = async ({commit}) => {
    try{
        const { data:result } = await configurationClient.get(`/catalog/specialLabels/colors`)        
        const { data, message, error } = result.result    
        return {data, message, error}        
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getIcons = async ({commit}) => {
    try{
        const { data:result } = await configurationClient.get(`/catalog/specialLabels/icons`)        
        const { data, message, error } = result    
        return {data, message, error}        
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getDashboardInfo = async ({commit}, params) => {
    try{
        const { data:result } = await dashboardClient.get(`/special-labels${params}`)        
        const { data, message, error } = result.result
        return {data, message, error}        
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getSpecialLabelList = async ({commit}) => {
    try{
        const { data:result } = await configurationClient.get(`/special-labels/list-special-labels`)        
        const { data, message, error } = result    
        return {data, message, error}        
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}