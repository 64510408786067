// export const myAction = ( state ) => {

// }


export const login = (state, {user, accessToken, refreshToken, expiresIn, route_redirect, date, host, isLandlord}) => {
    if (accessToken) {
        sessionStorage.setItem('token', accessToken)
        state.token = accessToken
        //sessionStorage.setItem('expires', expiresIn)
        //state.expires = expiresIn
        sessionStorage.setItem('date', date)
        state.date = date
    }

    if (refreshToken) {
        sessionStorage.setItem('refreshToken', refreshToken)
        state.refreshToken = refreshToken
    }

    if (user) {
        sessionStorage.setItem('user', JSON.stringify(user))
        state.user = user
    }

    if (host) {
        sessionStorage.setItem('host', host)
        state.host = host
    }

    if (route_redirect) {
        sessionStorage.setItem('routeRedirect', route_redirect)
        state.routeRedirect = route_redirect
    }

    sessionStorage.setItem('isLandlord', isLandlord)
    state.isLandlord = isLandlord

}

export const logout = (state) => {
    state.token = null
    //state.expires = null
    state.refreshToken = null
    state.user = null
    state.date = null
    state.host = null
    state.isLandlord = null
    sessionStorage.removeItem('token')
    sessionStorage.removeItem('expires')
    sessionStorage.removeItem('refreshToken')
    sessionStorage.removeItem('user')
    sessionStorage.removeItem('date')
    sessionStorage.removeItem('host')
    sessionStorage.removeItem('isLandlord')
    sessionStorage.removeItem('backupData')
}

export const requestEmailRecoveryPassword = (state, email) => {
    state.emailRecoveryPassword = email
}

export const setTypeOfRequest = (state, {type}) => {
    sessionStorage.setItem('typeOfRequest', type)
}

export const setIsSecurityQuestionsAvailable = (state, {isAvailable}) => {
    sessionStorage.setItem('IsSecurityQuestionsAvailable', isAvailable)
}

export const setUserId = (state, {userId}) => {
    state.userId = userId
}

export const setPasswordResetToken = (state, {token}) => {
    sessionStorage.setItem('passwordResetToken', token)
}

export const isSecurityQuestionsAvailable = (state, {isSecurityQuestionsAvailable}) => {
    sessionStorage.setItem('isSecurityQuestionsAvailable', isSecurityQuestionsAvailable)
}

export const clearPasswordResetToken = (state) => {
    sessionStorage.removeItem('passwordResetToken')
    sessionStorage.removeItem('typeOfRequest')
}

export const updateToken = (state, {token, expiresIn, date}) => {

    if (token) {
        sessionStorage.setItem('token', token)
        state.token = token
        sessionStorage.setItem('date', date)
        state.date = date
    }

}

export const setTenantHost = (state, tenantHost) => {
    sessionStorage.setItem('host', tenantHost)
    state.host = tenantHost
}

export const setAccessToken = (state, accessToken) => {
    sessionStorage.setItem('token', accessToken)
    state.token = accessToken
}

export const backupData = (state, data) => {
    if (data) {
        state.backupData = {...data}
        sessionStorage.setItem('backupData', JSON.stringify(data))
    } else {
        state.backupData = data
        sessionStorage.setItem('backupData', JSON.stringify(data))
    }
}

export const changePermissions = (state, data) => {
    state.user.permissions = [...data]
}

export const updatePermissionsUserSesion = (state, permissions) => {

    let user = JSON.parse(sessionStorage.getItem('user'))

    user.permissions = permissions;

    sessionStorage.setItem('user', JSON.stringify(user))
    state.user = user
}
