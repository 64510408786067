import ephiClient from '@/axiosClients/ephiClient'
import operationClient from '@/axiosClients/operationClient'
import {errorHttpMessage} from "@/utils/errorDecodeUtil"
import { downloadFile } from "@/utils/filesUtil"

export const getConsultationTypes = async ({commit}) => {
    try {
        const { data:result } = await ephiClient.get(`/patients/short-medical-history-types`)
        const { data, message, error } = result.result
        return {data, message, error}
    } catch(ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true
        }
    }
}

export const getPatientShortData = async ({commit}, patientId) => {
    try {
        const {data: result} = await operationClient.get(`/patients/show/short-medical-history/${patientId}`)
        const { data, message, error } = result.result
        return {data, message, error}
    } catch (ex) {
        if (ex.error && ex.message) {
            const error_http_message = errorHttpMessage(ex);
            return {
                message: error_http_message.message,
                error_keys: error_http_message.error_keys,
                data: ex.response.data,
                error: true
            }
        } else {
            return {
                message: '',
                data: [],
                error: true
            }
        }
    }
}

export const saveShortClinicalHistory = async ({commit}, params) => {
    try {
        const {data: result} = await ephiClient.post('/patients/short-medical-histories', params)
        const {data, message, error} = result.result
        return {data, message, error}
    } catch(ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true
        }
    }
}

export const updateShortClinicalHistory = async ({commit}, shortClinicalHistoryObj) => {
    try {
        const {data: result} = await ephiClient.post(`/patients/short-medical-histories/edit/${shortClinicalHistoryObj.id}`, shortClinicalHistoryObj.data)
        const {data, message, error} = result.result
        return {data, message, error}
    } catch(ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true
        }
    }
}

export const getShortClinicalHistoryById = async ({commit}, shortClinicalHistoryId) => {
    try {
        const {data: result} = await ephiClient.get(`/patients/short-medical-histories/${shortClinicalHistoryId}`)
        const { data, message, error } = result.result
        return {data, message, error}
    } catch (ex) {
        if (ex.error && ex.message) {
            const error_http_message = errorHttpMessage(ex);
            return {
                message: error_http_message.message,
                error_keys: error_http_message.error_keys,
                data: ex.response.data,
                error: true
            }
        } else {
            return {
                message: '',
                data: [],
                error: true
            }
        }
    }
}

export const deleteShortMedicalHistoryFile = async ({commit}, file_item) => {
    try {
        const {data: result} = await ephiClient.delete(`/patients/short-medical-history-file/${file_item.id}`)
        const {message, error} = result.result
        return {message, error}
    } catch (ex) {
        if (ex.error && ex.message) {
            const error_http_message = errorHttpMessage(ex);
            return {
                message: error_http_message.message,
                error_keys: error_http_message.error_keys,
                data: ex.response.data,
                error: true
            }
        } else {
            return {
                message: '',
                data: [],
                error: true
            }
        }
    }
}
