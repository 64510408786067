import verifyTokenGuardWithoutPermission from "@/guards/verifyTokenGuardWithoutPermission";
import isVerifyTokenGuard from "@/guards/verifyTokenGuard";
export default {
    name: 'record-disabilities',
    component: () => import(/* webpackChunkName: "base-layout" */ '@/layouts/BaseLayout.vue'),
    children: [
        {
            path: 'create/:patient_id',
            name: 'record-disabilities-create',
            beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [
                    {   
                        route: {
                            name: 'patients-index',
                            params: {}
                        },
                        transKey: 'breadcrumbs.previous',
                        isActive: false,
                        isRedirect: true
                    },
                    {                           
                        transKey: 'record-disabilities.title',
                        isActive: true
                    }
                ]
            },
            component: () => import('@/modules/digital-record/medical-reports/record-disabilities/views/Create.vue')
        }, 
        {
            path: 'edit/:patient_id/:id',
            name: 'record-disabilities-edit',
            beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [
                    {   
                        route: {
                            name: 'patients-index',
                            params: {}
                        },
                        transKey: 'breadcrumbs.previous',
                        isActive: false,
                        isRedirect: true
                    },
                    {                           
                        transKey: 'breadcrumbs.record-disabilities-edit',
                        isActive: true
                    }
                ]
            },
            component: () => import('@/modules/digital-record/medical-reports/record-disabilities/views/Edit.vue')
        },
        {
            path: 'view/:patient_id/:id',
            name: 'record-disabilities-view',
            beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [
                    {   
                        route: {
                            name: 'patients-index',
                            params: {}
                        },
                        transKey: 'breadcrumbs.previous',
                        isActive: false,
                        isRedirect: true
                    },
                    {                           
                        transKey: 'breadcrumbs.record-disability-detail',
                        isActive: true
                    }
                ]
            },
            component: () => import('@/modules/digital-record/medical-reports/record-disabilities/views/View.vue')
        },
        {
            path: 'detail/:patient_id',
            name: 'record-disabilities-detail-list',
            beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [
                    {   
                        route: {
                            name: 'record-disabilities-index',
                            params: {}
                        },
                        transKey: 'breadcrumbs.record-disabilities-index',
                        isActive: false,
                        isRedirect: false
                    },
                    {                           
                        transKey: 'breadcrumbs.record-disabilities-detail',
                        isActive: true
                    }
                ]
            },
            component: () => import('@/modules/digital-record/medical-reports/record-disabilities/views/Detail.vue')
        },
        {
            path: '',
            name: 'record-disabilities-index',
            beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [
                    {                        
                        transKey: 'breadcrumbs.record-disabilities-index',
                        isActive: true,                    
                    }
                ]
            },
            component: () => import(/* webpackChunkName: "record-disabilities-index" */ '@/modules/digital-record/medical-reports/record-disabilities/views/Index.vue')
        }
    ]
}