import {errorHttpMessage} from "@/utils/errorDecodeUtil"
import {downloadFile} from "@/utils/filesUtil"
import operationClient from "@/axiosClients/operationClient";
import configurationClient from "@/axiosClients/configurationClient";

export const getFilters = async ({commit}) => {
    try {
        const {data: result} = await operationClient.get(`/get-filters/assignation-patient`)
        const {data, message, error} = result.result
        return {data, message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const save = async ({commit}, topFiltersData) => {
    try {
        const {data: result} = await configurationClient.post('/assignation-patient', topFiltersData)
        const {message, error} = result.result
        return {message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const findById = async ({commit}, assignationPatientsId) => {
    try {
        const {data: result} = await configurationClient.get(`/assignation-patient/show/${assignationPatientsId}`)
        const {data, message, error} = result.result
        return {data, message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const edit = async ({commit}, {topFiltersData, assignationPatientId}) => {

    try {
        const {data: result} = await configurationClient.post(`/assignation-patient/${assignationPatientId}`, topFiltersData)
        const {message, error} = result.result
        return {message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }

}

export const deleteById = async ({commit}, assignationPatientId) => {
    try {
        const {data: result} = await configurationClient.delete(`/assignation-patient/${assignationPatientId}`)
        const {message, error} = result.result
        return {message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}
