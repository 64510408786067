import * as actions from './actions'
import state from './state'
import getters from './getter'


const vaccinationsSchemeModule = {
    namespaced: true,
    actions,
    state,
    getters
}


export default vaccinationsSchemeModule
