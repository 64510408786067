
import {errorHttpMessage} from "@/utils/errorDecodeUtil"
import configurationClient from '@/axiosClients/configurationClient'
import { downloadFile } from "@/utils/filesUtil"

export const saveClient = async ({commit}, client) => {
    try{
        const { data:result } = await configurationClient.post('/clients/create', client)        
        const { message, error } = result.result    
        return {message, error}        
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    } 
}

export const findClientById = async ({commit}, clientId) => {
    try{        
        const { data:result } = await configurationClient.get(`/clients/show/${clientId}`)        
        const { data, message, error } = result.result    
        return {data, message, error}        
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const editClient = async ({commit}, formData) => {
    try{
        const { data:result } = await configurationClient.post(`/clients/${formData.get('tenant_id')}`, formData)        
        const { message, error } = result.result    
        return { message, error}        
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const deleteClient = async ({commit}, clientId) => {
    try{        
        const { data:result } = await configurationClient.delete(`/clients/destroy/${clientId}`)        
        const { message, error } = result.result    
        return {message, error}        
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    } 
}

export const getProducts = async ({commit}) => {
    try{        
        const { data:result } = await configurationClient.get(`/products/getProducts`)
        const { message, error, data } = result.result    
        const options = data.map(el => ({id: el.id, text: el.name}))
        return {message, error, options}        
    } catch(ex) {                     
        console.log(ex)
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    } 
}

export const getClientsList = async ({commit}) => {
    try{        
        const { data:result } = await configurationClient.get(`/clients/list`)        
        const { data, message, error } = result.result    
        return {data, message, error}        
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const downloadExcelReport = async ({commit}, fileName) => {
    try{        
        const response = await configurationClient.get(`/clients/download`,{
            responseType: 'blob'
        })                
        downloadFile(response.data, fileName)
    } catch(ex) {                     
        console.log('error', ex)
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    } 
}


export const deleteFile = async ({commit}, fileId) => {

    try{
        const { data:result } = await configurationClient.delete(`/clients/file/${fileId}`)
        const { data, message, error } = result.result
        return {data, message, error}
    } catch(ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, error: true}
    }
}

export const deleteLogo = async ({commit}, client_id) => {
    try {
        const {data: result} = await configurationClient.delete('/clients/delete-logo/' + client_id)
        const {data, message, error} = result.result
        return {data, message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, data: ex, error: true}
    }
}

export const checkHost = async ({commit}, hostName) => {
    try{
        const { data:result } = await configurationClient.get(`/clients/check-host/${hostName}`)
        const { data, message, error } = result.result
        return {data, message, error}        
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    } 
}
