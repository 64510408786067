import operationClient from "@/axiosClients/operationClient";
import ephiClient from "@/axiosClients/ephiClient";
import configurationClient from "@/axiosClients/configurationClient";
import {errorHttpMessage} from "@/utils/errorDecodeUtil";


export const getNotesEvolutionsById = async ({commit}, {id}) => {
    try {
        const {data: result} = await ephiClient.get(
            `/patients/notes-evolution/show/${id}`
        );
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};


export const getNotesEvolutions = async ({commit}, {id}) => {
    try {
        const {data: result} = await ephiClient.get(
            `/patients/notes-evolution/${id}`
        );
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};


export const getShortMedicalHistories = async ({commit}, {id}) => {
    try {
        const {data: result} = await operationClient.get(
            `/patients/short-medical-histories/${id}`
        );
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};

export const getAadmissionMedicalExams = async ({commit}, {id}) => {
    try {
        const {data: result} = await operationClient.get(
            `/patients/admission-medical-exam/get-all/${id}`
        );
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};

export const deleteShortMedicalHistories = async ({commit}, {id}) => {
    try {
        const {data: result} = await ephiClient.delete(
            `/patients/short-medical-histories/${id}`
        );
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};
export const deleteAdmissionMedicalExams = async ({commit}, {id}) => {
    try {
        const {data: result} = await ephiClient.delete(
            `/patients/admission-medical-exam/${id}`
        );
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};


export const getMedicalReports = async ({commit}, {id}) => {
    try {
        const {data: result} = await ephiClient.get(
            `/patients/medical-reports/${id}`
        );
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};

export const getMedicalConditions = async ({commit}, {id}) => {
    try {
        const {data: result} = await ephiClient.get(
            `/patients/medical-conditions/${id}`
        );
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};

export const postMedicalConditions = async (
    {commit},
    {patient_id, note, year, medical_condition_id}
) => {
    try {
        const body = {patient_id, note, year, medical_condition_id};
        const {data: result} = await ephiClient.post(
            `/patients/medical-conditions`,
            body
        );
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};

export const putMedicalConditions = async (
    {commit},
    {patient_id, medical_condition_id, note, year, id}
) => {
    try {
        const body = {medical_condition_id, note, year, patient_id};
        const {data: result} = await ephiClient.put(
            `/patients/medical-conditions/${id}`,
            body
        );
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};

export const getActiveMedications = async ({commit}, {id}) => {
    try {
        const {data: result} = await ephiClient.get(
            `/patients/active-medications/${id}`
        );
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};

export const getPeriodicExams = async ({commit}, {id}) => {
    try {
        const {data: result} = await ephiClient.get(`/patients/${id}/periodic-exams`)
        const {data, message, error} = result.result
        return {data, message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const putActiveMedications = async (
    {commit},
    {id, patient_id, medicine_id}
) => {
    try {
        const {data: result} = await ephiClient.put(
            `/patients/active-medications/${id}`,
            {patient_id, medicine_id}
        );
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};

export const postActiveMedications = async (
    {commit},
    {patient_id, medicine_id}
) => {
    try {
        const {data: result} = await ephiClient.post(
            `/patients/active-medications`,
            {patient_id, medicine_id}
        );
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};

export const getMedicalHistories = async ({commit}, {id}) => {
    try {
        const {data: result} = await ephiClient.get(
            `/patients/medical-histories/${id}`
        );
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};

export const postMedicalHistories = async (
    {commit},
    {patient_id, title, note, year}
) => {
    try {
        const {data: result} = await ephiClient.post(
            `/patients/medical-histories`,
            {patient_id, title, note, year}
        );
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};

export const putMedicalHistories = async (
    {commit},
    {id, patient_id, title, note, year}
) => {
    try {
        const {data: result} = await ephiClient.put(
            `/patients/medical-histories/${id}`,
            {patient_id, title, note, year}
        );
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};

export const getSocialHabits = async ({commit}, {id}) => {
    try {
        const {data: result} = await ephiClient.get(
            `/patients/social-habits/${id}`
        );
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};
export const putSocialHabits = async (
    {commit},
    {id, patient_id, habit}
) => {
    try {
        const {data: result} = await ephiClient.put(
            `/patients/social-habits/${id}`,
            {patient_id, habit}
        );
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};
export const postSocialHabits = async (
    {commit},
    {id, patient_id, habit}
) => {
    try {
        const {data: result} = await ephiClient.post(
            `/patients/social-habits`,
            {patient_id, habit}
        );
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};

export const getAllErgiesTolerances = async ({commit}, {id}) => {
    try {
        const {data: result} = await ephiClient.get(
            `/patients/allergies-tolerances/${id}`
        );
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};

export const putAllErgiesTolerances = async (
    {commit},
    {id, patient_id, description}
) => {
    try {
        const {data: result} = await ephiClient.put(
            `/patients/allergies-tolerances/${id}`,
            {patient_id, description}
        );
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};

export const postAllErgiesTolerances = async (
    {commit},
    {id, patient_id, description}
) => {
    try {
        const {data: result} = await ephiClient.post(
            `/patients/allergies-tolerances`,
            {patient_id, description}
        );
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};

export const getAlertsAndSpecialNeeds = async ({commit}, {id}) => {
    try {
        const {data: result} = await ephiClient.get(
            `/patients/alerts-and-special-needs/${id}`
        );
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};
export const putAlertsAndSpecialNeeds = async (
    {commit},
    {id, patient_id, description}
) => {
    try {
        const {data: result} = await ephiClient.put(
            `/patients/alerts-and-special-needs/${id}`,
            {patient_id, description}
        );
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};
export const postAlertsAndSpecialNeeds = async (
    {commit},
    {id, patient_id, description}
) => {
    try {
        const {data: result} = await ephiClient.post(
            `/patients/alerts-and-special-needs`,
            {patient_id, description}
        );
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};

export const getSurgicalHistories = async ({commit}, {id}) => {
    try {
        const {data: result} = await ephiClient.get(
            `/patients/surgical-histories/${id}`
        );
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};

export const postSurgicalHistories = async (
    {commit},
    {patient_id, title, note, year}
) => {
    try {
        const {data: result} = await ephiClient.post(
            `/patients/surgical-histories`,
            {patient_id, title, note, year}
        );
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};

export const putSurgicalHistories = async (
    {commit},
    {id, patient_id, title, note, year}
) => {
    try {
        const {data: result} = await ephiClient.put(
            `/patients/surgical-histories/${id}`,
            {patient_id, title, note, year}
        );
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};

export const getPsychiatricHistories = async ({commit}, {id}) => {
    try {
        const {data: result} = await ephiClient.get(
            `/patients/psychiatric-histories/${id}`
        );
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};
export const putPsychiatricHistories = async (
    {commit},
    {id, patient_id, title, note, year}
) => {
    try {
        const {data: result} = await ephiClient.put(
            `/patients/psychiatric-histories/${id}`,
            {patient_id, title, note, year}
        );
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};
export const postPsychiatricHistories = async (
    {commit},
    {patient_id, title, note, year}
) => {
    try {
        const {data: result} = await ephiClient.post(
            `/patients/psychiatric-histories`,
            {patient_id, title, note, year}
        );
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};

export const getStatusCovidHistories = async ({commit}, {id}) => {
    try {
        const {data: result} = await ephiClient.get(
            `/patients/status-covid-histories/${id}/current`
        );
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};

export const getStatusCovidHistory = async ({commit}, {id}) => {
    try {
        const {data: result} = await ephiClient.get(
            `/patients/status-covid-histories/${id}`
        );
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};

export const postPatientStatusCovid = async (
    {commit},
    {patient_id, covid_status_id}
) => {
    try {
        const body = {patient_id, covid_status_id};
        const {data: result} = await ephiClient.post(
            `/patients/covid-status`,
            body
        );
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};


export const putPatientStatusCovid = async (
    {commit},
    {patient_id, covid_status_id}
) => {
    try {
        const body = {patient_id, covid_status_id};
        const {data: result} = await ephiClient.put(
            `/patients/covid-status/update`,
            body
        );
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};

export const getVaccinationRecords = async ({commit}, {id}) => {
    try {
        const {data: result} = await operationClient.get(
            `/patients/vaccination-records/${id}`
        );
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};

export const getNutritions = async ({commit}, {id}) => {
    try {
        const {data: result} = await ephiClient.get(
            `/patients/nutritions/${id}`
        );
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};

export const getNutritionById = async ({commit}, {id}) => {
    try {
        const {data: result} = await ephiClient.get(
            `/patients/nutritions/show/${id}`
        );
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};

export const getHabits = async ({commit}, {id}) => {
    try {
        const {data: result} = await ephiClient.get(
            `/patients/habits/get/${id}`
        );
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};

export const downloadClinicalHistory = async ({commit}, patient_id) => {

    try {
        const {data: result} = await ephiClient.get(`/patients/clinical-history/${patient_id}/download`, {responseType: "blob"})

        const blob = new Blob([result], {type: "application/pdf"});
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.target = "_blank"
        //link.download = dataDownload.file_name
        link.click();
        document.body.removeChild(link);

        return true
    } catch (ex) {
        //console.log(ex)
        return
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}
export const deleteMedicalConditions = async ({commit}, {id}) => {
    try {
        const {data: result} = await ephiClient.delete(
            `/patients/medical-conditions/${id}`
        );
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};
export const deleteActiveMedications = async ({commit}, {id}) => {
    try {
        const {data: result} = await ephiClient.delete(
            `/patients/active-medications/${id}`
        );
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};
export const deleteMedicalHistories = async ({commit}, {id}) => {
    try {
        const {data: result} = await ephiClient.delete(
            `/patients/medical-histories/${id}`
        );
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};

export const deleteSocialHabits = async ({commit}, {id}) => {
    try {
        const {data: result} = await ephiClient.delete(
            `/patients/social-habits/${id}`
        );
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};
export const deleteAllErgiesTolerances = async ({commit}, {id}) => {
    try {
        const {data: result} = await ephiClient.delete(
            `/patients/allergies-tolerances/${id}`
        );
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};

export const deleteAlertsAndSpecialNeeds = async ({commit}, {id}) => {
    try {
        const {data: result} = await ephiClient.delete(
            `/patients/alerts-and-special-needs/${id}`
        );
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};
export const deleteSurgicalHistories = async ({commit}, {id}) => {
    try {
        const {data: result} = await ephiClient.delete(
            `/patients/surgical-histories/${id}`
        );
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};
export const deletePsychiatricHistories = async ({commit}, {id}) => {
    try {
        const {data: result} = await ephiClient.delete(
            `/patients/psychiatric-histories/${id}`
        );
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};

export const deletePeriodicExams = async ({commit}, dataDelete) => {
    try {
        const {data: result} = await ephiClient.delete(
            `/patients/${dataDelete.patient_id}/periodic-exams/${dataDelete.id}`
        )
        const {data, message, error} = result.result
        return {data, message, error}
    } catch (ex) {
        //console.log(ex)
        return
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const deleteMedicalReports = async ({commit}, params) => {
    try {
        const {data: result} = await ephiClient.delete(
            `/patients/medical-reports/${params.id}/${params.type}`
        )
        //console.log(result)
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};

export const downloadMedicReport = async ({commit}, dataDownload) => {

    try {

        if (dataDownload.type == "certificate_health_status") {
            var {data: result} = await ephiClient.get(`/patients/${dataDownload.patient_id}/certificate-health-status/${dataDownload.id}/download`, {responseType: "blob"})
        } else if (dataDownload.type == "patient_incident_report") {
            var {data: result} = await ephiClient.get(`/patients/incident-reports/download/${dataDownload.id}`, {responseType: "blob"})
        } else if (dataDownload.type == "incapacity") {
            var {data: result} = await ephiClient.get(`/patients/incapacities/${dataDownload.id}/download`, {responseType: "blob"})
        }

        const blob = new Blob([result], {type: "application/pdf"});
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.target = "_blank"
        //link.download = dataDownload.file_name
        link.click();
        document.body.removeChild(link);

        return {data, message, error} = {"data": "", "error": false, "message": ""}

        return true
    } catch (ex) {
        //console.log(ex)
        return
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const downloadPeriodicExam = async ({commit}, dataDownload) => {
    try {
        // const {data: result} = await ephiClient.get(`/patients/${dataDownload.patient_id}/periodic-exams/${dataDownload.id}/download`)
        // let data = result.result.data.map(({clinical_exam_files}) => clinical_exam_files)
        // data = data.length === 1? data = data[0] : data.reduce((pre, next)=> pre.concat(next))
        // let firstPdfUrl = data[0]
        // let extrapdfsUrls = data.filter((item, index)=> index > 0 )
        const {data: result} = await ephiClient.get(`/patients/${dataDownload.patient_id}/periodic-exams/${dataDownload.id}/download`, {responseType: "blob"})
        const blob = new Blob([result], {type: "application/pdf"});
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.target = "_blank"
        //link.download = dataDownload.file_name
        link.click();
        document.body.removeChild(link);

        return true
    } catch (ex) {
        //console.log(ex)
        return
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}


export const downloadShortMedicalHistories = async ({commit}, dataDownload) => {
    try {
        const {data: result} = await ephiClient.get(`/patients/short-medical-histories/${dataDownload.id}/download`, {responseType: "blob"})

        const blob = new Blob([result], {type: "application/pdf"});
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.target = "_blank"
        //link.download = dataDownload.file_name
        link.click();
        document.body.removeChild(link);

        return true
    } catch (ex) {
        //console.log(ex)
        return
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const downloadAdmissionMedicalExams = async ({commit}, dataDownload) => {

    try {
        const {data: result} = await ephiClient.get(`/patients/admission-medical-exam/pdf/${dataDownload.id}`, {responseType: "blob"})

        const blob = new Blob([result], {type: "application/pdf"});
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.target = "_blank"
        link.click();
        document.body.removeChild(link);

        return true
    } catch (ex) {
        //console.log(ex)
        return
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const downloadPdfConsultation = async ({commit}, dataDownload) => {

    try {
        const {data: result} = await ephiClient.get(`/patients/encounter/download/medical-consultation/${dataDownload.id}`, {responseType: "blob"})

        const blob = new Blob([result], {type: "application/pdf"});
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.target = "_blank"
        link.click();
        URL.revokeObjectURL(link.href);

        return {data: null, error: null, message: null}
    } catch (ex) {
        //console.log(ex)
        return {data: null, message: null, error: ex}
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const downloadPdfPrescription = async ({commit}, dataDownload) => {

    try {
        const {data: result} = await ephiClient.get(`/patients/encounter/download/${dataDownload.id}`, {responseType: "blob"})

        const blob = new Blob([result], {type: "application/pdf"});
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.target = "_blank"
        link.click();
        document.body.removeChild(link);
        // URL.revokeObjectURL(link.href);

        return {data: null, error: null, message: null}
    } catch (ex) {
        //console.log(ex)
        return {data: null, message: null, error: ex}
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}


export const getAdmissionMedicalExams = async ({commit}, {id}) => {
    try {
        const {data: result} = await ephiClient.get(`/patients/admission-medical-exam/get-all/${id}`)
        const {data, message, error} = result.result
        return {data, message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}


export const getDigitalRecord = async ({commit}, {id}) => {
    try {
        const {data: result} = await ephiClient.get(`/patients/digital-record/${id}`)
        const {data, message, error} = result.result
        return {data, message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getMedicinesList = async ({commit}) => {
    try {
        const {data: result} = await configurationClient.get(`/catalog/medicines/list`);
        const {data, message, error} = result.result;
        return {data, message, error};
    } catch (ex) {
        if (ex.error && ex.message) {
            const error_http_message = errorHttpMessage(ex);
            return {
                message: error_http_message.message,
                error_keys: error_http_message.error_keys,
                data: ex.response.data,
                error: true
            }
        } else {
            return {
                message: '',
                data: [],
                error: true
            }
        }
    }
};

export const getMedicines = async ({commit}) => {
    try {
        const {data: result} = await configurationClient.get(
            `/catalog/medicines/show`
        );
        const {data, message, error} = result;
        return {data, message, error};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
};

export const getMedicinesCatalog = async ({commit}) => {
    try {
        const {data: result} = await configurationClient.get(`/catalog/medicines/catalog/list`)
        const {data} = result.result;
        return {data};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
}

export const getEncounterMedicinesCatalog = async ({commit}) => {
    try {
        const {data: result} = await configurationClient.get(`/catalog/medicines/catalog/list/encounter`)
        const {data} = result.result;
        return {data};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
}

export const getMedicinePresentations = async ({commit}, medicineId) => {
    try {
        const {data: result} = await configurationClient.get(`/catalog/medicines/presentation/list/${medicineId}`)
        const {data} = result.result;
        return {data};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
}

export const getDoseOfMedicinePresentations = async ({commit}, medicine) => {
    try {
        const {data: result} = await configurationClient.get(`/catalog/medicines/catalog/doses/list/${medicine.medicineId}/${medicine.presentationId}`)
        const {data} = result.result;
        return {data};
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true,
        };
    }
}
