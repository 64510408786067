import store from '@/store'
import router from "@/router";

const hasAccessGuard = async (to, from, next) => {

    const token = store.getters["auth/getToken"]
    const refreshToken = store.getters["auth/getRefreshToken"]
    const routeRedirect = store.getters['auth/getRouteRedirect']

    if (token && refreshToken) {
        // next({name: 'dashboard-index'})
        next({name: routeRedirect})
    }

    next()

}

export default hasAccessGuard