
import {errorHttpMessage} from "@/utils/errorDecodeUtil"
import configurationClient from '@/axiosClients/configurationClient'
import vaccinationClient from "@/axiosClients/vaccinationClient";

export const save = async ({commit}, vaccinationscheme) => {
    try{
        const { data:result } = await vaccinationClient.post('/vaccination-scheme', vaccinationscheme)
        const { message, error } = result.result    
        return {message, error}        
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    } 
}

export const findById = async ({commit}, vaccinationRecordId) => {
    try{        
        const { data:result } = await vaccinationClient.get(`/vaccination-scheme/show/${vaccinationRecordId}`)        
        const { data, message, error } = result.result    
        return {data, message, error}        
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const edit = async ({commit}, vaccinationScheme) => {
    
    try{
        const { data:result } = await vaccinationClient.post(`/vaccination-scheme/${vaccinationScheme.id}`, vaccinationScheme)        
        const { message, error } = result.result    
        return { message, error}        
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const deleteById = async ({commit}, vaccinationSchemeId) => {
    try{        
        const { data:result } = await vaccinationClient.delete(`/vaccination-scheme/${vaccinationSchemeId}`)        
        const { message, error } = result.result    
        return {message, error}        
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    } 
}

export const getVaccinations = async ({commit}) => {
    try{        
        const { data:result } = await configurationClient.get(`/catalog/vaccination-records`)        
        const { data, message, error } = result.result    
        return {data, message, error}        
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}