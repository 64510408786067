import {ref, computed, reactive} from 'vue'
import {useStore} from 'vuex'
import {useRouter} from 'vue-router'
import swalUtil from '@/utils/sweetalertUtil'
import {removeRememberUser, setRememberUser} from "@/modules/auth/store/getters";
import {setErrorKey} from "@/utils/errorDecodeUtil";
import {isSecurityQuestionsAvailable} from "@/modules/auth/store/mutations";
import {set, event} from "vue-gtag";
import {createApp} from 'vue'
import {App} from "vue";
import VueGtag from "vue-gtag";

const useAuth = () => {

    //Composables
    const router = useRouter()
    const store = useStore()

    //Data
    const user = ref({})
    const email = ref(null)
    const loginError = ref(false)
    const password1 = ref(null)
    const password2 = ref(null)
    const verificationCode = ref(null)
    const questions = ref(null)
    const isLoading = ref(false)
    const errorKeys = ref({})
    const loginErrorMessage = ref(null)
    const askBusinessName = ref(false)
    const usernameValid = ref(false)
    const isShowPassword = ref(false);
    const securityQuestionsAvailable = ref(false)
    const isSSOConfigured = ref(false);
    const ssoUrl = ref("");
    const businessName = ref("");

    //Methods
    const logout = async () => {

        const {message, error} = await store.dispatch('auth/logout')

        if (!error) {

            router.push({name: 'auth-login'}).then(() => {
                router.go()
            })

        } else {
            //console.log('error el iniciar sesion')
        }

    }

    const validateUsername = async () => {

        if (user.value.rememberUser) {
            user.value.username = user.value.username.toLowerCase()
            setRememberUser(user.value.username)
        } else {
            removeRememberUser()
        }

        loginErrorMessage.value = "";
        setLoading(true);

        const {message, error, ask_business_name} = await store.dispatch('auth/validateUser', user.value)

        setLoading(false);

        if (!error) {

            //Username is valid
            usernameValid.value = true;

            //Set if we need to ask from business name because there are more than one user with the same numero de nomina
            askBusinessName.value = ask_business_name;

        } else {
            askBusinessName.value = false;
            usernameValid.value = false;
            loginErrorMessage.value = message;
            loginError.value = true
        }
    }

    const validateUsernameRecoverPassword = async () => {

        if (user.value.rememberUser) {
            user.value.username = user.value.username.toLowerCase()
            setRememberUser(user.value.username)
        } else {
            removeRememberUser()
        }

        loginErrorMessage.value = "";
        setLoading(true);

        const {message, error, ask_business_name} = await store.dispatch('auth/validateUser', user.value)

        setLoading(false);

        if (!error) {

            //Username is valid
            usernameValid.value = true;

            //Set if we need to ask from business name because there are more than one user with the same numero de nomina
            askBusinessName.value = ask_business_name;

            //If we dont need to ask for business name, do the recovery password
            if (!ask_business_name) {
                requestEmailRecoveryPassword();
            }

        } else {
            askBusinessName.value = false;
            usernameValid.value = false;
            loginErrorMessage.value = message;
            loginError.value = true
        }

    }

    const login = async () => {

        if (user.value.rememberUser) {
            setRememberUser(user.value.username)
        } else {
            removeRememberUser()
        }

        loginErrorMessage.value = "";
        setLoading(true);

        user.value.is_need_business = askBusinessName

        user.value.username = user.value.username.toLowerCase()

        const {message, error, ask_business_name} = await store.dispatch('auth/login', user.value)

        setLoading(false);

        if (!error) {

            //Check if wee need to ask from business name because there are more than one user with the same numero de nomina
            if (ask_business_name == true) {
                askBusinessName.value = ask_business_name;
            } else {

                const isLandlord = computed(() => store.getters['auth/getIsLandlord'])
                const userLoggedIn = store.getters['auth/getLoggedUser']
                const userUuid = userLoggedIn.uuid;
                const userHost = store.getters['auth/getTenantHost'];
                const routeRedirect = store.getters['auth/getRouteRedirect']

                //Set google analytics depends environment
                if (process.env.VUE_APP_LANDLORD_URL == "administrador.sherpa.la") {
                    //Production mode
                    createApp(App).use(VueGtag, {
                        config: {id: "G-XV5B0BRQ3F", params: {"user_id": userUuid}}
                    }, router)
                } else {
                    //Development and localhost mode
                    createApp(App).use(VueGtag, {
                        config: {id: "G-CL9EVQF6MF", params: {"user_id": userUuid}}
                    }, router)
                }
                
                // if (isLandlord.value) {
                //     router.push({name: 'clients-index'})
                // } else {
                //     router.push({name: 'dashboard-index'})
                // }

                router.push({name: routeRedirect})

            }

        } else {
            loginErrorMessage.value = message;
            loginError.value = true
        }
    }

    const setLoginFromSSOResponse = async (ssoResponse) => {

        ssoResponse = JSON.parse(atob(ssoResponse))

        if (!ssoResponse.result.error) {

            loginErrorMessage.value = "";
            store.commit('setGeneralIsLoading', true)

            setLoading(true);

            const acessTokenResponse = ssoResponse.result.data;
            const {message, error, ask_business_name} = await store.dispatch('auth/loginFromSSOResponse', acessTokenResponse)

            setLoading(false);

            if (!error) {

                store.commit('setGeneralIsLoading', false)

                const isLandlord = computed(() => store.getters['auth/getIsLandlord'])
                const userLoggedIn = store.getters['auth/getLoggedUser']
                const userUuid = userLoggedIn.uuid;
                const userHost = store.getters['auth/getTenantHost'];
                const routeRedirect = store.getters['auth/getRouteRedirect']

                //Set google analytics depends environment
                if (process.env.VUE_APP_LANDLORD_URL == "administrador.sherpa.la") {
                    //Production mode
                    createApp(App).use(VueGtag, {
                        config: {id: "G-XV5B0BRQ3F", params: {"user_id": userUuid}}
                    }, router)
                } else {
                    //Development and localhost mode
                    createApp(App).use(VueGtag, {
                        config: {id: "G-CL9EVQF6MF", params: {"user_id": userUuid}}
                    }, router)
                }

                // if (isLandlord.value) {
                //     router.push({name: 'calendar-index'})
                // } else {
                //     router.push({name: 'dashboard-index'})
                // }

                router.push({name: routeRedirect})


            } else {

                loginErrorMessage.value = message;
                loginError.value = true
            }

        } else {

            loginErrorMessage.value = ssoResponse.result.message;
            loginError.value = true

        }

        store.commit('setGeneralIsLoading', false)

    }

    const requestEmailRecoveryPassword = async (is_need_business = false) => {

        user.value.is_need_business = is_need_business;

        if (isLoading.value == false) {

            loginErrorMessage.value = "";

            setLoading(true);

            const {message, error, type, password_reset_token, security_questions_available} = await store.dispatch('auth/sendEmailRecoveryPassword', user.value)

            setLoading(false);

            if (!error) {

                //Save if the user can recover password with security questions
                store.commit('auth/setTypeOfRequest', {"type": type})
                store.commit('auth/setPasswordResetToken', {"token": password_reset_token})
                store.commit('auth/isSecurityQuestionsAvailable', {"isSecurityQuestionsAvailable": security_questions_available})

                if (type == 'code') {
                    router.push({name: 'auth-password-confirmation'})
                } else {
                    router.push({name: 'auth-questions'})
                }

            } else {
                loginErrorMessage.value = message;
                loginError.value = true
            }

        }

    }

    const updatePasswordVerificationCode = async () => {

        if (isLoading.value == false) {

            setLoading(true);

            const data = {
                password1,
                password2,
                verificationCode
            }

            const {message, error} = await store.dispatch('auth/updatePasswordVerificationCode', data)

            setLoading(false);

            if (!error) {

                store.commit("auth/clearPasswordResetToken")

                swalUtil.fire({
                    icon: 'success',
                    html: 'Contraseña actualizada correctamente',
                    confirmButtonText: 'Aceptar',
                }).then((result) => {
                    //console.log('result swalert', result)
                    if (result.isConfirmed) {
                        router.push({name: 'auth-login'})
                    }
                })

            } else {

                swalUtil.fire({
                    icon: 'error',
                    title: "Error",
                    html: message
                })

            }

        }

    }

    const goToResendCodeForgotPassword = () => {
        store.commit('auth/requestEmailRecoveryPassword', null)
        router.push({name: 'auth-password-recovery'})
    }

    const validateUserSecretQuestions = async (userId) => {

        errorKeys.value = {};

        const {message, error, error_keys} = await store.dispatch('auth/validateUserSecretQuestions', questions["_rawValue"])

        if (!error) {

            //Answers provider are correct, redirect user to reset password
            store.commit('auth/setIsSecurityQuestionsAvailable', {"isAvailable": false})
            store.commit('auth/setTypeOfRequest', {"type": "questions"})
            store.commit('auth/setUserId', {"userId": userId})

            router.push({name: 'auth-password-confirmation'})

        } else {

            errorKeys.value = setErrorKey(error_keys);

            swalUtil.fire({
                icon: 'error',
                title: 'Error',
                html: message
            })
        }

    }

    const resetErrors = () => {
        loginError.value = false
    }

    const setLoading = (set_loading) => {
        isLoading.value = set_loading;
    }

    const getSecretQuestionsByUser = async () => {

        setLoading(true);

        const passwordResetToken = store.getters["auth/getPasswordResetToken"]
        const {message, error, data} = await store.dispatch('auth/getQuestionsByUser', passwordResetToken)

        setLoading(false);

        if (!error) {

            questions.value = data;

        } else {
            swalUtil.fire({
                icon: 'error',
                title: 'Error',
                html: message
            })
        }

    }

    const updatePasswordWithoutVerificationCode = async () => {

        if (isLoading.value == false) {

            setLoading(true);

            const data = {
                password1,
                password2
            }

            const {message, error} = await store.dispatch('auth/updatePasswordWithoutVerificationCode', data)

            setLoading(false);

            if (!error) {

                store.commit("auth/clearPasswordResetToken")


                swalUtil.fire({
                    icon: 'success',
                    html: 'Contraseña actualizada correctamente',
                    confirmButtonText: 'Aceptar',
                }).then((result) => {
                    //console.log('result swalert', result)
                    if (result.isConfirmed) {
                        router.push({name: 'auth-login'})
                    }
                })

            } else {

                swalUtil.fire({
                    icon: 'error',
                    title: "Error",
                    html: message
                })

            }

        }

    }

    const cancelSignin = async () => {

        askBusinessName.value = false
        usernameValid.value = false
        loginErrorMessage.value = ""
        loginError.value = false

    }

    const showPassword = () => {
        isShowPassword.value = !isShowPassword.value;
    }

    //Computeds
    const loggedUser = computed(() => store.getters['auth/getLoggedUser'])

    const typeOfRequest = computed(() => store.getters['auth/getTypeOfRequest'])

    const isSecurityQuestionsAvailable = computed(() => {
        const securityQuestionAvailable = store.getters['auth/getSecurityQuestionsAvailable']
        return (securityQuestionAvailable == "true") ? true : false;
    })

    const samePasswordClass = computed(() => {
        if (password1.value == password2.value) {
            return false
        }
        if (password1.value != null && password2.value == null) {
            return false
        }
        if (password1.value == null && password2.value != null) {
            return false
        }
        return true
    })

    const passwordInputType = computed(() => {
        return (isShowPassword.value) ? "text" : "password"
    })

    const hasSSOConfigured = async () => {

        const {message, error, data} = await store.dispatch('auth/hasSSOConfigured')

        setLoading(false);

        if (!error) {

            isSSOConfigured.value = data.has_sso_configured
            ssoUrl.value = data.sso_url
            businessName.value = data.business_name

        } else {


        }

    }


    return {
        user,
        loginError,
        loggedUser,
        email,
        password1,
        password2,
        verificationCode,
        samePasswordClass,
        typeOfRequest,
        questions,
        isLoading,
        isSecurityQuestionsAvailable,
        errorKeys,
        loginErrorMessage,
        askBusinessName,
        usernameValid,
        passwordInputType,
        isSSOConfigured,
        ssoUrl,
        businessName,
        hasSSOConfigured,
        setLoginFromSSOResponse,
        showPassword,
        resetErrors,
        cancelSignin,
        setLoading,
        login,
        validateUsername,
        logout,
        requestEmailRecoveryPassword,
        updatePasswordVerificationCode,
        goToResendCodeForgotPassword,
        validateUserSecretQuestions,
        getSecretQuestionsByUser,
        updatePasswordWithoutVerificationCode,
        validateUsernameRecoverPassword
    }

}

export default useAuth
