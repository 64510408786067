import isVerifyTokenGuard from "@/guards/verifyTokenGuard";

export default {
    name: 'habits',
    component: () => import(/* webpackChunkName: "base-layout" */ '@/layouts/BaseLayout.vue'),
    children: [
        {
            path: '',
            name: 'patient-habits-index',
            beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [                   
                    {                           
                        transKey: 'breadcrumbs.habits-index',
                        isActive: true
                    }
                ]
            },
            component: () => import(/* webpackChunkName: "index-habits" */ '@/modules/patient-profile/habits/views/Create.vue')
        }
    ]
}