import isVerifyTokenGuard from "@/guards/verifyTokenGuard";

export default {
    name: 'data-mining',
    component: () => import(/* webpackChunkName: "base-layout" */ '@/layouts/BaseLayout.vue'),
    children: [
        {
            path: '',
            name: 'data-mining-index',
            beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [
                    {                           
                        transKey: 'breadcrumbs.data-mining-index',
                        isActive: true
                    }
                ]
            },
            component: () => import('@/modules/data-mining/views/Index.vue'),
        },
        {
            path: 'create',
            name: 'data-mining-create',
            beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [
                    {                           
                        transKey: 'breadcrumbs.data-mining-create',
                        isActive: true
                    }
                ]
            },
            component: () => import(/* webpackChunkName: "data-mining-create" */ '@/modules/data-mining/views/Create.vue')
        }    
    ]
}