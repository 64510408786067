import isVerifyTokenGuard from "@/guards/verifyTokenGuard";

export default {
    name: 'editable-inputs',
    component: () => import(/* webpackChunkName: "base-layout" */ '@/layouts/BaseLayout.vue'),
    children: [
        {
            path: '',
            name: 'editable-inputs-index',
            beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [
                    {
                        transKey: 'breadcrumbs.editable-inputs-index',
                        isActive: true
                    }
                ]
            },
            component: () => import(/* webpackChunkName: "subgroups-index" */ '@/modules/configurations/catalogs/editable-inputs/views/Index.vue')
        },
        {
            path: 'create',
            name: 'editable-inputs-create',
            beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [
                    {
                        route: {
                            name: 'editable-inputs-index',
                            params: {}
                        },
                        transKey: 'breadcrumbs.editable-inputs-index',
                        isActive: false
                    },
                    {
                        transKey: 'breadcrumbs.editable-inputs-create',
                        isActive: true
                    }
                ]
            },
            component: () => import(/* webpackChunkName: "subgroups-create" */ '@/modules/configurations/catalogs/editable-inputs/views/Create.vue')
        },
        {
            path: 'edit/:id',
            name: 'editable-inputs-edit',
            beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [
                    {
                        route: {
                            name: 'editable-inputs-index',
                            params: {}
                        },
                        transKey: 'breadcrumbs.editable-inputs-index',
                        isActive: false
                    },
                    {
                        transKey: 'breadcrumbs.editable-inputs-edit',
                        isActive: true
                    }
                ]
            },
            component: () => import(/* webpackChunkName: "subgroups-edit" */ '@/modules/configurations/catalogs/editable-inputs/views/Edit.vue')
        }
    ]
}
