import store from '@/store'
import sweetalertUtil from '@/utils/sweetalertUtil'
import i18n from '@/translations'
import {checkPermission} from '@/utils/permissionsUtil'

const verifyTokenGuardWithoutPermission = async (to, from, next) => {

    //const token = store.getters["auth/getToken"]
    const isExpired = store.getters['auth/isExpired']
    //const loggedUser = store.getters['auth/getLoggedUser']

    if (isExpired) {

        const {message, error} = await store.dispatch('auth/refreshToken')
        if (error) {

            await store.dispatch('auth/clearAuthToken')
            next({name: 'auth-login'})

        }

    } else {

        //Needs to change password
        const passwordExpired = store.getters['auth/isPasswordExpired']

        if (passwordExpired) {
            next({name: 'change-password-expired-edit'})
        } else {
            next()
        }

    }

    next()

}

export default verifyTokenGuardWithoutPermission
