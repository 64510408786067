// export const myGetter = ( state ) => {
//  return state
// }

export const getLoggedUser = (state) => {
    return state.user
}

export const getToken = (state) => {
    return sessionStorage.getItem('token') || null
}

export const getRefreshToken = (state) => {
    return sessionStorage.getItem('refreshToken') || null
}

export const isPasswordExpired = (state) => {

    try {

        const token = getToken();
        const jwt = parseJwt(token);
        const is_expired = jwt.is_password_expired || false

        return is_expired

    } catch (ex) {
        return false
    }
}

export const getExpires = (state) => {

    try {

        const token = getToken();
        const jwt = parseJwt(token);

        return jwt.exp || 0
    } catch (ex) {
        return 0
    }
}

export const getRememberUser = (state) => {
    return sessionStorage.getItem('rememberUser') || ""
}

export const setRememberUser = (rememberUser) => {
    return sessionStorage.setItem('rememberUser', rememberUser)
}

export const removeRememberUser = (rememberUser) => {
    return sessionStorage.removeItem('rememberUser')
}

export const isExpired = (state) => {

    const diff = debugDiffTimeToken(state)

    //The expires limit token is after 60 minutes
    if (diff <= 60) {
        return true
    }
    return false
}

export const debugDiffTimeToken = (state) => {

    const expired = getExpires()
    const d1 = new Date(expired * 1000)
    const d2 = new Date();

    const diff = (d1.getTime() - d2.getTime()) / (1000 * 60)

    return diff
}

export const getEmailRecoveryPassword = (state) => {
    return state.emailRecoveryPassword
}

export const getTypeOfRequest = (state) => {
    return sessionStorage.getItem('typeOfRequest')
}

export const getUserId = (state) => {
    return state.userId
}

export const getIsSecurityQuestionsAvailable = (state) => {
    return sessionStorage.getItem('IsSecurityQuestionsAvailable')
}

export const getPasswordResetToken = (state) => {
    return sessionStorage.getItem('passwordResetToken')
}

export const getPasswordResetTokenDecode = (state) => {
    var passwordResetToken = parseJwt(sessionStorage.getItem('passwordResetToken'))
    return passwordResetToken;
}

export const getSecurityQuestionsAvailable = (state) => {
    return sessionStorage.getItem('isSecurityQuestionsAvailable')
}

export const isExpiredPasswordResetToken = (state) => {
    var passwordResetToken = getPasswordResetTokenDecode()
    var expires_at = passwordResetToken.expires_at

    const d1 = new Date(expires_at * 1000)
    const d2 = new Date();

    const diff = (d1.getTime() - d2.getTime()) / (1000 * 60)

    //console.log(diff)

    return (diff <= 0)
}

export const getTenantHost = (state) => {
    return state.host
}

export const getIsLandlord = (state) => {
    return state.isLandlord
}

function parseJwt(token) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
}

export const getBackupData = (state) => {
    return state.backupData
}

export const getRouteRedirect = (state) => {
    return state.routeRedirect
}

export const getIsNeedsAcceptNoticeOfPrivacy = (state) => {

    try {

        const token = getToken();
        const jwt = parseJwt(token);

        return jwt.show_notice_of_privacy || 0

    } catch (ex) {
        return 0
    }

}

