
import {errorHttpMessage} from "@/utils/errorDecodeUtil"
import configurationClient from '@/axiosClients/configurationClient'
import { downloadFile } from "@/utils/filesUtil"

export const saveCollaboratorType = async ({commit}, collaboratorType) => {
    try{
        const { data:result } = await configurationClient.post('/catalog/collaboratorType/create', collaboratorType)        
        const { message, error } = result.result    
        return {message, error}        
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    } 
}

export const findCollaboratorTypeById = async ({commit}, collaboratorTypeId) => {
    try{        
        const { data:result } = await configurationClient.get(`/catalog/collaboratorType/show/${collaboratorTypeId}`)        
        const { data, message, error } = result.result    
        return {data, message, error}        
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const editCollaboratorType = async ({commit}, collaboratorType) => {
    try{
        const { data:result } = await configurationClient.post(`/catalog/collaboratorType/${collaboratorType.id}`, collaboratorType)        
        const { message, error } = result.result    
        return { message, error}        
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const deleteCollaboratorType = async ({commit}, collaboratorTypeId) => {
    try{        
        const { data:result } = await configurationClient.delete(`/catalog/collaboratorType/destroy/${collaboratorTypeId}`)        
        const { message, error } = result.result    
        return {message, error}        
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    } 
}

export const downloadExcelReport = async ({commit}, fileName) => {
    try{        
        const response = await configurationClient.get(`/catalog/collaboratorType/download`,{
            responseType: 'blob'
        })                
        downloadFile(response.data, fileName)
    } catch(ex) {                     
        console.log('error', ex)
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    } 
}