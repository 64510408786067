
import {errorHttpMessage} from "@/utils/errorDecodeUtil"
import configurationClient from '@/axiosClients/configurationClient'
import { downloadFile } from "@/utils/filesUtil"

export const saveClinicalExam = async ({commit}, clinicalExam) => {
    try{
        const { data:result } = await configurationClient.post('/catalog/clinicalExam/create', clinicalExam)        
        const { message, error } = result.result    
        return {message, error}        
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    } 
}

export const findClinicalExamById = async ({commit}, clinicalExamId) => {
    try{        
        const { data:result } = await configurationClient.get(`/catalog/clinicalExam/show/${clinicalExamId}`)        
        const { data, message, error } = result.result    
        return {data, message, error}        
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const editClinicalExam = async ({commit}, clinicalExam) => {
    try{
        const { data:result } = await configurationClient.post(`/catalog/clinicalExam/${clinicalExam.id}`, clinicalExam)        
        const { message, error } = result.result    
        return { message, error}        
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const deleteClinicalExam = async ({commit}, clinicalExamId) => {
    try{        
        const { data:result } = await configurationClient.delete(`/catalog/clinicalExam/destroy/${clinicalExamId}`)        
        const { message, error } = result.result    
        return {message, error}        
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    } 
}

export const downloadExcelReport = async ({commit}, fileName) => {
    try{        
        const response = await configurationClient.get(`/catalog/clinicalExam/download`,{
            responseType: 'blob'
        })                
        downloadFile(response.data, fileName)
    } catch(ex) {                             
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    } 
}