import verifyTokenGuardWithoutPermission from "@/guards/verifyTokenGuardWithoutPermission";

export default {
    name: 'concentrated-affected-systems',
    component: () => import('@/layouts/BaseLayout.vue'),
    children: [
        {
            path: '',
            name: 'concentrated-affected-systems-index',
            beforeEnter: [verifyTokenGuardWithoutPermission],
            meta: {
                breadcrumbs: [
                    {
                        transKey: 'breadcrumbs.record-affected-systems',
                        isActive: true,
                    }
                ]
            },
            component: () => import('@/modules/digital-record/affected-systems/views/Index.vue')
        }
    ]
}
