import ephiClient from '@/axiosClients/ephiClient'
import {errorHttpMessage} from "@/utils/errorDecodeUtil"

export const saveGlucose = async ({commit}, data) => {
    try {
        const {data: result} = await ephiClient.post('/patients/glucose', data)
        const {message, error} = result.result
        return {message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true
        }
    }
}

export const getGlucoseById = async ({commit}, glucose_id) => {
  try{
    const { data:result } = await ephiClient.get(`/patients/glucose/${glucose_id}`)
    const { data, message, error } = result.result
    return {data, message, error}
  } catch(ex) {
    if (ex.error && ex.message) {
      const error_http_message = errorHttpMessage(ex);
      return {
        message: error_http_message.message,
        error_keys: error_http_message.error_keys,
        data: ex.response.data,
        error: true
      }
    } else {
      return {
        message: '',
        data: [],
        error: true
      }
    }
  }
}

export const updateGlucose = async ({commit}, data) => {
    try {
        const {data: result} = await ephiClient.put(`/patients/glucose/${data.id}`, data.glucose_data)
        const {message, error} = result.result
        return {message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            error: true
        }
    }
}

export const deleteGlucose = async ({commit}, glucose_id) => {
  try{
    const { data:result } = await ephiClient.delete(`/patients/glucose/${glucose_id}`)
    const { message, error } = result.result
    return { message, error}
  } catch(ex) {
    if (ex.error && ex.message) {
      const error_http_message = errorHttpMessage(ex);
      return {
        message: error_http_message.message,
        error_keys: error_http_message.error_keys,
        error: true
      }
    } else {
      return {
        message: '',
        error: true
      }
    }
  }
}

export const getGlucosePDF = async ({commit}, patient_id = null) => {
  const req_url = (patient_id != null) ? `/patients/glucose/pdf/${patient_id}` : `/patients/glucose/pdf`

  try {
    const {data: result} = await ephiClient.get(req_url, {responseType: "blob"})
    const blob = new Blob([result], {type: "application/pdf"});
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.target = "_blank"
    link.click();
    document.body.removeChild(link);

    return true
  } catch (ex) {
    if (ex.error && ex.message) {
      const error_http_message = errorHttpMessage(ex);
      return {
        message: error_http_message.message,
        error_keys: error_http_message.error_keys,
        data: ex.response.data,
        error: true
      }
    } else {
      return {
        message: '',
        data: [],
        error: true
      }
    }
  }
}
