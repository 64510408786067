import configurationClient from '@/axiosClients/configurationClient'
import { downloadFile } from "@/utils/filesUtil"
import {errorHttpMessage} from "@/utils/errorDecodeUtil"

export const saveMedicine = async ({ commit }, medicine) => {
    try {
        const { data:result } = await configurationClient.post('/catalog/medicines/catalog/create', medicine)
        const { message, error } = result.result
        return {message, error}
    } catch(ex) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true
        }
    }
}

export const findMedicineById = async ({commit}, medicineId) => {
    try{        
        const { data:result } = await configurationClient.get(`/catalog/medicines/catalog/${medicineId}`)        
        const { data, message, error } = result.result    
        return {data, message, error}        
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true
        }
    }
}

export const editMedicine = async ({ commit }, medicine) => {
    try{
        const { data:result } = await configurationClient.post('/catalog/medicines/catalog/create', medicine)        
        const { message, error } = result.result    
        return { message, error}        
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true
        }
    }
}

export const deleteMedicine = async ({ commit }, medicineId) => {
    try{        
        const { data:result } = await configurationClient.delete(`/catalog/medicines/catalog/${medicineId}`)
        const { message, error } = result.result
        return {message, error}
    } catch(ex) {       
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true
        }
    }
}

export const deleteDose = async  ({ commit }, doseId) => {
    try{        
        const { data:result } = await configurationClient.delete(`/catalog/medicines/presentation/${doseId}`)        
        const { message, error } = result.result    
        return {message, error}        
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true
        }
    } 
}

export const downloadExcelReport = async ({commit}, fileName) => {
    try{        
        const response = await configurationClient.get(`/catalog/medicines/download`,{
            responseType: 'blob'
        })                
        downloadFile(response.data, fileName)
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true
        }
    } 
}

export const downloadExcelMedicinesCatalog = async ({commit}, fileName) => {
    try {
        const response = await configurationClient.get(`/catalog/medicines/catalog/excel`,{
            responseType: 'blob'
        })                
        downloadFile(response.data, fileName)
    } catch (error) {
        const error_http_message = errorHttpMessage(ex);
        return {
            message: error_http_message.message,
            error_keys: error_http_message.error_keys,
            data: ex.response.data,
            error: true
        }
    }
}

export const uploadMedicinesCatalog = async ({commit}, file) => {
    try {
        const { data: result } = await configurationClient.post('/catalog/medicines/catalog/excel', file)
        const { message, error } = result.result
        return { message, error }
    } catch (ex) {
        if (ex) {
            const error_http_message = errorHttpMessage(ex);
            return {
                message: error_http_message.message,
                error_keys: error_http_message.error_keys,
                data: ex.response?.data,
                error: true
            }
        } else {
            console.error('error', ex)
        }
    }
}

