import ephiClient from '@/axiosClients/ephiClient'
import {errorHttpMessage} from "@/utils/errorDecodeUtil"
import configurationClient from "@/axiosClients/configurationClient";

export const getEncounterModalities = async ({commit}) => {
    try {
        const {data: result} = await ephiClient.get('/patients/encounter/modalities')
        const {data, message, error} = result.result
        return {data, message, error}
    } catch (ex) {
        if (ex.error && ex.message) {
            const error_http_message = errorHttpMessage(ex);
            return {
                message: error_http_message.message,
                error_keys: error_http_message.error_keys,
                data: ex.response.data,
                error: true
            }
        } else {
            return {
                message: '',
                data: [],
                error: true
            }
        }
    }
}

export const getPrescriptionPDF = async ({commit}, encounterId) => {
    try {
        const {data: result} = await ephiClient.get(`/patients/encounter/download/${encounterId}`, {responseType: "blob"})
        const blob = new Blob([result], {type: "application/pdf"});
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.target = "_blank"
        link.click();

        return { error: false, message: ''}

    } catch (ex) {
        if (ex.error && ex.message) {
            const error_http_message = errorHttpMessage(ex);
            return {
                message: error_http_message.message,
                error_keys: error_http_message.error_keys,
                data: ex.response.data,
                error: true
            }
        } else {
            return {
                message: '',
                data: [],
                error: true
            }
        }
    }
}

export const saveEncounter = async ({commit}, data) => {
    try {
        const {data: result} = await ephiClient.post('/patients/encounter', data)
        const {data: val, message, error} = result.result
        return {val, message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex)
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const updateEncounter = async ({commit}, data) => {
    try {
        const {data: result} = await ephiClient.post(`/patients/encounter/${data.id}`, data.data)
        const {data: val, message, error} = result.result
        return {val, message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex)
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getDiagnosticsByAffectedSystem = async ({commit}, dataForm) => {

    try {
        const {data: result} = await configurationClient.get('/catalog/diagnostics/affected-system/' + dataForm.affectedSystemId)
        const {message, error, data} = result.result
        return {message, error, data}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex)
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }

}

export const deleteEncounterFile = async ({commit}, file_item) => {
    try {
        const {data: result} = await ephiClient.delete(`/patients/encounter/file/${file_item.id}`)
        const {message, error} = result.result
        return {message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return { message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}