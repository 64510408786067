import isVerifyTokenGuard from "@/guards/verifyTokenGuard";

export default {
    name: '/',
    component: () => import('@/layouts/NoticePrivacyLayout.vue'),
    children: [
        {
            path: '',
            name: 'notice_privacy-index',
            //beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [
                    {
                        transKey: 'breadcrumbs.notice-privacy-index',
                        isActive: true
                    }
                ]
            },
            component: () => import('@/modules/notice-privacy/views/Index.vue')
        }
    ]
}
