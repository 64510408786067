import hasAccessGuard from "@/guards/hasTokenGuard";
import verifyTokenRecoverPassword from "@/guards/verifyTokenRecoverPassword";

export default {
    name: 'auth',
    component: () => import(/* webpackChunkName: "auth-layout" */ '@/modules/auth/layouts/AuthLayout.vue'),
    children: [
        {
            path: '',
            name: 'auth-login',
            beforeEnter: [hasAccessGuard],
            component: () => import(/* webpackChunkName: "auth-login" */ '@/modules/auth/views/Login.vue'),
        },
        {
            path: '/sso/:response',
            name: 'response-sso',
            component: () => import(/* webpackChunkName: "auth-login" */ '@/modules/auth/views/Login.vue'),
        },
        {
            path: '/password-recovery',
            name: 'auth-password-recovery',
            beforeEnter: [hasAccessGuard],
            component: () => import(/* webpackChunkName: "auth-login" */ '@/modules/auth/views/PasswordRecovery.vue'),
        },
        {
            path: '/questions',
            name: 'auth-questions',
            beforeEnter: [hasAccessGuard],
            component: () => import(/* webpackChunkName: "auth-login" */ '@/modules/auth/views/Questions.vue'),
        },
        {
            path: '/password-confirmation',
            name: 'auth-password-confirmation',
            beforeEnter: [hasAccessGuard],
            component: () => import(/* webpackChunkName: "auth-login" */ '@/modules/auth/views/PasswordConfirmation.vue'),
        }
    ]
}
