import isVerifyTokenGuard from "@/guards/verifyTokenGuard";

export default {
    name: 'medicines',
    component: () => import(/* webpackChunkName: "base-layout" */ '@/layouts/BaseLayout.vue'),
    children: [
        {
            path: '',
            name: 'medicines-index',
            beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [                    
                    {                           
                        transKey: 'breadcrumbs.medicines-index',
                        isActive: true
                    }
                ]
            },
            component: () => import(/* webpackChunkName: "medicines-index" */ '@/modules/configurations/catalogs/medicines/views/index.vue')
        },
        {
            path: 'create',
            name: 'medicines-create',
            beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [
                    {   
                        route: {
                            name: 'medicines-index',
                            params: {}
                        },
                        transKey: 'breadcrumbs.medicines-index',
                        isActive: false
                    },
                    {                           
                        transKey: 'breadcrumbs.medicines-create',
                        isActive: true
                    }
                ]
            },
            component: () => import(/* webpackChunkName: "medicines-create" */ '@/modules/configurations/catalogs/medicines/views/create.vue')
        },
        {
            path: 'edit/:id',
            name: 'medicines-edit',
            beforeEnter: [isVerifyTokenGuard],
            meta: {
                breadcrumbs: [
                    {   
                        route: {
                            name: 'medicines-index',
                            params: {}
                        },
                        transKey: 'breadcrumbs.medicines-index',
                        isActive: false
                    },
                    {                           
                        transKey: 'breadcrumbs.medicines-edit',
                        isActive: true
                    }
                ]
            },
            component: () => import(/* webpackChunkName: "medicines-edit" */ '@/modules/configurations/catalogs/medicines/views/edit.vue')
        }
    ]
}