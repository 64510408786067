import axios from 'axios'
import store from '@/store'

const videocallClient = axios.create({})
const protocol = window.location.protocol

videocallClient.interceptors.request.use(config => {
    config.headers.Authorization = `Bearer ${store.getters['auth/getToken']}`
    config.headers.lang = store.getters['getLang']

    //If user is already logged in, take their tenant host
    var host = store.getters['auth/getTenantHost']

    if (host == null) {
        //If user is not logged in, means that is a external user. Take their host from the url shared
        host = window.location.hostname;
    }

    config.url = `${process.env.VUE_APP_PROTOCOL}://${host}/videocall/api/v1${config.url}`

    return config;
})

export default videocallClient
