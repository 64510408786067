import isVerifyTokenGuard from "@/guards/verifyTokenGuard";
import verifyTokenGuardWithoutPermission from "@/guards/verifyTokenGuardWithoutPermission";

export default {
    name: 'dashboard',
    component: () => import(/* webpackChunkName: "base-layout" */ '@/layouts/BaseLayout.vue'),
    children: [
        {
            path: '',
            name: 'dashboard-index',
            beforeEnter: [isVerifyTokenGuard],
            component: () => import(/* webpackChunkName: "dashboard-index" */ '@/modules/dashboard/views/Dashboard.vue')
        },
        {
            path: 'patient-home',
            name: 'patient-home-index',
            beforeEnter: [verifyTokenGuardWithoutPermission],
            component: () => import(/* webpackChunkName: "patient-home-index" */ '@/modules/dashboard/views/PatientHome.vue')
        },
        {
            path: 'home-empty',
            name: 'home-empty-index',
            beforeEnter: [verifyTokenGuardWithoutPermission],
            component: () => import(/* webpackChunkName: "home-empty-index" */ '@/modules/dashboard/views/DashboardEmpty.vue')

        }
    ]
}
