import isVerifyTokenGuard from "@/guards/verifyTokenGuard"

export default {
  name: 'notifications',
  component: () => import(/* webpackChunkName: "base-layout" */ '@/layouts/BaseLayout.vue'),
  children: [
    {
      path: '',
      name: 'notifications-index',
      beforeEnter: [isVerifyTokenGuard],
      meta: {
        breadcrumbs: [{
          transKey: 'breadcrumbs.patients-index',
          isActive: true
        }]
      },
      component: () => import( /* webpackChunkName: "notifications-index" */ '@/modules/notifications/views/Index.vue')
    }
  ]
}