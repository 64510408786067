import {errorHttpMessage} from "@/utils/errorDecodeUtil"
import configurationAuthTenant from '@/axiosClients/authTenant'


export const updateUserPassword = async ({commit}, password) => {
    try {

        const {data: result} = await configurationAuthTenant.post('/update-password', password)
        const {message, error, data} = result.result

        if (!error) {
            commit('updateToken', result.result.data.token)
        }

        return {message, error, data}

    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}
