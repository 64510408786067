import {errorHttpMessage} from "@/utils/errorDecodeUtil"
import configurationClient from '@/axiosClients/configurationClient'
import {downloadFile} from "@/utils/filesUtil"

export const getNoticeOfPrivacy = async ({commit}) => {
    try {
        const {data: result} = await configurationClient.get('/notice-privacy')
        const {data, message, error} = result.result
        return {data, message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const acceptNoticePrivacy = async ({commit}, acceptNoticePrivacy) => {
    try {
        const {data: result} = await configurationClient.post(`/notice-privacy`, {accept_notice_policy: acceptNoticePrivacy})
        const {data, message, error} = result.result
        return {data, message, error}
    } catch (ex) {
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}
