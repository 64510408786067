
import {errorHttpMessage} from "@/utils/errorDecodeUtil"
import configurationClient from '@/axiosClients/configurationClient'
import { downloadFile } from "@/utils/filesUtil"
import dashboardClient from '@/axiosClients/dashboardClient'
import ephiClient from '@/axiosClients/ephiClient'

export const saveCieDiagnosis = async ({commit}, diagnosis) => {
    try{
        const { data:result } = await configurationClient.post('/catalog/diagnostics/create', diagnosis)        
        const { message, error } = result.result    
        return {message, error}        
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    } 
}

export const findCieDiagnosisById = async ({commit}, diagnosisId) => {
    try{        
        const { data:result } = await configurationClient.get(`/catalog/diagnostics/show/${diagnosisId}`)        
        const { data, message, error } = result.result    
        return {data, message, error}        
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const getAllDiagnostics = async ({commit}) => {
    try{        
        const { data } = await configurationClient.get(`/catalog/diagnostics`)    
        return data.result
    } catch(ex) { 
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const editCieDiagnosis = async ({commit}, diagnosis) => {
    try{
        const { data:result } = await configurationClient.post(`/catalog/diagnostics/${diagnosis.id}`, diagnosis)        
        const { message, error } = result.result    
        return { message, error}        
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}

export const deleteCieDiagnosis = async ({commit}, diagnosisId) => {
    try{        
        const { data:result } = await configurationClient.delete(`/catalog/diagnostics/destroy/${diagnosisId}`)        
        const { message, error } = result.result    
        return {message, error}        
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    } 
}

export const downloadExcelReport = async ({commit}, fileName) => {
    try{        
        const response = await configurationClient.get(`/catalog/diagnostics/download`,{
            responseType: 'blob'
        })                
        downloadFile(response.data, fileName)
    } catch(ex) {                     
        console.log('error', ex)
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    } 
}

export const getDashboardInfo = async ({commit}, params) => {
    try{
        const { data:result } = await dashboardClient.get(`/diagnostics${params}`)   
        const { data, message, error } = result.result
        return {data, message, error}        
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}


export const getIncapacitiesInfo = async ({commit}, params) => {
    try{
        const { data:result } = await ephiClient.get(`/patients/incapacities/data-graph${params}`)   
        const { data, message, error } = result.result
        return {data, message, error}        
    } catch(ex) {                     
        const error_http_message = errorHttpMessage(ex);
        return {message: error_http_message.message, error_keys: error_http_message.error_keys, data: ex.response.data, error: true}
    }
}